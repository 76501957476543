import React from 'react'
import { connect } from "react-redux";
import { Loading, AlertMessage } from '../../components'
import { galleryUri, resourceUri, generarPin, isSuccess } from '../../api'
import { PlusIcon } from '../../components/Icons';
import { ADMIN } from '../../utils/Permisos';
import Screen from '../Screen';
import { SUCURSAL_NUEVA } from '../../utils/Routes';


class Sucursales extends Screen {
  state = {
    loading: false,
    busy: false,
    message: ''
  }

  toggleBusy = () => this.setState({ busy: !this.state.busy })
  nueva = () => this.navigate(SUCURSAL_NUEVA)

  enviarPin = (id) => {
    this.toggleBusy()
    generarPin(id).then(res => {
      if (isSuccess(res)) {
        this.setState({ message: 'Enviamos un correo con el nuevo pin'})
      }
    }).finally(this.toggleBusy)
  }

  onError = (e) => {
    this.setState({loading: false})
    console.error(e)
  }

  render() {
    const { message, loading, busy } = this.state
    if (loading) return <Loading />

    return (
      <div className="container">
        <AlertMessage message={message} type="success" className="mt-2" close={() => this.setState({ message: '' })} />
        <div className="row">
          {this.props.sucursales.map(sucursal =>
            <Sucursal key={sucursal.id} enviar={this.enviarPin} disabled={busy} { ...sucursal } /> )}
        </div>
      </div>
    )
  }
}

const Sucursal = ({ id, nombre, direccion, telefonos, thumbnail, enviar, disabled }) => (
  <div className="col-12 col-md-5 col-lg-3 m-1">
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <strong>{nombre}</strong>
          <small className="text-muted">{id}</small>
        </div>
        <img src={thumbnail !== null ? galleryUri('/' + thumbnail) : resourceUri("/resource/branch-1.jpg")} className="img-fluid" alt="thumb" />
        <p>{direccion}</p>
        {telefonos.map(Telefono)}
        <button className="btn btn-outline-primary btn-block mt-4" type="button" onClick={() => enviar(id)} disabled={disabled}>Generar PIN</button>
      </div>
    </div>
  </div>
)

const Telefono = (telefono) => (
  <div key={telefono}>
    <small>{telefono}</small>
  </div>
)

const mapStateToProps = ({ session }) => ({
  sucursales: session.sucursales.sort((a, b) => a.id - b.id),
  permisos: session.permisos,
})

export default connect(mapStateToProps)(Sucursales)