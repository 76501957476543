import React, {Component} from 'react'

import Screen from '../Screen';
import { isSuccess, crearEmpleado, obtenerUsuario, modificarEmpleado } from '../../api'
import { AlertMessage } from '../../components';
import { isEmpty } from '../../utils';
import { Button, Container, Form, Menu, Segment } from 'semantic-ui-react';


export default class AgregarTrabajador extends Screen {
  state = {
    id: null,
    email: '',
    nombre: '',
    error: '',
    loading: true
  }

  componentDidMount() {
    const email = this.props.match.params.email
    if (email) {
      obtenerUsuario(email).then(res => {
        if (isSuccess(res)) {
          this.setState({
            id: res.data.id,
            email: res.data.email,
            nombre: res.data.nombre
          })
        } else {
          this.setState({ error: res.data })
        }
      }).finally(this.toggleLoading)
    } else {
      this.toggleLoading()
    }
  }

  crear = () => {
    const empleado = {
      email: this.state.email.trim().toLowerCase(),
      nombre: this.state.nombre.trim()
    }

    this.setState({ error: '', loading: true })
    crearEmpleado(empleado).then(res => {
      if (isSuccess(res)) {
        this.props.history.goBack()
      } else {
        if (res.data.indexOf('credenciales_pkey') !== -1) {
          this.setState({ error: 'El correo esta en uso' })
        } else {
          this.setState({ error: res.data })
        }
      }
    }).finally(this.loading)
  }

  modificar = () => {
    const empleado = {
      persona: this.state.id,
      nombre: this.state.nombre.trim()
    }

    this.setState({ error: '', loading: true })
    modificarEmpleado(empleado).then(res => {
      if (isSuccess(res)) {
        this.goBack()
      } else {
        this.setState({ error: res.data })
      }
    }).finally(this.loading)
  }

  loading = () => this.setState({ loading: !this.state.loading })

  onChange = ({ target }) => this.setState({ [target.id]: target.value })

  render() {
    const { email, nombre, error, loading } = this.state
    const modificando = this.props.match.params.email
    const disabled = isEmpty(email) || isEmpty(nombre) || loading

     return (
      <Container>
        <Menu>
          <Menu.Item>
             <Button onClick={this.goBack}>Volver</Button>
          </Menu.Item>
          <Menu.Item>Trabajador</Menu.Item>
          <Menu.Item position="right">
            <Button onClick={modificando ? this.modificar : this.crear} disabled={disabled} color="green">
              {modificando ? 'Modificar' : 'Crear'}
            </Button>
          </Menu.Item>
        </Menu>
        <Segment>
            <AlertMessage message={error} />
            <Form>
              <Form.Field>
                <label>Correo Electronico</label>
                <input type="email" id="email" value={email} onChange={this.onChange} disabled={modificando} />
              </Form.Field>
              <Form.Field>
                 <label>Nombre</label>
                 <input type="text" id="nombre" value={nombre} onChange={this.onChange} />
              </Form.Field>
            </Form>
         </Segment>
       </Container>
    )
  }
}

