import React, {Component} from 'react'
import { Container, Menu, Button, Icon } from 'semantic-ui-react';

import { Loading } from '../../components'
import { fetchPedidos, fetchGastos } from '../../api'
import { jornada_xlsx, totalesJornada } from '../../lib/JornadaExport';
import TablaDetalle from './TablaDetalle';
import { EstadisticasClp } from './Estadisticas';


export default class JornadaDetalle extends Component {
  state = {
    pedidos: [],
    gastos: [],
    loading: true
  }

  componentDidMount() {
    let jornada = this.props.match.params.id
    fetchPedidos(jornada).then(res => {
      if (res.status !== 'success') {
        return
      }
      this.setState({ pedidos: res.data })
    }).catch(console.error).finally(() => this.setState({ loading: false }))

    fetchGastos(jornada).then(res => {
      console.log(res)
      if (res.status === 'success') {
        this.setState({ gastos: res.data })
      }
    }).catch(console.error)
  }

  exportar = () => {
    jornada_xlsx(this.state.pedidos, this.state.gastos)
  }


  render() {
    const { pedidos, gastos, loading } = this.state
    if (loading) return <Loading />

    const { totalRepartidores, totalGastos, sodexo, transbank, transferencias,
      aplicaciones, totalEfectivo, totalOtrosMedios, pagoonline, amipass } = totalesJornada(pedidos, gastos)


    const totales = [
      { label: "Total Efectivo", value: totalEfectivo },
      { label: "Total Otros Medios", value: totalOtrosMedios },
      { label: "Gastos", value: totalGastos },
      { label: "Repartidores", value: totalRepartidores }
    ]

    const totales_ventas = [
      { label: "Total Caja", value: totalEfectivo - totalGastos },
      { label: "Total Ventas", value: totalEfectivo + totalOtrosMedios }
    ]

    const totales_medios_pago = [
      { label: "Sodexo", value: sodexo },
      { label: "Transbank", value: transbank },
      { label: "Transferencias", value: transferencias },
      { label: "Aplicaciones", value: aplicaciones },
      { label: "Pago Online", value: pagoonline },
      { label: "Amipass", value: amipass }
    ]

    return (
      <Container>
        <Menu>
          <Menu.Item>
            Jornada {this.props.match.params.id}
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Button color="green" type="button" onClick={this.exportar}>
                <Icon name="download" />
                Descargar
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <EstadisticasClp data={totales} />
        <EstadisticasClp data={totales_ventas} />
        <EstadisticasClp data={totales_medios_pago} />
        <TablaDetalle pedidos={pedidos} gastos={gastos} busy={loading} />
      </Container>
    )
  }
}