import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Button, Container, Form, Grid, Header, Icon, Image, Message, Segment } from 'semantic-ui-react'

import { MensajeError } from '../components/Mensajes'
import { AlertMessage } from '../components'
import { DASHBOARD } from '../utils/Routes'
import { sessionLogin } from '../actions/SessionActions'
import { send_code } from '../api'
import { int, isEmail, isEmpty } from '../utils'
import Screen from './Screen'
import { limpiarError } from '../actions/StatusActions'
import moment from 'moment'

class Login extends Screen {
  state = {
    step: 0,
    email: '',
    code: '',
    error: '',
    sucursal: -1,
    sucursales: [],
    loading: false
  }

  componentDidMount() {
    if (this.props.validSession) {
      this.props.history.push(DASHBOARD)
    }
  }

  /*sendEmail = (e) => {
    e.preventDefault()
    let email = this.state.email.trim()
    if (email === '') return
    this.setState({loading: true})
    send_code(email).then(res => {
      if (res.status === 'success') {
        const sucursales = res.data.sort(sortSucursales)
        this.setState({ step: 1, sucursales, sucursal: sucursales.length > 0 && sucursales[0].id })
      }
    }).catch(this.onError).finally(() => this.setState({ loading: false }))
  }*/

  enviarCorreo = (e) => {
    e.preventDefault()
    this.clear()
    this.toggleLoading()
    send_code(this.state.email.toLowerCase().trim()).then(r => {
      if (r.status === 'success') {
        const sucursales = r.data
        if (sucursales.length === 0) {
          this.onError("Usuario no autorizado")
        } else {
          this.setState({
            step: 1,
            loading: false,
            sucursales: sucursales.sort(sortSucursales),
            selected: sucursales[0].id
          })
        }
      } else {
        this.onError('Problemas enviando el correo, intente mas tarde.')
      }
    }).catch(this.onError)
  }

  login = async (event) => {
    event.preventDefault()
    let code = this.state.code
    if (isNaN(code)) return

    try {
      const data = {
        email: this.state.email.trim(),
        codigo: int(code),
        sucursal: int(this.state.sucursal)
      }
      this.toggleLoading()
      await this.props.sessionLogin(data)
    } catch (e) {
      this.setState({ loading: false })
      this.onError(e)
    }
  }

  onError = (e) => {
    console.error(e)
    let error = e.data
    if (!error) {
      error = e.message ? e.message : e
    }
    this.setState({ error, loading: false })
  }

  onEmailChange = (e) => this.setState({ email: e.target.value })
  onCodeChange = (e) => this.setState({ code: e.target.value })
  onSucursalChange = (e, { value }) => this.setState({ selected: value })

  clear = () => this.setState({ error: '', code: '', sucursales: [], sucursal: -1 })

  back = (e) => {
    e.preventDefault()
    this.setState({step: 0})
  }

  render() {
    const { email, code, step, loading, error, sucursales, sucursal } = this.state
    //const sucursales = this.state.sucursales.map(s => ({ ...s, id: s.id.toString() }))
    
    return (
      <Grid textAlign='center' style={{ height: '70vh', marginTop: 32 }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='black' textAlign='center'>
            <Image src={process.env.REACT_APP_LOGO} /> {process.env.REACT_APP_TITULO}
          </Header>
          <Form size='large' loading={loading}>
            <MensajeError content={error} />
            {step === 0 ?
              <EmailSegment email={email} onEmailChange={this.onEmailChange} onClick={this.enviarCorreo} /> :
              <CodeSegment code={code} onCodeChange={this.onCodeChange} onClick={this.login}
                onBackClick={this.back} sucursales={sucursales} sucursal={sucursal} onSucursalChange={this.onSucursalChange} />
            }
          </Form>
          <Message attached='bottom' warning>
            <Icon name='help' />
            {step === 0 ? 'Recibiras un correo con el código para ingresar.' : `Ingresa el codigo que enviamos al correo ${email}`}
          </Message>
          <Icon name="copyright" /> {process.env.REACT_APP_TITULO} - <small>{moment().format('YYYY')}</small>
        </Grid.Column>
      </Grid>
    )
    /*return (
      <Container>
        <div className="col-md-6">
          <div className="cs-login-title">
            <img src={process.env.REACT_APP_LOGO} alt="logo" />
            <h2>{process.env.REACT_APP_TITULO}</h2>
          </div>
          <div className="card">
            <div className="card-body">
              <AlertMessage message={this.props.error} close={this.props.limpiarError}/>
              <AlertMessage message={this.state.error} close={() => this.setState({error: ''})}/>
              {this.state.step === 0 ?
                <EmailForm submit={this.send_email} email={this.state.email} onChange={this.onChange}
                  host={this.props.host} selectHost={this.selectHost} loading={this.state.loading}/> :
                <CodeForm submit={this.login} code={this.state.code} onChange={this.onChange} email={this.state.email}
                  sucursales={sucursales} sucursal={this.state.sucursal} back={this.back} loading={this.state.loading}/>
              }
            </div>
          </div>
        </div>
      </Container>
    )*/
  }
}


const EmailSegment = ({ email, onEmailChange, onClick }) => {
  const error = email.length > 0 && !isEmail(email)
  return (
    <Segment stacked>
      <Form.Input type="email" error={error} fluid icon='mail' iconPosition='left' placeholder='correo@proveedor.com' value={email} onChange={onEmailChange} />
      <Button color='black' fluid size='large' onClick={onClick} disabled={error || isEmpty(email)}>
        Continuar
      </Button>
    </Segment>
  )
}

const CodeSegment = ({ code, onCodeChange, onClick, onBackClick, sucursales, sucursal, onSucursalChange }) => {
  const error = isNaN(code)
  return (
    <Segment stacked>
      <Form.Select
        fluid
        value={sucursal}
        onChange={onSucursalChange}
        options={sucursales.map(v => ({ key: v.id, value: v.id, text: v.nombre }))}
        placeholder='Selecciona la Sucursal'
      />
      <Form.Input fluid icon='unhide' type="number" iconPosition='left'
        placeholder='Ingresa el código' error={error} value={code} onChange={onCodeChange} />
      <Button.Group vertical fluid labeled icon>
        <Button color='black' size='large' content="Ingresar" icon="sign in" onClick={onClick} disabled={error || isEmpty(code)} />
        <Button size="large" icon="chevron left" content="Volver" onClick={onBackClick} />
      </Button.Group>
    </Segment>
  )
}

const EmailForm = ({submit, email, onChange, loading }) => (
  <form onSubmit={submit}>
    <div className="form-group">
      <label htmlFor="email">Correo electronico</label>
      <input id="email" className="form-control" email={email} onChange={onChange}/>
    </div>
    <button className="btn btn-primary btn-block" disabled={loading}>Enviar</button>
    <small className="form-text">* Recibiras un codigo en tu correo para el siguiente paso.</small>
  </form>
)

const CodeForm = ({submit, email, code, sucursal, onChange, back, sucursales, loading}) => sucursales.length === 0 ? <Inexistente /> : (
  <form onSubmit={submit}>
    {sucursales.length > 1 &&
      <div className="form-group">
        <label htmlFor="sucursal">Selecciona la sucursal</label>
        <select className="form-control" id="sucursal" value={sucursal} onChange={onChange}>
          {sucursales.map(sucursal => (
            <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>
          ))}
        </select>
      </div>
    }
    <div className="form-group">
      <label htmlFor="code">Codigo de ingreso</label>
      <input id="code" className="form-control" value={code} onChange={onChange}/>
      <small>Ingresa el codigo que enviamos a tu correo {email}</small>
    </div>
    <button className="btn btn-primary btn-block" disabled={loading}>Ingresar</button>
    <button className="btn btn-default btn-block" onClick={back} disabled={loading}>Volver</button>
  </form>
)

const Inexistente = () => (
  <div className="alert alert-danger" role="alert">
    Usuario no existe
  </div>
)

function sortSucursales(a, b) {
  return a.nombre < b.nombre ? -1 : 1
}

const mapStateToProps = (state) => ({
  validSession: !isEmpty(state.session.token),
  error: state.status.error
})

const mapDispatchToProps = (dispatch) => ({
  sessionLogin: (email, code) => dispatch(sessionLogin(email, code)),
  limpiarError: () => dispatch(limpiarError())
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)