import { get, post, put, del, resource, downloadFile } from './Methods'

const url = 'https://franquiciaskamisushi.cl/images'

export function galleryUri(resource) {
  return url + '/gallery' + resource
}

export function resourceUri(resource) {
  return url + resource
}

export function isSuccess(res) {
  return res.status === 'success'
}

export const send_code = (email) => post('/api/email', { email })
export const login = (body) => post('/api/login', body)
export const session = () => get('/api/v1/session')
export const logout = () => del('/api/v1/logout')

export const generarPin = (sucursal) => post('/api/v1/sucursales/pin/generar', { sucursal })
export const fijarSucursal = (sucursal) => post('/api/v1/user/sucursal', { sucursal })
export const fetchSucursales = () => get('/api/v1/sucursales')
export const fetchComunas = () => get('/api/v1/comunas')
export const obtenerUsuario = (email) => get('/api/v1/credenciales/usuarios/' + email)
export const buscarCredenciales = (body) => post('/api/v1/credenciales/usuarios', body)
export const crearUsuario = (body) => put('/api/v1/credenciales', body)
export const modificarUsuario = (body) => post('/api/v1/credenciales', body)
export const eliminarCredencial = (email) => del('/api/v1/credenciales', { email })

export const nuevaSucursal = (sucursal) => put('/api/v1/sucursales', sucursal)
export const modificarSucursal = (sucursal) => post('/api/v1/sucursales', sucursal)

export const fetchProductos = (sucursal) => get('/api/v1/productos/' + sucursal)
export const agregarProducto = (data) => post('/api/v1/productos', data)
export const modificarProducto = (data) => post('/api/v1/productos/modificar', data)
export const copiarProductos = (sucursales, productos) => post('/api/v1/productos/copiar', { sucursales, productos })
export const eliminarProductos = (_ids) => del('/api/v1/productos', { _ids })
export const fetchCategorias = () => get('/api/v1/categorias')

export const fetchEmpleados = () => get('/api/v1/deliveryman')
export const crearEmpleado = (empleado) => post('/api/v1/deliveryman', empleado)
export const modificarEmpleado = (empleado) => put('/api/v1/deliveryman', empleado)
export const eliminarEmpleado = (email) => del('/api/v1/deliveryman/' + email)

export const fetchInventario = () => get('/api/v1/inventario')
export const fetchInventarioConsumo = () => get('/api/v1/inventario/consumo')
export const agregarInventario = (data) => post('/api/v1/inventario', data)
export const cerrarInventario = (data) => post('/api/v1/inventario/cerrar', data)
export const fetchInventarioItems = () => get('/api/v1/inventario/items')
export const agregarInventarioItem = (data) => post('/api/v1/inventario/items', data)
export const eliminarInventarioItem = (data) => delete('/api/v1/inventario/items', data)
export const fetchInventarioEventos = (id) => get('/api/v1/inventario/eventos/' + id)

export const fetchCompras = (fecha_desde, fecha_hasta) => get(`/api/v1/compras/${fecha_desde}/${fecha_hasta}`)
export const fetchConceptos = () => get('/api/v1/backoffice/conceptos')
export const fetchCategoriasConceptos = () => get('/api/v1/backoffice/conceptos/categorias')
export const ingresarCompra = (data) => post('/api/v1/compras', data)
export const eliminarCompra = (id) => del('/api/v1/compras', { id })
export const resumenCompraVenta = (fecha_desde, fecha_hasta) => get(`/api/v1/compras/resumen/${fecha_desde}/${fecha_hasta}`)
export const obtenerProyeccion = () => get('/api/v1/compraventa/proyeccion')
export const fetchRatios = () => get('/api/v1/compraventa/ratios')
export const resumenConceptos = (fecha_desde, fecha_hasta) => get(`/api/v1/compraventa/conceptos/${fecha_desde}/${fecha_hasta}`)
export const ventasPorMes = (fecha_desde, fecha_hasta) => get(`/api/v1/compraventa/ventas/${fecha_desde}/${fecha_hasta}`)

export const fetchArchivos = () => get('/api/v1/archivos')
export const urlDescarga = (nombre) => downloadFile(`/api/v1/archivos/${nombre}`, nombre)

export const ventasSucursales = (desde, hasta) => get(`/api/v1/compraventa/sucursales/ventas/${desde}/${hasta}`)


export function fetchImagenesPath() {
  return get('/api/v1/imagenes')
}

export function agregarCategoria(data) {
  return post('/api/v1/categorias', data)
}

export function eliminarCategoria(id) {
  return del('/api/v1/categorias', { id })
}

export function fetchJornadas() {
  return get('/api/v1/jornada')
}

export function fetchPedidos(jornada) {
  return get('/api/v1/jornada/:jornada/pedidos'.replace(':jornada', jornada))
}

export function fetchGastos(jornada) {
  return get('/api/v1/gastos/:jornada'.replace(':jornada', jornada))
}

export function historialInventario() {
  return get('/api/v1/inventario/historico')
}

export function fetchEventosInventario(ids) {
  return post('/api/v1/inventario/eventos', { ids })
}

export function fetchItemsInventario() {
  return get('/api/v1/inventario/items')
}

export function buscarPedidosPorFecha(desde, hasta) {
  return get(`/api/v1/pedidos/${desde}/${hasta}`)
}

export function buscarJornadasPorFecha(desde, hasta) {
  return post('/api/v1/jornada/filtrar', { desde, hasta })
}

export function buscarCompraVentaPorFecha(desde, hasta, consolidado) {
  return post('/api/v1/jornada/compraventa', { desde, hasta, consolidado })
}

export function resumenMensual(desde, hasta) {
  return post('/api/v1/compraventa/resumen/mensual', { desde, hasta })
}

export function resumenAnual(desde, hasta) {
  return post('/api/v1/compraventa/resumen/anual', { desde, hasta })
}

export function resumenDiario() {
  return get('/api/v1/compraventa/resumen/diario')
}

export function resumenSucursalesAnual(anio) {
  return get('/api/v1/compraventa/ventasanual/' + anio)
}

//@Deprecated
export function ordersByDate(start_date, end_date) {
  return get('/api/v0/web/orders/' + start_date + '/' + end_date)
}

export function fetchUsuarios(permisos) {
  return get('/api/v1/usuarios/' + permisos)
}