import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment';

import { APLICACIONES, EFECTIVO, SODEXO, TRANSBANK,
  TRANSFERENCIA, tipoPagoLabel, PAGO_ONLINE, AMIPASS } from "../utils/TipoPagos";
import { COMPLETED, estadoLabel } from "../utils/Estados";
import { reduceProductos } from '../Model';
import { DELIVERY } from '../utils/TipoPedidos';


export function pedidos_xlsx(pedidos) {
  const workbook = new Workbook()
  
  pedidosSheet(workbook.addWorksheet('Pedidos'), pedidos)
  writeWorkbook(workbook, 'pedidos.xlsx')
}

export function compraventa_xlsx(jornadas, pedidos, gastos, sucursal, desde, hasta) {
  const workbook = new Workbook()

  gastos = gastos.map(g => {
    const value = jornadas.find(j => g.id_jornada === j.id)
    return {
      ...g,
      sucursal: value.sucursal
    }
  })

  let totales = totalesJornada(pedidos, gastos)

  totalesSheet(workbook.addWorksheet('Totales'), totales)
  jornadasSheet(workbook.addWorksheet('Jornadas ' + jornadas.length), jornadas, pedidos)
  pedidosSheet(workbook.addWorksheet('Pedidos ' + pedidos.length), pedidos)
  gastosSheet(workbook.addWorksheet('Gastos ' + gastos.length), gastos)
  repartidoresSheet(workbook.addWorksheet('Repartidores'), totales.repartidores)

  const fecha_desde = moment(desde).format("DD-MM-YYYY")
  const fecha_hasta = moment(hasta).format("DD-MM-YYYY")
  let nombre = "compraventa"
  if (sucursal) {
    moment()
    nombre = sucursal.nombre + "-compraventa"
  }
  writeWorkbook(workbook, `${nombre}[${fecha_desde}][${fecha_hasta}].xlsx`)
}

export function jornada_xlsx(pedidos, gastos) {
  const workbook = new Workbook()

  let totales = totalesJornada(pedidos, gastos)

  totalesSheet(workbook.addWorksheet('Totales'), totales)
  pedidosSheet(workbook.addWorksheet('Pedidos ' + pedidos.length), pedidos)
  gastosSheet(workbook.addWorksheet('Gastos ' + gastos.length), gastos)
  repartidoresSheet(workbook.addWorksheet('Repartidores'), totales.repartidores)

  workbook.xlsx.writeBuffer({ base64: true }).then(xlsx64 => {
    saveAs(new Blob([xlsx64]), 'jornadas.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  }).catch(console.error)
}

function writeWorkbook(workbook, name) {
  return workbook.xlsx.writeBuffer({ base64: true }).then(xlsx64 => {
    saveAs(new Blob([xlsx64]), name, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  }).catch(console.error)
}

function totalesSheet(sheet, totales) {
  sheet.columns = [
    { header: 'Total Caja', key: 'caja', width: 15 },
    { header: 'Total Efectivo', key: 'efectivo', width: 15 },
    { header: 'Total Ventas', key: 'ventas', width: 15 },
    { header: 'Total Otros Medios de Pago', key: 'otros', width: 15 },
    { header: 'Transbank', key: 'transbank', width: 15 },
    { header: 'Transferencias', key: 'transferencias', width: 15 },
    { header: 'Sodexo', key: 'sodexo', width: 15 },
    { header: 'Aplicaciones', key: 'aplicaciones', width: 15 },
    { header: 'Gastos', key: 'gastos', width: 15 },
    { header: 'Repartidores', key: 'repartidores', width: 15 },
  ]

  sheet.addRow({
    caja: totales.totalEfectivo - totales.totalGastos,
    efectivo: totales.totalEfectivo,
    ventas: totales.totalEfectivo + totales.totalOtrosMedios,
    otros: totales.totalOtrosMedios,
    transbank: totales.transbank,
    transferencias: totales.transferencias,
    sodexo: totales.sodexo,
    aplicaciones: totales.aplicaciones,
    gastos: totales.totalGastos,
    repartidores: totales.totalRepartidores
  })
}

function repartidoresSheet(sheet, repartidores) {
  sheet.columns = [
    { header: 'id sucursal', key: 'sucursal', width: 12 },
    { header: 'Correo', key: 'correo', width: 25 },
    { header: 'Nombre', key: 'nombre', width: 25 },
    { header: 'Total', key: 'total', width: 10 },
  ]

  repartidores.forEach(repartidor => sheet.addRow({
    nombre: repartidor.nombre,
    correo: repartidor.email,
    total: repartidor.total,
    sucursal: repartidor.sucursal
  }))

/*  const rows = repartidores.map(r => ([
    r.email, r.nombre, r.total
  ]))

  sheet.addTable({
    name: 'Repartidores',
    ref: 'B2',
    headerRow: true,
    totalsRow: true,
    style: {
      theme: 'TableStyleDark3',
      showRowStripes: true,
    },
    columns: [
      { name: 'Correo' },
      { name: 'Nombre' },
      { name: 'Total', totalsRowFunction: 'sum', filterButton: false },
    ],
    rows,
  });*/
}

function jornadasSheet(sheet, jornadas, pedidos) {
  sheet.columns = [
    { header: 'Id Sucursal', key: 'id_sucursal', width: 10 },
    { header: 'Sucursal', key: 'nombre_sucursal', width: 20 },
    { header: 'Id', key: 'id', width: 20 },
    { header: 'Fecha Apertura', key: 'apertura', width: 25 },
    { header: 'Fecha Cierre', key: 'cierre', width: 25 },
    { header: 'Ultimo Pedido', key: 'ultimo_pedido', width: 25 }
  ]

  jornadas.forEach(j => {
    const jornada_pedidos = pedidos.filter(p => p.jornada === j.id).sort(sortPedidos)
    let ultimo_pedido = ''
    if (jornada_pedidos.length > 0) {
      ultimo_pedido = jornada_pedidos[0].datetime
    }
    sheet.addRow({ ...j, id_sucursal: j.sucursal.id, nombre_sucursal: j.sucursal.nombre, ultimo_pedido })
  })
}

function sortPedidos(a, b) {
  return moment(a.datetime).isAfter(b.datetime) ? -1 : 1
}

function gastosSheet(sheet, gastos) {
  sheet.columns = [
    { header: 'id sucursal', key: 'sucursal', width: 10 },
    { header: 'Fecha', key: 'fecha', width: 20 },
    { header: 'Tipo Concepto', key: 'tipoconcepto', width: 20 },
    { header: 'Nombre Concepto', key: 'nombreconcepto', width: 25 },
    { header: 'Descripcion', key: 'descripcion', width: 50 },
    { header: 'Cantidad', key: 'cantidad', width: 10 },
    { header: 'Precio', key: 'precio', width: 10 },
    { header: 'Total', key: 'total', width: 10 },
    { header: 'Caja', key: 'caja', width: 5 },
  ]

  gastos.forEach(gasto => sheet.addRow({
    fecha: gasto.momento,
    tipoconcepto: gasto.concepto.tipo_concepto,
    nombreconcepto: gasto.concepto.nombre_concepto,
    precio: gasto.monto,
    total: gasto.monto * gasto.cantidad,
    cantidad: gasto.cantidad,
    descripcion: gasto.descripcion,
    caja: gasto.caja ? 'Si' : 'No',
    sucursal: gasto.sucursal && gasto.sucursal.id
  }))
}

export function pedidosSheet(sheet, pedidos) {
  sheet.columns = [
    { header: 'id sucursal', key: 'sucursal', width: 8 },
    { header: 'Indice', key: 'indice', width: 8 },
    { header: 'Tipo', key: 'tipo', width: 12 },
    { header: 'Fecha', key: 'fecha', width: 20 },
    { header: 'Telefono', key: 'telefono', width: 15 },
    { header: 'Rut', key: 'rut', width: 15 },
    { header: 'Nombre', key: 'nombre', width: 20 },
    { header: 'Direccion', key: 'direccion', width: 40 },
    { header: 'Repartidor', key: 'repartidor', width: 18 },
    { header: 'Costo Despacho', key: 'costo_despacho', width: 18 },
    { header: 'Estado', key: 'estado', width: 12 },
    { header: 'Medio de Pago', key: 'mediopago', width: 15 },
    { header: 'Recargo', key: 'recargo', width: 10 },
    { header: 'Producto', key: 'producto', width: 40 },
    { header: 'Cantidad', key: 'cantidad', width: 10 },
    { header: 'Precio', key: 'precio', width: 10 },
    { header: 'Total Productos', key: 'total_producto', width: 15 },
    { header: 'Total', key: 'total', width: 10 },
  ]

  const flatPedidos = pedidos.reduce((t, v) => [...t, ...v.products.map((p, i) => ({
    ...v,
    deliveryCost: (i === 0 && v.type === DELIVERY) ? v.deliveryCost : '',
    producto: p.title,
    cantidad: p.quantity,
    precio: p.price,
    total: v.products.reduce(reduceProductos, 0)
  }))], [])


  flatPedidos.forEach(pedido => sheet.addRow({
    indice: pedido.index,
    tipo: pedido.type,
    fecha: pedido.datetime,
    telefono: pedido.user.phone,
    nombre: pedido.user.name,
    rut: pedido.user.run,
    direccion: pedido.user.address,
    repartidor: pedido.deliveryMan !== null ? pedido.deliveryMan.nombre : '',
    costo_despacho: pedido.deliveryCost,
    estado: estadoLabel(pedido.status),
    mediopago: tipoPagoLabel(pedido.payment),
    recargo: pedido.recargo === 1 ? 'NO' : pedido.recargo,
    producto: pedido.producto,
    cantidad: pedido.cantidad,
    precio: pedido.precio,
    //total: pedido.products.reduce(reduceProductos, pedido.deliveryCost)
    total_producto: pedido.cantidad * pedido.precio,
    total: pedido.total + pedido.deliveryCost,
    sucursal: pedido.sucursal
  }))
}


export function totalesJornada(pedidos, gastos) {
  const completados = pedidos.filter(p => p.status === COMPLETED)
  const repartidores = agrupaRepartidores(completados)

  const totalRepartidores = repartidores.reduce((t, v) => t += v.total, 0)
  const totalGastos = gastos.filter(g => g.caja).reduce((t, v) => t += v.cantidad * v.monto, totalRepartidores)


  const sodexo = completados.filter(o => o.payment === SODEXO).reduce(calculaTotal, 0)
  const transbank = completados.filter(o => o.payment === TRANSBANK).reduce(calculaTotal, 0)
  const transferencias = completados.filter(o => o.payment === TRANSFERENCIA).reduce(calculaTotal, 0)
  const aplicaciones = completados.filter(o => o.payment === APLICACIONES).reduce(calculaTotal, 0)
  const pagoonline = completados.filter(o => o.payment === PAGO_ONLINE).reduce(calculaTotal, 0)
  const amipass = completados.filter(o => o.payment === AMIPASS).reduce(calculaTotal, 0)

  const totalEfectivo = completados.filter(o => o.payment === EFECTIVO).reduce(calculaTotal, 0)
  const totalOtrosMedios = completados.filter(o => o.payment !== EFECTIVO).reduce(calculaTotal, 0)

  return {
    totalRepartidores, totalGastos, sodexo, transbank, transferencias, aplicaciones,
    totalEfectivo, totalOtrosMedios, repartidores, pagoonline, amipass
  }
}

function agrupaRepartidores(pedidos) {
  let despachos = pedidos.filter(o => o.deliveryMan !== null)
    .map(o => ({ ...o.deliveryMan, total: o.deliveryCost, sucursal: o.sucursal }))
    .map(o => ({ ...o, nombre: (o.name || o.nombre) }))

  let deliveryMan = despachos.reduce((t, v) => {
    if (t[v.email]) {
      return ({ ...t, [v.email]: { ...v, total: t[v.email].total + v.total } })
    } else {
      return ({ ...t, [v.email]: { ...v } })
    }
  }, {})

  return Object.keys(deliveryMan).map(email => deliveryMan[email])
}

function calculaTotal(t, v) {
  let total = v.products.reduce(reduceProductos, 0) * (v.recargo ? v.recargo : 1)
  if (v.deliveryCost !== null) {
    total += v.deliveryCost
  }
  return t + total
}
