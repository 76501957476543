import React, {Component} from 'react'
import { connect } from "react-redux";

import { Loading, AlertMessage, Paginacion, filterPagination, CalendarInput, GastoItem, JornadaItem, PedidoItem, RepartidorItem } from '../components'
import { buscarCompraVentaPorFecha } from '../api'
import { JORNADA_DETALLE } from "../utils/Routes";
import moment, { ISO_DATE } from "../utils/Moment";
import { clp } from '../utils';
import { compraventa_xlsx, totalesJornada } from "../lib/JornadaExport";
import { categorias_from_state, conceptos_from_state } from '../reducers/ComprasReducer';
import { CANCELED, COMPLETED } from '../utils/Estados';
import { EstadisticasClp } from './jornada/Estadisticas';
import TablaDetalle from './jornada/TablaDetalle';
import { Button, Container, Grid, Icon, Segment } from 'semantic-ui-react';
import { sucursal_fs } from '../reducers/SessionReducer';


class Compraventa extends Component {
  state = {
    jornadas: [],
    pedidos: [],
    gastos: [],
    desde: new Date(),
    hasta: new Date(),
    visibleDesde: false,
    visibleHasta: false,
    consolidado: false,
    loading: false,
    error: ''
  }

  componentDidMount() {
    const desde = moment().subtract(6, 'days').toDate()
    this.setState({ desde })
  }

  buscar = () => {
    const { desde, hasta, consolidado } = this.state

    const fecha_desde = moment(desde).format('YYYY-MM-DDT00:00:00')
    const fecha_hasta = moment(hasta).format('YYYY-MM-DDT23:59:59')

    this.setState({ loading: true, error: '' })
    buscarCompraVentaPorFecha(fecha_desde, fecha_hasta, consolidado).then(res => {
      //console.log(res)
      if (res.status === 'success') {
        const jornadas = res.data.jornadas.map(j => ({
          ...j,
          sucursal: this.props.sucursales.find(s => s.id === j.sucursal)
        }))

        this.setState({
          jornadas,
          pedidos: res.data.pedidos,
          gastos: res.data.gastos
        })
      } else {
        this.setState({ error: res.data })
      }
    }).finally(() => this.setState({ loading: false }))
  }

  exportar = () => {
    const { jornadas, pedidos, gastos, desde, hasta } = this.state
    const { conceptos, categorias, sucursal } = this.props

    const gastosData = gastos.map(g => {
      if (g.concepto && g.concepto !== null) {
        return g
      }

      const v = conceptos.find(v => v.value === g.id_concepto)
      const c = categorias.find(k => k.value === v.id_categoria)
      return {
        ...g,
        concepto: {
          nombre_concepto: v.text,
          tipo_concepto: c.text
        }
      }
    })

    compraventa_xlsx(jornadas, pedidos, gastosData, sucursal, desde, hasta)
  }

  detalle = (id) => {
    this.props.history.push(JORNADA_DETALLE.replace(':id', id))
  }

  page = (id, value) => this.setState({ [id]: value })

  render() {
    const { pedidos, gastos } = this.state
    const { desde, hasta, consolidado, loading, visibleDesde, visibleHasta, error } = this.state

    const { totalRepartidores, totalGastos, sodexo, transbank, transferencias,
      aplicaciones, totalEfectivo, totalOtrosMedios, pagoonline, amipass } = totalesJornada(pedidos, gastos)

    const totales = [
      { label: "Total Efectivo", value: totalEfectivo },
      { label: "Total Otros Medios", value: totalOtrosMedios },
      { label: "Gastos", value: totalGastos },
      { label: "Repartidores", value: totalRepartidores }
    ]

    const totales_ventas = [
      { label: "Total Caja", value: totalEfectivo - totalGastos },
      { label: "Total Ventas", value: totalEfectivo + totalOtrosMedios }
    ]

    const totales_medios_pago = [
      { label: "Sodexo", value: sodexo },
      { label: "Transbank", value: transbank },
      { label: "Transferencias", value: transferencias },
      { label: "Aplicaciones", value: aplicaciones },
      { label: "Pago Online", value: pagoonline },
      { label: "Amipass", value: amipass }
    ]

    return (
      <Container>
        <Segment loading={loading}>
          <AlertMessage message={error} />
          <Grid verticalAlign="middle">
            <Grid.Column width={6}>
              <div className="form-group">
                <label>Selecciona la fecha Desde</label>
                <CalendarInput value={desde} onChange={desde => this.setState({ desde, visibleDesde: false })}
                  visible={visibleDesde} toggle={() => this.setState({ visibleDesde: !visibleDesde })} />
              </div>
            </Grid.Column>
            <Grid.Column width={6}>
              <div className="form-group">
                <label>Selecciona la fecha Hasta</label>
                <CalendarInput value={hasta} onChange={hasta => this.setState({ hasta, visibleHasta: false })}
                  visible={visibleHasta} toggle={() => this.setState({ visibleHasta: !visibleHasta })} />
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <Button.Group>
                <Button primary onClick={() => this.buscar()}>
                  <Icon name="search" />
                  Buscar
                </Button>
                <Button color="green" onClick={this.exportar}>
                  <Icon name="download" />
                  Descargar
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Segment>
        {pedidos.length > 0 && <div>
          <EstadisticasClp data={totales} />
          <EstadisticasClp data={totales_ventas} />
          <EstadisticasClp data={totales_medios_pago} />
          <TablaDetalle pedidos={pedidos} gastos={gastos} busy={loading} />
        </div>}
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({ 
  sucursales: state.session.sucursales,
  sucursal: sucursal_fs(state),
  conceptos: conceptos_from_state(state),
  categorias: categorias_from_state(state)
 })

export default connect(mapStateToProps)(Compraventa)