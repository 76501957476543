export const CREATE_SESSION = 'CREATE_SESSION'
export const FIJAR_SESSION = 'FIJAR_SESSION'
export const DESTROY_SESSION = 'DESTROY_SESSION'
export const FIJAR_SUCURSAL = 'FIJAR_SUCURSAL'
export const FIJAR_SUCURSALES = 'FIJAR_SUCURSALES'
export const FIJAR_ERROR = 'FIJAR_ERROR'
export const LIMPIAR_ERROR = 'LIMPIAR_ERROR'
export const FIJAR_COMUNAS = 'FIJAR_COMUNAS'
export const FIJAR_USUARIOS = 'FIJAR_USUARIOS'
export const FIJAR_INVENTARIO_ITEMS = 'FIJAR_INVENTARIO_ITEMS'
export const FIJAR_WINDOWS_SIZE = 'FIJAR_WINDOWS_SIZE'
export const FIJAR_COMPRAS = 'FIJAR_COMPRAS'
export const FIJAR_CONCEPTOS = 'FIJAR_CONCEPTOS'
export const FIJAR_CATEGORIAS_CONCEPTOS = 'FIJAR_CATEGORIAS_CONCEPTOS'
export const FIJAR_REPORTE_ANUAL = 'FIJAR_REPORTE_ANUAL'
