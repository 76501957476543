import React, {Component} from 'react'
import { Container, Segment, Form, Button, Icon, Table } from 'semantic-ui-react';

import { JornadaItem, Loading, AlertMessage, Paginacion, filterPagination, CalendarInput } from '../../components'
import { buscarJornadasPorFecha } from '../../api'
import { JORNADA_DETALLE } from "../../utils/Routes";
import moment, { ISO_DATE } from "../../utils/Moment";



export default class Jornadas extends Component {
  state = {
    jornadas: [],
    desde: new Date(),
    hasta: new Date(),
    visibleDesde: false,
    visibleHasta: false,
    loading: false,
    current: 0,
    error: ''
  }

  componentDidMount() {
    const desde = moment().subtract(7, 'days').toDate()
    this.setState({ desde })
  }

  buscar = () => {
    const { desde, hasta } = this.state

    this.setState({ loading: true, error: '' })
    buscarJornadasPorFecha(moment(desde).format(ISO_DATE), moment(hasta).format(ISO_DATE)).then(res => {
      if (res.status === 'success') {
        this.setState({ jornadas: res.data, current: 0 })
      } else {
        this.setState({ error: res.data })
      }
    }).finally(() => this.setState({ loading: false }))
  }

  detalle = (id) => {
    this.props.history.push(JORNADA_DETALLE.replace(':id', id))
  }

  page = (current) => {
    //if (current > -1 && current < (this.state.jornadas.length / PAGE_SIZE) ) {
      this.setState({ current })
    //}
  }

  render() {
    const { current, jornadas, desde, hasta, loading, visibleDesde, visibleHasta, error } = this.state
    if (loading) return <Loading />

    return (
      <Container>
        <Segment>
            <AlertMessage message={error} />
            <Form onSubmit={this.buscar}>
              <Form.Group widths={3}>
                <Form.Field>
                  <label>Desde</label>
                  <CalendarInput value={desde} onChange={desde => this.setState({ desde, visibleDesde: false })}
                    visible={visibleDesde} toggle={() => this.setState({ visibleDesde: !visibleDesde })} />
                </Form.Field>
                <Form.Field>
                  <label>Hasta</label>
                  <CalendarInput value={hasta} onChange={hasta => this.setState({ hasta, visibleHasta: false })}
                    visible={visibleHasta} toggle={() => this.setState({ visibleHasta: !visibleHasta })} />
                </Form.Field>
                <div style={{ alignItems: 'flex-end', display: 'flex'}}>
                  <Button primary icon labelPosition="left">
                    <Icon name="search" />
                    Buscar
                  </Button>
                </div>
              </Form.Group>
            </Form>
        </Segment>
        <JornadasData data={jornadas} page={this.page} current={current} detalle={this.detalle} />
      </Container>
    )
  }
}

function JornadasData({ data, page, current, detalle }) {
  const filtrado = filterPagination(data, current)
  if (filtrado.length === 0) {
    return null
  }

  return (
    <Segment basic>
      <Table selectable>
        <Table.Header>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.HeaderCell>Fecha Apertura</Table.HeaderCell>
          <Table.HeaderCell>Fecha Cierre</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {filtrado.map(j => <JornadaItem key={j.id} {...j} onClick={detalle} />)}
        </Table.Body>
      </Table>
      <Paginacion page={page} length={data.length} frame={current} />
    </Segment>
  )
}