import React, {Component} from 'react'

import Loading from '../../components/Loading'
import { historialInventario } from '../../api'
import moment from "../../utils/Moment";
import { INVENTARIO_DETALLE } from '../../utils/Routes';



export default class Inventario extends Component {
  state = {
    loading: true,
    historial: []
  }

  componentDidMount() {
    historialInventario().then(res => {
      if (res.status === 'success') {
        this.setState({ historial: res.data })
      }
      //return fetchEventosInventario(res.data.map(v => v.uuid)).then(console.log)
    }).finally(() => this.setState({ loading: false }))
  }

  detalle = (uuid, anterior) => {
    this.props.history.push(INVENTARIO_DETALLE.replace(':uuid', uuid).replace(":anterior", anterior))
  }

  render() {
    let { historial, loading } = this.state
    if (loading) return <Loading />

    return (
      <div className="container">
        <ul className="list-group mt-3">
          {historial.sort(sortHistorial).map(periodo => (
            <HistorialItem key={periodo.uuid} {...periodo} historial={historial} onClick={this.detalle} />
          ))}
        </ul>
      </div>
    )
  }
}

function HistorialItem({ uuid, anterior, cierre, historial, onClick }) {
  let inventarioAnterior = historial.find(v => v.uuid === anterior)
  return (
    <li className="list-group-item cs-pointer" onClick={() => onClick(uuid, anterior)}>
      <div className="row">
        <div className="col-6">
          <div>Inicio</div>
          <div><strong>{inventarioAnterior && formatDatetime(inventarioAnterior.cierre)}</strong></div>
        </div>
        <div className="col-6">
          <div>Cierre</div>
          <div><strong>{formatDatetime(cierre)}</strong></div>
        </div>
      </div>
      <small>{uuid}</small>
    </li>
  )
}

function sortHistorial(a, b) {
  return moment(a.cierre).isBefore(moment(b.cierre)) ? 1 : -1
}

function formatDatetime(datetime) {
  return moment(datetime).format('DD/MM/YYYY - HH:mm:ss')
}