import React from 'react'
import { connect } from 'react-redux';

import Screen from '../Screen'
import { isSuccess, crearUsuario, modificarUsuario } from '../../api'
import Permisos, { OPERADOR, rol } from "../../utils/Permisos";
import { isEmpty, int } from "../../utils";
import { Checkbox, AlertMessage } from '../../components';
import { ArrowLeft } from "../../components/Icons";


class NuevoUsuarios extends Screen {
  state = {
    email: '',
    permisos: OPERADOR.toString(),
    sucursales: [],
    loading: false,
    error: ''
  }

  componentDidMount() {
    const email = this.props.match.params.email
    const usuario = this.props.usuarios.find(v => v.email === email)

    if (usuario) {
      this.setState({ 
        email: email,
        permisos: usuario.permisos.toString(),
        sucursales: this.props.sucursales.map(v => ({ ...v, checked: usuario.sucursales.some(s => s === v.id) }))
      })
    }  else { 
      this.setState({ sucursales: this.props.sucursales.map(v => ({ ...v, checked: false }))})
      

    }
  }

  submit = () => {
    const { email, permisos, sucursales } = this.state
    const modificar = !isEmpty(this.props.match.params.email)

    const data = {
      email: email.trim().toLowerCase(),
      rol: rol(int(permisos)),
      sucursales: sucursales.filter(v => v.checked).map(v => v.id)
    }

    this.toggleLoading()
    this.setState({ error: '' })

    const _fnc = modificar ? modificarUsuario : crearUsuario
    _fnc(data).then(res => {
      if (isSuccess(res)) {
        this.goBack()
      } else {
        this.setState({ error: res.data })
      }
    }).catch(this.onError).finally(this.toggleLoading)
  }

  checkSucursal = (id) => this.setState({
    sucursales: this.state.sucursales.map(p =>
      ({ ...p, checked: p.id === id ? !p.checked : p.checked }))
  })

  onChange = (e) => this.setState({ [e.target.id]: e.target.value })

  render() {
    const { email, sucursales, permisos, error, loading } = this.state
    const disabled = isEmpty(email) || loading
    const modificar = !isEmpty(this.props.match.params.email)
    
    return (
      <div className="container">
        <div className="card">
          <div className="card-body">
            <AlertMessage message={error} />
            <div className="mb-3 row justify-content-between">
              <button className="btn btn-link" type="button" onClick={this.goBack}><ArrowLeft dark /></button>
              <button className="btn btn-primary" type="button" onClick={this.submit} disabled={disabled}>
                {modificar ? 'Modificar' : 'Nuevo'}
              </button>
            </div>
            <form className="form-row">
              <div className="col-6">
                <div className="form-group">
                  <label>Email</label>
                  <input className="form-control" id="email" value={email} onChange={this.onChange} disabled={modificar}/>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Permisos</label>
                  <select className="form-control" id="permisos" value={permisos} onChange={this.onChange}>
                    {Permisos.map(p => <option key={p.value} value={p.value.toString()}>{p.label}</option>)}
                  </select>
                </div>
              </div>
              <div className="col-12">
                <ul className="list-group list-group-flush">
                  {sucursales.map(({ id, nombre, checked }) => (
                    <li className="list-group-item" key={id}>
                      <div className="row">
                        <div className="col-1" onClick={() => this.checkSucursal(id)}><Checkbox checked={checked} /></div>
                        <div className="col-6">{nombre}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}



const mapStateToProps = ({ session, usuarios }) => ({
  sucursales: session.sucursales,
  usuarios: usuarios.data
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(NuevoUsuarios)