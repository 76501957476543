import createReducer from './createReducer'
import { DESTROY_SESSION, FIJAR_REPORTE_ANUAL } from '../actions/Types'

const initialState = {
  anual: []
}

export const reportes = createReducer(initialState, {
  [DESTROY_SESSION]: () => ({ ...initialState }),
  [FIJAR_REPORTE_ANUAL]: (state, { reporte, anio }) => ({
    anual: [...state.anual.filter(v => v.key !== anio), {
      key: anio, data: reporte
    }]
  }),
})

export function reporte_anual_fs(state) {
  return state.reportes.anual
}