import React, {Component} from 'react'
import { connect } from 'react-redux';
import moment from 'moment';

import { Button, Container, Form, Icon, Menu, Segment, Select, Statistic, Table } from 'semantic-ui-react';
import { ANIOS, clp, MESES_AGNIO } from '../../utils';
import { fetchRatios, isSuccess, obtenerProyeccion, resumenCompraVenta } from '../../api';


class ResumenCompraVenta extends Component {
  state = {
    mes: moment().month() + 1,
    anio: moment().year(),
    conceptos: [],
    proyeccion: 0,
    ratios: [],
    grupos: [],
    gastos_categoria: [],
    id_grupo: 2,
    total_ventas: 0,
    total_pendiente: 0,
    total_pagado: 0,
    loading: false,
  }

  async componentDidMount() {
    try {
      const res = await obtenerProyeccion()
      console.log(res)
      if (isSuccess(res)) {
        this.setState({ proyeccion: res.data })
      }
      const res2 = await fetchRatios()
      console.log(res2)
      if (isSuccess(res2)) {
        this.setState({
          grupos: res2.data.grupos.map(v => ({ key: v.id.toString(), value: v.id, text: v.nombre })),
          ratios: res2.data.ratios
        })
      }
      await this.fetch()
    } catch (e) {
      console.error('ResumenCompraVenta', e)
    }
  }

  onMesChange = (value) => {
    this.setState({ mes: value })
    this.fetch(value, this.state.anio)
  }

  onAnioChange = (value) => {
    this.setState({ anio: value })
    this.fetch(this.state.mes, value)
  }

  onRatiosChange = (value) => {
    this.setState({ id_grupo: value })
  }

  fetch = async (mes, anio) => {
    mes = mes ? mes : this.state.mes
    anio = anio ? anio : this.state.anio
    const fecha = `${anio}-${mes < 10 ? '0' + mes : mes}`
    const dias = moment(fecha, "YYYY-MM").daysInMonth()
    const fecha_desde = `${fecha}-01`
    const fecha_hasta = `${fecha}-${dias}`

    try {
      this.setState({ loading: true })
      const res = await resumenCompraVenta(fecha_desde, fecha_hasta)
      if (isSuccess(res)) {
        const resumen_compras = res.data.resumen_compras
        this.setState({
          conceptos: resumen_compras.compras,
          total_pendiente: resumen_compras.total_pendiente,
          total_pagado: resumen_compras.total_pagado,
          total_ventas: res.data.total_ventas,
          gastos_categoria: res.data.gastos_categoria
        })
      } else {
        console.error(res)
      }
    } catch (e) {
      console.error('fetch', e)
    } finally {
      this.setState({ loading: false })
    }
  }
  
  render() {
    const { loading, anio, mes, conceptos, grupos, id_grupo, total_ventas, ratios, proyeccion,
      total_pagado, total_pendiente, gastos_categoria } = this.state

    const conceptos_data = [ ...conceptos, ...gastos_categoria]
    const total_gastos = gastos_categoria.reduce((t, v) => t + v.total, 0)
    const ratios_data = ratios.filter(v => v.id_grupo === id_grupo)
    const total_compras = conceptos_data.reduce((t, v) => t + v.total, 0)
    const margen = total_ventas - total_compras

    return (
      <Container>
        <Menu>
          <Menu.Item>
            <Form.Field control={Select} options={MESES_AGNIO} value={mes} onChange={(e, { value }) => this.onMesChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
          <Menu.Item>
            <Form.Field control={Select} options={ANIOS} value={anio} onChange={(e, { value }) => this.onAnioChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
          <Menu.Item position="right">
            <Form.Field control={Select} options={grupos} value={id_grupo} onChange={(e, { value }) => this.onRatiosChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
        </Menu>
        <Segment loading={loading}>
          <Statistic.Group widths="2" size="tiny">
            <Statistic>
              <Statistic.Value>{clp(proyeccion)}</Statistic.Value>
              <Statistic.Label>Ventas Proyectadas</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{clp(total_ventas)}</Statistic.Value>
              <Statistic.Label>Ventas a la fecha</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>
        <Segment loading={loading}>
          <Statistic.Group widths="4" size="tiny">
            <Statistic>
              <Statistic.Value>{clp(total_compras)}</Statistic.Value>
              <Statistic.Label>Total Gastos</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{clp(total_gastos)}</Statistic.Value>
              <Statistic.Label>Gastos Aplicación</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{clp(total_pagado)}</Statistic.Value>
              <Statistic.Label>Gastos Pagados</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{clp(total_pendiente)}</Statistic.Value>
              <Statistic.Label>Gastos Pendientes</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>
        <Segment textAlign="center" loading={loading}>
          <Statistic size="tiny" color={margen < 0 ? 'red' : "black"}>
            <Statistic.Value>{clp(margen)}</Statistic.Value>
            <Statistic.Label>Margen</Statistic.Label>
          </Statistic>
        </Segment>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="4"/>
              <Table.HeaderCell width="6">Presupuesto</Table.HeaderCell>
              <Table.HeaderCell width="6">Gastado</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {conceptos_data.map(v => <Presupuesto key={v.id} { ...v} ratios={ratios_data} proyeccion={proyeccion} />)}
          </Table.Body>
        </Table>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="4" />
              <Table.HeaderCell width="6">Disponible</Table.HeaderCell>
              <Table.HeaderCell width="6">Porcentaje Utilizado</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {conceptos_data.map(v => <Estimaciones key={v.id} {...v} ratios={ratios_data} proyeccion={proyeccion} />)}
          </Table.Body>
        </Table>
      </Container>
    )
  }
}

const Presupuesto = ({ id, concepto, total, ratios, proyeccion }) => {
  const ratio = ratios.find(v => v.id_categoria === id)
  const proporcion = ratio ? proyeccion * ratio.ratio : 0

  return (
    <Table.Row >
      <Table.Cell>{concepto}</Table.Cell>
      <Table.Cell>{clp(proporcion)}</Table.Cell>
      <Table.Cell>{clp(total)}</Table.Cell>
    </Table.Row>
  )
}

const Estimaciones = ({ id, concepto, total, proyeccion, ratios }) => {
  const ratio = ratios.find(v => v.id_categoria === id)
  const proporcion = ratio ? proyeccion * ratio.ratio : 0
  const disponible = proporcion - total
  const razon = total / proporcion

  return (
    <Table.Row negative={disponible < 0}>
      <Table.Cell>{concepto}</Table.Cell>
      <Table.Cell>{clp(disponible)}</Table.Cell>
      <Table.Cell>{Math.floor(razon * 100)}%</Table.Cell>
    </Table.Row>
  )
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, {  })(ResumenCompraVenta)