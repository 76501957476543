import React, {Component} from 'react'
import Chart from "react-apexcharts";

import Loading from '../components/Loading'
import { resumenAnual } from "../api";
import moment, { agnios, meses, mesLabel } from '../utils/Moment';
import { clp } from '../utils';


const initAgnio = moment().year().toString()

const resumenMensualOptions = {
  chart: {
    id: "basic-bar",
    type: 'bar',
    toolbar: {
      show: false,
    }
  },
  colors: ['#9e6de0', '#faafca', '#f2e052'],
  legend: {
    show: true,
    position: 'top',
    horizontalAlign: 'right',
    markers: {
      width: 20,
      height: 5,
      radius: 0,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '50%',
      barHeight: '10%',
      distributed: false,
    }
  },
  dataLabels: {
    enabled: false
  },

  stroke: {
    show: true,
    width: 2,
    curve: 'smooth'
  },
  xaxis: {
    categories: [],

    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      width: 40,
    }
  },
  yaxis: {
    labels:{
      formatter: (value) => clp(value)
    }
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    shared: true,
    intersect: false,
    followCursor: true,
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return seriesName
        }
      },
    },
  }
}

const splineAreaOptions = {
  chart: {
    group: 'social',
    height: 135,
    width: '100%',
    background: '#fd5190',
    type: 'area',
    sparkline: {
      enabled: true,
    }
  },
  yaxis: {
    labels: {
      minWidth: 40
    }
  },
  stroke: {
    width: 2
  },
  colors: ['rgba(255, 255, 255, 0.6)'],
  //colors: ['#FFFFFF'],
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.3,
      stops: [0, 50, 100]
    },
  },

  tooltip: {
    theme: 'dark',
    marker: {
      show: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function () {
          return '';
        }
      }
    }
  },
}

export default class Dashboard extends Component {
  state = {
    loading: true,
    agnio: initAgnio,
    pedidos: [],
    gastos: [],
    categories: []
  }

  componentDidMount() {
    this.refreshResumenAnual(this.state.agnio)
  }

  refreshResumenAnual = (agnio) => {
    this.setState({ loading: true })
    console.log('año', agnio)
    resumenAnual(agnio + '-01-01T00:00:00', agnio + '-12-31T23:59:59').then(res => {
      //console.log(res)
      if (res.status === 'success') {
        const { compras, ventas } = res.data
        const meses = ventas.map(p => p.mes).map(mesLabel)

        console.log(meses, compras, ventas)
        this.setState({ pedidos: ventas, gastos: compras, categories: meses })
      }
    }).finally(() => this.setState({ loading: false }))
  }

  onAgnioChange = (e) => {
    const agnio = e.target.value
    this.setState({ agnio })
    this.refreshResumenAnual(agnio)
  }

  render() {
    const { agnio, loading, pedidos, gastos } = this.state
    if (loading) return <Loading />

    const ventas = meses().map(v => {
      const p = pedidos.find(p => p.mes === v.id)
      return p ? p.total : 0
    })
    const compras = meses().map(v => {
      const g = gastos.find(g => g.mes === v.id)
      return g ? g.total : 0
    })
    const ganancias = meses().map(v => {
      const gasto = gastos.find(g => g.mes === v.id)
      const venta = pedidos.find(p => p.mes === v.id)
      return (venta ? venta.total : 0) - (gasto ? gasto.total : 0)
    })

    const total_ventas = ventas.reduce((v, t) => t + v, 0)
    const total_gastos = compras.reduce((v, t) => t + v, 0)
    const total_margen = ganancias.reduce((v, t) => t + v, 0)

    const options = {
      ...resumenMensualOptions,
      xaxis: {
        ...resumenMensualOptions.xaxis,
        categories: meses().map(v => v.label)
      }
    }

    const series = [{
      name: 'Ventas',
      type: 'column',
      data: ventas
    }, {
      name: 'Gastos',
      type: 'column',
      data: compras
    },
    {
      name: 'Ganancia',
      data: ganancias,
      type: 'line'
    }]

    return (
      <div className="container">
        <div className="row mt-2">
          <div className="col-xl-3 col-sm-6">
            <div className="card">
              <div className="card-body">
                <select className="form-control" id="agnio" value={agnio} onChange={this.onAgnioChange}>
                  {agnios().map(a => <option key={a} value={a}>Año {a}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <MiniCard label="Ventas del año" value={clp(total_ventas)} data={ventas} color="#fd5190" />
          </div>
          <div className="col-xl-3 col-sm-6">
            <MiniCard label="Gastos del año" value={clp(total_gastos)} data={compras} color="#46c79e"/>
          </div>
          <div className="col-xl-3 col-sm-6">
            <MiniCard label="Margen del año" value={clp(total_margen)} data={ganancias} color="#9e6de0" />
          </div>
          
        </div>
        <div className="card card-default">
          <div className="card-body">
            <div className="chart-wrapper">
              <Chart
                options={options}
                series={series}
                height={370}
                type="bar"
              />
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const MiniCard = ({ value, label, data, color }) => (
  <div className="card card-default card-mini">
    <div className="card-header">
      <h2>{value}</h2>
      <div className="sub-title">
        <span className="mr-1">{label}</span>
      </div>
    </div>
    <div className="card-body">
      <div className="chart-wrapper">
        <div>
          <Chart options={{ ...splineAreaOptions, chart: { ...splineAreaOptions.chart, background: color }}} series={[{ data }]} type="area" />
        </div>
      </div>
    </div>
  </div>
)