import { Grid, Segment, Statistic } from "semantic-ui-react"
import { clp } from "../../utils"

const EstadisticasClp = ({ data }) => {
  return (
    <Segment>
      <Grid columns={data.length} textAlign="center" stackable>
        <Grid.Row>
          {data.map((v, i) => (
            <Grid.Column key={i}>
              <Estadistica label={v.label} value={clp(v.value)} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

const Estadistica = ({ label, value }) => (
  <Statistic size="tiny">
    <Statistic.Value>{value}</Statistic.Value>
    <Statistic.Label>{label}</Statistic.Label>
  </Statistic>
)

export {
  EstadisticasClp
}