import React, { Component } from "react";
import { Checkbox } from "./Forms";


export default class SeleccionarSucursalesModal extends Component {
  state = {
    sucursales: [],
    checkbox: false
  }

  componentDidMount() {
    this.setState({ sucursales: this.props.sucursales })
  }

  checkAll = () => {
    const checkbox = !this.state.checkbox
    this.setState({
      checkbox,
      sucursales: this.state.sucursales.map(p =>
        ({ ...p, checked: checkbox }))
    })
  }

  onChange = (id) => this.setState({ sucursales: this.state.sucursales.map(v => ({ ...v, checked: v.id === id ? !v.checked : v.checked })) })
  onClick = () => this.props.onClick(this.state.sucursales.filter(s => s.checked).map(s => s.id))

  render() {
    const { sucursales, checkbox } = this.state
    const disabled = sucursales.filter(v => v.checked).length === 0 || this.props.disabled

    return (
      <div id="modalSeleccionarSucursales" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="sucursales" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Selecciona las sucursales</h5>
              <div className="btn-group" role="group" aria-label="botones">
                <button type="button" className="btn btn-primary" onClick={this.onClick} disabled={disabled}>Copiar</button>
                <button type="button" className="btn btn-link" data-dismiss="modal">Volver</button>
              </div>
            </div>
            <div className="modal-body">
              <table className="table table-hover">
                <thead>
                  <tr onClick={this.checkAll}>
                    <th><Checkbox checked={checkbox} /></th>
                    <th>Nombre</th>
                  </tr>
                </thead>
                <tbody>
                  {sucursales.map(({ id, nombre, checked }) => (
                    <tr key={id} onClick={() => this.onChange(id)}>
                      <td><Checkbox checked={checked} /></td>
                      <td>{nombre}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}