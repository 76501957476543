import createReducer from './createReducer'
import { CREATE_SESSION, DESTROY_SESSION, FIJAR_SESSION, FIJAR_SUCURSAL } from '../actions/Types'

const initialState = {
  token: '',
  email: '',
  permisos: 0,
  nombre: '',
  sucursal: 0,
  sucursales: []
}

export const session = createReducer(initialState, {
  [CREATE_SESSION]: (state, { token, email }) => ({ ...state, token, email }),
  [DESTROY_SESSION]: () => ({ ...initialState }),
  [FIJAR_SESSION]: (state, { permisos, sucursal, sucursales }) => ({ 
    ...state,
    sucursal,
    sucursales,
    permisos: isNaN(permisos) ? 0 : permisos
  }),
  [FIJAR_SUCURSAL]: (state, { id }) => ({ ...state, sucursal: id })
})

export function permisos_fs(state) {
  return state.session.permisos
}

export function sucursal_fs(state) {
  return state.session.sucursales.find(v => v.id === state.session.sucursal)
}

export function sucursales_fs(state) {
  return state.session.sucursales.filter(v => v.id !== 1)
}