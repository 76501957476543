import React, {Component, useState} from 'react'

import { agregarInventario, cerrarInventario, isSuccess } from '../../api'
import { fetch_inventario_items } from '../../actions/InventarioActions';
import { connect } from 'react-redux';
import { Button, Card, Confirm, Container, Icon,  Input, Menu, Message, Segment } from 'semantic-ui-react';
import { clp, int, isEmpty, toggleModal } from '../../utils';
import { inventario_cantidad_float } from "../../lib/Logica";
import { INVENTARIO_DETALLE_ITEM } from '../../utils/Routes';
import { inventario_items_from_state } from '../../reducers';
import AlertModal from '../../components/AlertModal';


function toggleConfirmar() {
  toggleModal("confirmar-cierre")
}

class GestionInventario extends Component {
  state = {
    filtro: '',
    loading: true,
    cierre: false,
    confirmar: false,
    inventario_cierre: [],
    success: false,
    error: ''
  }

  async componentDidMount() {
    await this.fetch()
  }

  fetch = async () => {
    this.setState({ loading: true })
    await this.props.fetch_inventario_items()
    this.setState({ loading: false })
  }

  detalle = (id) => {
    this.props.history.push(INVENTARIO_DETALLE_ITEM.replace(':id', id))
  }

  cerrar_inventario = async () => {
    try  {
      this.setState({ loading: true, success: false })
      const eventos = this.state.inventario_cierre.filter(v => !isEmpty(v.cantidad)).map(v => ({
        ...v,
        cantidad: inventario_cantidad_float(v.cantidad)
      }))

      console.log(eventos)
      const res = await cerrarInventario({ eventos })
      console.log(res)
      if (isSuccess(res)) {
        this.toggleSuccess()
      } else {
        this.setState({ error: res.data })
      }
      await this.fetch()
    } catch (e) {
      console.log(e)
      this.setState({ error: e.message })
    } finally {
      this.toggleCierre()
      this.setState({ loading: false })
      toggleConfirmar()
    }
  }

  iniciarCierre = () => {
    const inventario_cierre = this.props.items.map(v => ({
      id_item: v.id,
      precio: v.precio,
      cantidad: v.cantidad > 0 ? v.cantidad.toString() : ''
    }))
    console.log(inventario_cierre)
    this.setState({ inventario_cierre })
    this.toggleCierre()
  }

  toggleSuccess = () => this.setState({ success: !this.state.success })
  toggleCierre = () => this.setState({ cierre: !this.state.cierre })
  onFiltro = (e) => this.setState({ filtro: e.target.value })

  onValueCierre = (id_item, precio, cantidad) => {
    const inventario = this.state.inventario_cierre
    const exist = inventario.find(v => v.id_item === id_item)
    
    const inventario_cierre = exist ? inventario.map(v => ({ ...v, cantidad: v.id_item === id_item ? cantidad : v.cantidad }))
     : [...inventario, { id_item, precio, cantidad }]

    this.setState({ inventario_cierre })
  }

  render() {
    const { items } = this.props
    const { loading, filtro, cierre, inventario_cierre, success, error } = this.state

    return (
      <div style={{ marginTop: 40 }}>
        <Container>
          <Menu>
            <Menu.Item>
              Gestion de inventario
            </Menu.Item>
            <Menu.Menu position="right">
              {cierre && <Menu.Item position="right">
                <Button onClick={this.toggleCierre}>Volver</Button>
              </Menu.Item>}
              <Menu.Item>
                <Button color={cierre ? 'green' : "red"} icon labelPosition="left" onClick={cierre ? toggleConfirmar : this.iniciarCierre}>
                  <Icon name="ban" />
                  {cierre ? 'Enviar inventario' : 'Cerrar Inventario'}
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          
          {success &&
            <Message color="green" onDismiss={this.toggleSuccess}>
              Inventario cerrado
            </Message>
          }
          {!isEmpty(error) && <Message color="red">
            ERROR: {error}
          </Message>}
          {cierre && <Message color="yellow">
            Ingresa las cantidades finales para cada producto
          </Message>}
          <Segment loading={loading}>
            <Input placeholder="Buscar por nombre" fluid icon="search" iconPosition="left" onChange={this.onFiltro} value={filtro} />
          </Segment>
          <Card.Group itemsPerRow={3}>
            {items.filter(v => filterItems(v, filtro)).map(value => (
              <Tarjeta key={value.id} {...value} onSuccess={this.fetch} onDetalle={this.detalle}
                cierre={cierre} onValueCierre={this.onValueCierre}
                valueCierre={inventario_cierre.find(v => v.id_item === value.id)} />
            ))}
          </Card.Group>
        </Container>
        <AlertModal id="confirmar-cierre" onClick={this.cerrar_inventario}
         label="Continuar" title="Cerrar inventario" message="Vas a cerrar el inventario, continuar?" />
      </div>
    )
  }
}

const Tarjeta = ({ id, nombre, precio, cantidad, unidad, consumo, onSuccess, onDetalle, cierre, valueCierre, onValueCierre }) => {
  const [editable, setEditable] = useState(false)
  const [busy, setBusy] = useState(false)
  const [value, setValue] = useState('')

  const toggle = () => setEditable(!editable)
  const cantidadChange = (e) => setValue(e.target.value)
  
  const plus = () => agregar(1)
  const minus = () => agregar(-1)
  const agregar = async (sign) => {
    if (isEmpty(value) || isNaN(value)) {
      return setEditable(false)
    }

    const data = {
      id_item: id,
      precio: precio,
      cantidad: inventario_cantidad_float(value) * sign
    }

    try {
      setBusy(true)
      await agregarInventario(data)
      setValue('')
      setEditable(false)
      onSuccess()
    } catch (e) {
      console.log(e)
    } finally {
      setBusy(false)
    }
  }

  const _value_cierre = valueCierre ? valueCierre.cantidad : ''

  return (
    <Card as="a">
      <Card.Content onClick={() => onDetalle(id)}>
        <Card.Header>{nombre}</Card.Header>
        <Card.Meta>{clp(precio)}</Card.Meta>
        <Card.Description>{cantidad} {unidad}</Card.Description>
        <Card.Description>Consumo {consumo}</Card.Description>
      </Card.Content>
      {!cierre && <Card.Content extra>
        {editable ?
          <InputCantidad close={toggle} value={value} onChange={cantidadChange} plus={plus} minus={minus} busy={busy} /> :
          <Button basic color='green' fluid onClick={toggle}>
            Agregar
          </Button>
        }
      </Card.Content>}
      {cierre &&
        <Card.Content extra>
          <Input fluid placeholder="Ingrese la cantidad final" type="number"
           value={_value_cierre} onChange={e => onValueCierre(id, precio, e.target.value)} loading={busy} />
        </Card.Content>}
    </Card>
  )
}

const InputCantidad = ({ close, value, onChange, plus, minus, busy }) => (
  <Input fluid placeholder="Ingrese la cantidad" type="number" action value={value} onChange={onChange} loading={busy}>
    <input />
    <Button icon="plus" color="green" onClick={plus} loading={busy} />
    <Button icon="minus" color="red" onClick={minus} loading={busy}/>
    <Button icon="times" secondary onClick={close} loading={busy}/>
  </Input> 
)

function filterItems(item, filtro) {
  return item.nombre.toLowerCase().indexOf(filtro.toLowerCase()) > -1
}


const mapStateToProps = (state) => ({
  items: inventario_items_from_state(state)
})

export default connect(mapStateToProps, { fetch_inventario_items })(GestionInventario)