import { fetchComunas, isSuccess, fetchSucursales } from "../api";
import { FIJAR_COMUNAS, FIJAR_SUCURSALES } from "./Types";


export function obtenerComunas() {
  return (dispatch) => fetchComunas().then(res => {
    if (isSuccess(res)) {
      dispatch({ type: FIJAR_COMUNAS, comunas: res.data })
    }
    return res
  })
}

export function obtenerSucursales() {
  return (dispatch) => fetchSucursales().then(res => {
    if (isSuccess(res)) {
      dispatch({ type: FIJAR_SUCURSALES, sucursales: res.data })
    }
    return res
  })
}