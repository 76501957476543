import React, {Component} from 'react'

import Loading from '../components/Loading'
import { buscarPedidosPorFecha } from '../api'
import moment from "../utils/Moment";
import { PedidoItem, AlertMessage, CalendarInput } from '../components';
import { pedidos_xlsx } from '../lib/JornadaExport';

const PAGE_SIZE = 50
const ISO_DATE = "YYYY-MM-DDTHH:mm:ss"

/**@Deprecated
 * Antiguo
 **/
export default class Pedidos extends Component {
  state = {
    loading: false,
    pedidos: [],
    desde: new Date(),
    hasta: new Date(),
    page: 0,
    calendarDesde: false,
    calendarHasta: false,
    error: ''
  }

  componentDidMount() {
    const desde = moment().subtract(7, 'days')
    
    this.setState({ desde: desde.toDate() })
    //this.buscar(desde)
  }

  buscar = (desde, hasta) => {
    desde = desde ? desde : this.state.desde
    hasta = hasta ? hasta : this.state.hasta

    //console.log(moment(desde).format('YYYY-MM-DD'), moment(hasta).format('YYYY-MM-DD'))
    this.setState({ loading: true, error: '' })
    buscarPedidosPorFecha(moment(desde).format(ISO_DATE), moment(hasta).format(ISO_DATE)).then(res => {
      if (res.status === 'success') {
        this.setState({ pedidos: res.data.sort(sortFecha) })
      } else {
        console.log(res.data)
        this.setState({ error: res.data })
      }
    }).catch(console.error).finally(() => this.setState({ loading: false }))
  }

  export = () => {
    pedidos_xlsx(this.state.pedidos)
  }

  next = () => {
    let { page, pedidos } = this.state

    if (page < (pedidos.length / PAGE_SIZE) - 1) {
      this.setState({ page: page + 1 })
    }
  }

  page = (page) => {
    this.setState({ page })
  }

  before = () => {
    let { page } = this.state

    if (page > 0) {
      this.setState({ page: page - 1 })
    }
  }

  render() {
    const { pedidos, desde, hasta, page, loading, visibleDesde, visibleHasta, error } = this.state
    if (loading) return <Loading />

    const maxpages = Math.ceil(pedidos.length / PAGE_SIZE)
    const data = pedidos.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE)

    let pages = []
    for (let i = 0; i < maxpages; i++) {
      pages.push(i)
    }

    const index = page < 5 ? 0 : page - 5
    pages = pages.slice(index, index + 10)

    return (
      <div className="container">
        <h4>Pedidos</h4>
        <div className="card">
          <div className="card-body">
            <AlertMessage message={error} />
            <div className="d-flex align-items-center justify-content-center">
              <CalendarInput value={desde} onChange={desde => this.setState({ desde, visibleDesde: false })}
                visible={visibleDesde} toggle={() => this.setState({ visibleDesde: !visibleDesde })} />
              <div className="p-2"><i className="fas fa-arrow-right fa-2x"></i></div>
              <CalendarInput value={hasta} onChange={hasta => this.setState({ hasta, visibleHasta: false })}
                visible={visibleHasta} toggle={() => this.setState({ visibleHasta: !visibleHasta })} />
              <button type="button" className="btn btn-primary" onClick={() => this.buscar()}>
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item d-flex justify-content-between">
            <button className="btn btn-success" type="button" onClick={this.export}>Exportar</button>
            <div>{pedidos.length} Pedidos</div>
          </li>
        {data.length === 0 && <li className="list-group-item">No hay pedidos</li>}
          {data.map(p => (
            <PedidoItem key={p.id} { ...p } />
          ))}
        </ul>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center m-3">
            {maxpages > 10 &&
              <li className="page-item"><button className="page-link" type="button" onClick={() => this.page(0)}>Primero</button></li>
            }
            <li className="page-item"><button className="page-link" type="button" onClick={this.before}>Anterior</button></li>
            {pages.map(p => (
              <PaginationItem key={p} page={this.page} value={p} active={p === page} />
            ))}
            <li className="page-item"><button className="page-link" type="button" onClick={this.next}>Siguiente</button></li>
            {maxpages > 10 &&
              <li className="page-item"><button className="page-link" type="button" onClick={() => this.page(maxpages - 1)}>Ultimo</button></li>
            }
          </ul>
        </nav>
      </div>
    )
  }
}

const PaginationItem = ({ value, page, active }) => (
  <li className={"page-item" + (active ? ' active' : '')}>
    <button type="button" className="page-link" onClick={() => page(value)}>{value + 1}</button>
  </li>
)

function sortFecha(a, b) {
  return moment(a.datetime).isBefore(moment(b.datetime)) ? 1 : -1
}