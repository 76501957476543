import { FIJAR_ERROR, LIMPIAR_ERROR } from './Types'

export function fijarError(error) {
  return {
    type: FIJAR_ERROR,
    error
  }
}

export function limpiarError() {
  return {
    type: LIMPIAR_ERROR
  }
}