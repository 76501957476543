import moment from "moment";
import { isSuccess, resumenSucursalesAnual } from "../api";
import { reporte_anual_fs } from "../reducers/ReportesReducer";
import { FIJAR_REPORTE_ANUAL, FIJAR_WINDOWS_SIZE } from "./Types";


export function fijar_window_size() {
  return {
    type: FIJAR_WINDOWS_SIZE
  }
}

export function fijar_reporte_anual(anio) {
  return async (dispatch, getState) => {
    try {
      const actual = moment().year()
      const anual = reporte_anual_fs(getState())
      if (!anual.find(v => v.key === anio) || anio === actual) {
        const res = await resumenSucursalesAnual(anio)
        if (isSuccess(res)) {
          dispatch({
            type: FIJAR_REPORTE_ANUAL,
            reporte: res.data,
            anio
          })
        }
      } else {
        console.log('cacheado')
      }
    } catch (e) {
      console.error(e)
    }
  }
}