import React from 'react'

const PAGE_SIZE = 20

export function filterPagination(data, frame) {
  return data.slice(frame * PAGE_SIZE, frame * PAGE_SIZE + PAGE_SIZE)
}

export default function ({ length, page, frame }) {
  const { pagesData, max } = pages(length, frame)
  if (pagesData.length < 2) return <div></div>

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center m-3">
        {max > 10 &&
          <li className="page-item"><button className="page-link" type="button" onClick={() => page(0)}>
            <i className="far fa-angle-double-left"></i>
          </button></li>
        }
        <li className="page-item"><button className="page-link" type="button" onClick={() => frame > 0 && page(frame - 1)}>
          <i className="far fa-angle-left"></i>
        </button></li>
        {pagesData.map(p => <PaginationItem key={p} page={page} value={p} active={p === frame} />)}
        <li className="page-item"><button className="page-link" type="button" onClick={() => frame < (max - 1) && page(frame + 1)}>
          <i className="far fa-angle-right"></i>
        </button></li>
        {max > 10 &&
          <li className="page-item"><button className="page-link" type="button" onClick={() => page(max - 1)}>
            <i className="far fa-angle-double-right"></i>
          </button></li>
        }
      </ul>
    </nav>
  )
}

const PaginationItem = ({ value, page, active }) => (
  <li className={"page-item" + (active ? ' active' : '')}>
    <button type="button" className="page-link" onClick={() => page(value)}>{value + 1}</button>
  </li>
)

function pages(length, frame) {
  const max = Math.ceil(length / PAGE_SIZE)
  let pagesData = []

  for (let i = 0; i < max; i++) {
    pagesData.push(i)
  }

  const index = frame < 4 ? 0 : frame - 4
  pagesData = pagesData.slice(index, index + 10)

  return { pagesData, max }
}