import React, { Component } from "react";

import { isEmpty, int, toggleModal } from "../utils";
import { agregarProducto, galleryUri, modificarProducto } from "../api";
import { Form } from "semantic-ui-react";



export default class ProductoModal extends Component {
  state = {
    extraName: '',
    nombre: '',
    descripcion: '',
    todos: false,
    loading: false
  }

  guardar = () => {
    const { _id, index, title, detail, price, asset, tags, products, extras, subproducts_category, uuid } = this.props.producto
    const data = {
      _id,
      uuid: this.state.todos ? uuid : undefined,
      index: int(index),
      title: title.trim(),
      detail: detail.trim(),
      price: int(price),
      asset: asset,
      tags: tags,
      products: products,
      extras: extras,
      subproducts_category,
      sucursal: int(this.props.sucursal),
    }

    const _fnc = _id ? modificarProducto : agregarProducto
    this.setState({ loading: true })

    _fnc(data).then(console.log).then(this.props.refrescar).then(() => {
      toggleModal('modalProducto')
    }).catch(console.error).finally(() => this.setState({ loading: false }))
  }

  agregarExtra = () => {
    this.props.agregarExtra(this.state.extraName.trim())
    this.setState({extraName: ''})
  }

  agregarProducto = () => {
    let title = this.state.nombre.trim()
    let detail = this.state.descripcion.trim()

    this.props.agregarProducto({ title, detail })
    this.setState({ nombre: '', descripcion: '' })
  }

  render() {
    const { extraName, nombre, descripcion, loading, todos } = this.state
    const { categorias, producto, imagenes } = this.props
    const { index, title, detail, price, asset, tags, products, extras, subproducts_category, uuid } = producto

    const disabled = isEmpty(title) || isEmpty(price) || loading
    const thumbnail = isEmpty(asset) ? "img/empty.jpg" : galleryUri(asset)

    return (
      <div id="modalProducto" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="productos" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn btn-success" onClick={this.guardar} disabled={disabled}><i className="fas fa-save fa-lg"></i> Guardar</button>
              <button type="button" className="btn btn-link text-dark" data-dismiss="modal"><i className="fas fa-times fa-lg"></i></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-4 text-center">
                  <img src={thumbnail} className="img-fluid" alt="thumbnail" />
                </div>
                <div className="col-8">
                  {uuid && <Form.Field>
                    <input type="checkbox" id="uuid" checked={todos} onChange={e => this.setState({ todos: e.target.checked })} />
                    <label htmlFor="uuid">Modificar Todos ({uuid})</label>
                  </Form.Field>}
                  <NumberGroup label="Indice" id="index" value={index} onChange={this.props.onChange} feedback="Debe ser un numero" />
                  <div className="form-group">
                    <label htmlFor="title">*Titulo</label>
                    <input id="title" className="form-control" onChange={this.props.onChange} value={title} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="tags">Imagen del producto</label>
                    <select id="asset" className="form-control" value={asset} onChange={this.props.onChange}>
                      <option value="">----------------</option>
                      {imagenes.map(c => (
                        <option key={c._id} value={c.path}>{c.nombre}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="detail">Descripcion</label>
                <input id="detail" className="form-control" onChange={this.props.onChange} value={detail} />
              </div>
              <div className="form-group">
                <label htmlFor="tags">Categoria</label>
                <select id="tags" className="form-control" value={tags} onChange={this.props.onChange}>
                  <option value="">----------------</option>
                  {categorias.map(c => (
                    <option key={c._id} value={c.nombre}>{c.nombre}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="tags">Categoria sub productos</label>
                <select id="subproducts_category" className="form-control" value={subproducts_category} onChange={this.props.onChange}>
                  <option value="">----------------</option>
                  {categorias.map(c => (
                    <option key={c._id} value={c.nombre}>{c.nombre}</option>
                  ))}
                </select>
              </div>
              <NumberGroup label="Precio" id="price" value={price} onChange={this.props.onChange} feedback="Debe ser un numero" />
              <div className="form-group">
                <label htmlFor="price">Extras para seleccionar</label>
                <div className="input-group">
                  <input type="text" className="form-control" value={extraName} onChange={e => this.setState({ extraName: e.target.value })} placeholder="Descripcion" aria-label="Descripcion" aria-describedby="button-addon2" />
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={this.agregarExtra} disabled={isEmpty(extraName)}>Agregar</button>
                  </div>
                </div>
              </div>
              <ul className="list-group">
                {extras.map((e, i) => <Extra key={i} {...e} index={i} select={this.props.selectExtra} remove={this.props.eliminaExtra}/>)}
              </ul>
              <div className="form-group">
                <label htmlFor="price">Detalle del producto</label>
                <div className="input-group">
                  <input type="text" className="form-control" value={nombre} onChange={e => this.setState({ nombre: e.target.value })} placeholder="Nombre" aria-label="Nombre" aria-describedby="button-addon2" />
                  <input type="text" className="form-control" value={descripcion} onChange={e => this.setState({ descripcion: e.target.value })} placeholder="Descripcion" aria-label="Descripcion" aria-describedby="button-addon2" />
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={this.agregarProducto} disabled={isEmpty(nombre)}>Agregar</button>
                  </div>
                </div>
              </div>
              <ul className="list-group">
                {products.map((p, i) => <SubProduct key={i} index={i} {...p} remove={this.props.eliminaProducto} />)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const NumberGroup = ({ id, label, value, onChange, feedback }) => (
  <div className="form-group">
    <label htmlFor="index">{label}</label>
    <input id={id} type="number" className={"form-control" + (isNaN(value) ? ' is-invalid' : '')} onChange={onChange} value={value} />
    <div className="invalid-feedback">{feedback}</div>
  </div>
)

const SubProduct = ({ title, detail, remove, index }) =>  (
  <li className="list-group-item">
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex flex-column">
        {title}
        <small className="font-italic">{detail}</small>
      </div>
      <button className="btn btn-link" onClick={() => remove(index)}><i className="fas fa-times"></i></button>
    </div>
  </li>
)

const Extra = ({ selected, value, select, remove, index }) => (
  <li className="list-group-item">
    <div className="custom-control custom-checkbox">
      <input type="checkbox" className="custom-control-input" id={"customCheck" + index} checked={selected} onChange={() => select(index)} />
      <label className="custom-control-label" htmlFor={"customCheck" + index}>{value}</label>
      <button type="button" className="close" aria-label="Close" onClick={() => remove(index)}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </li>
)

/*
class Extra extends Component {
  state = {
    name: '',
    edit: false
  }

  onChange = (e) => this.setState({ name: e.target.value })

  render() {
    console.log(this.props)
    if (this.state.edit) {
      return (
        <li className="list-group-item">
          <div className="input-group mb-3">
            <input type="text" className="form-control" value={this.state.name} onChange={this.onChange} placeholder="Descripcion" aria-label="Descripcion" aria-describedby="button-addon2" />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary" type="button" id="button-addon2">Guardar</button>
            </div>
          </div>
        </li>
      )
    } else {
      return (
        <li className="list-group-item">
          <div>
            <input type="checkbox" className="form-check-input" checked={this.props.selected}/>
            <button className="btn btn-link" type="button" id="button-addon2">{this.props.name}</button>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </li>
      )
    }
  }
}*/