import React, { Component } from "react";
import { numeric } from '../utils/Numeral'

export default class CustomerList extends Component {
  state = {
    customer: {},
    minimum: 2
  }

  increase = () => this.setState({ minimum: this.state.minimum + 1 })
  decrease = () => this.setState({ minimum: this.state.minimum > 2 ? this.state.minimum - 1 : 2 })

  detail = (customer) => {
    this.setState({ customer })
    this._toggleDetail()
  }

  _toggleDetail = () => window.$('#customerItemDetail').modal('toggle')

  render() {
    if (this.props.customers.length === 0) return null
    return (
      <div>
        <ul className="list-group list-group-flush">
          {this.props.disableTopbar ? null : (
            <li className="list-group-item d-flex justify-content-end align-items-center">
              <strong className="mr-2">{this.state.minimum}</strong>
              <div className="btn-group btn-group-sm" role="group">
                <button type="button" className="btn btn-primary" onClick={this.increase}><i className="fal fa-plus"></i></button>
                <button type="button" className="btn btn-primary" onClick={this.decrease}><i className="fal fa-minus"></i></button>
              </div>
            </li>
          )}
          {this.props.customers.filter(o => o.orders.length >= this.state.minimum).sort(sortOrders).map((c, i) => (
            <li className="list-group-item pr-0 pl-0" key={i} onClick={() => this.detail(c)}>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-1">+56 {c.user.phone} - {c.user.name}</h5>
                <span className="badge badge-primary badge-pill">{c.orders.length}</span>
              </div>
              <p className="mb-1">{c.user.address}</p>
              <p className="text-muted mb-1">Total ${numeric(c.orders.reduce(totalAmount, 0))}</p>
            </li>
          ))}
        </ul>
        <ItemDetail {...this.state.customer} />
      </div>
    )
  }
}

function totalAmount(t, order) {
  return order.products.reduce((t, p) => t += (p.price * p.quantity), order.deliveryCost)
}

function sortOrders(a, b) {
  return b.orders.reduce(totalAmount, 0) - a.orders.reduce(totalAmount, 0)
}

const ItemDetail = ({user, orders}) => (
  <div id="customerItemDetail" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg">
      {!user || !orders ? null : (
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">
            <div>
              <h5>+56 {user.phone} - {user.name}</h5>
              <small>{user.address}</small>
            </div>
          </div>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <ul className="list-group list-group-flush">
            {orders.map((o, i) => (
              <OrderItem key={i} {...o} />
            ))}
          </ul>
        </div>
      </div>
      )}
    </div>
  </div>
)

const OrderItem = ({deliveryCost, products}) => (
  <li className="list-group-item">
    <h5>Total ordenes: ${numeric(products.reduce((t, p) => t += (p.price * p.quantity), deliveryCost))}</h5>
    <small>{products.reduce((s, p) => s + p.title + ', ', '')}</small>
  </li>
)