import React, { Component } from "react";
import { isEmpty } from "../utils";


export default class CategoriasModal extends Component {
  state = {
    nombre: ''
  }

  onChange = (e) => this.setState({ nombre: e.target.value })
  agregar = () => this.props.agregar(this.state.nombre)

  render() {
    let disabled = isEmpty(this.state.nombre)

    return (
      <div id="modalCategorias" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="categorias" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Categorias</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <input type="text" className="form-control" onChange={this.onChange} value={this.state.nombre} placeholder="Nombre de la categoria" aria-label="Nombre de la categoria" aria-describedby="button-addon2" />
                <div className="input-group-append">
                  <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={this.agregar} disabled={disabled}>Agregar</button>
                </div>
              </div>
              <ul className="list-group list-group-flush">
                {this.props.categorias.map(c => (
                  <li key={c._id} className="list-group-item">
                    <div className="row">
                      <label className="col-md-10">{c.nombre}</label>
                      <button type="button" className="close col-md-2 text-right" aria-label="Close" onClick={() => this.props.eliminar(c._id)}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}