import React from "react";
import { connect } from "react-redux";
import { Header, Item, Label } from "semantic-ui-react";
import { categorias_from_state, conceptos_from_state } from "../reducers/ComprasReducer";

import { clp } from '../utils';


function nombreConceptoLegacy(v) {
  if (!v || v === null) {
    return
  }
  return v.nombre_concepto
}

function categoriaConceptoLegacy(v) {
  if (!v || v === null) {
    return
  }
  return categoria(v.tipo_concepto)
}

const GastoItem = ({ concepto, monto, cantidad, descripcion, caja, id_concepto, conceptos, categorias }) => {
  let nombre_concepto = nombreConceptoLegacy(concepto)
  let categoria_concepto = categoriaConceptoLegacy(concepto)
  
  if (!concepto) {
    const v = conceptos.find(v => v.value === id_concepto)
    const c = categorias.find(k => k.value === v.id_categoria)
    nombre_concepto = v.text
    categoria_concepto = c && c.text
  }
  
  return (
    <Item>
      <Item.Content style={{width:'100%'}}>
        <Item.Header>{nombre_concepto}</Item.Header>
        <Item.Meta>{categoria_concepto}</Item.Meta>
        <Item.Description>{descripcion}</Item.Description>
        <Item.Extra>
          <Label.Group>
            {caja && <Label color="green">Caja</Label>}
            <Label>{cantidad}</Label>
            <Label>{clp(monto)}</Label>
          </Label.Group>
        </Item.Extra>
        <Item.Description><Header as="h4">{clp(monto * cantidad)}</Header></Item.Description>
      </Item.Content>
    </Item>
  )
}

const categorias = [{
  label: 'COSTOS DE ADMINISTRACION Y VENTAS',
  keys: ['costo_adm_vts']
}, {
  label: 'GASTOS DE PRODUCCION',
  keys: ['gst_produccion', 'gst_produccion_ind']
}, {
  label: 'REMUNERACIONES',
  keys: ['remuneraciones']
}]


function categoria(v) {
  let c = categorias.find(c => c.keys.some(k => k === v))
  if (!c) {
    return v
  }

  return c.label
}


const mapStateToProps = (state) => ({
  conceptos: conceptos_from_state(state),
  categorias: categorias_from_state(state)
})

export default connect(mapStateToProps)(GastoItem)