import React from 'react'
import { Message } from 'semantic-ui-react'
import { isEmpty } from '../utils'

export default function AlertMessage({ message, close, type = 'danger' }) {
  if (isEmpty(message)) {
    return null
  }

  return (
    <Message negative={type === 'danger'} onDismiss={close}>
      {message}
    </Message>
  )
}