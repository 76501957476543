export const INVENTARIO_ITEM_AGREGAR = 'AGREGAR'
export const INVENTARIO_ITEM_CERRAR = 'CERRAR'

const FACTOR_CANTIDAD = 10000

export function inventario_calcula_cantidad(cantidad) {
  return cantidad / FACTOR_CANTIDAD
}

export function inventario_cantidad_float(cantidad) {
  return Math.abs(parseFloat(cantidad) * FACTOR_CANTIDAD)
}