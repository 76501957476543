import { store } from '../lib/Store'
import { host } from "../config";
import { current_timestamp } from '../utils';


export function get(path) {
  return request(path, 'GET')
}

export function post(path, body) {
  return request(path, 'POST', body)
}

export function put(path, body) {
  return request(path, 'PUT', body)
}

export function del(path, body) {
  return request(path, 'DELETE', body)
}

export function downloadFile(path, nombre) {
  let { token } = store.getState().session
  return fetch(host + path + "?t=" + current_timestamp(), {
    method: 'GET',
    headers: {
      'API-TOKEN': token,
    },
  }).then(r => r.blob()).then(blob => blobDownload(blob, nombre))
}

function blobDownload(blob, nombre) {
  var objUrl = window.URL.createObjectURL(blob);
  //window.location.assign(objUrl);
  let link = document.createElement('a');
  link.href = objUrl;
  link.download = nombre;
  link.click();

  // For Firefox it is necessary to delay revoking the ObjectURL.
  setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
}

function request(path, method, body) {
  let { token } = store.getState().session
  return fetch(host + path, {
    method,
    headers: {
      'API-TOKEN': token
    },
    body: body ? JSON.stringify(body) : undefined
  }).then(r => r.json())/*.then(lag)*/.catch(error)
}

function lag(v) {
  return new Promise(res => {
    setTimeout(() => res(v), 1000)
  })
}

function error(e) {
  console.error('[API]', e)
  if (e.statusText) {
    return Promise.reject({ status: e.status, data: e.statusText })
  }
  if (e.message) {
    return Promise.reject({ status: 500, data: e.message })
  }
  return Promise.reject(e)
}