import numeral from './Numeral'
import moment from './Moment'

const $ = window.$

export function toggleModal(id) {
  $('#' + id).modal('toggle')
}

export const MAX_NUMBER = 999999999999999

export function isEmpty(s) {
  return !s || s === null || (s.trim ? s.trim().length === 0 : false)
}

export function int(s) {
  return parseInt(s, 10)
}

export function str(v) {
  return v && v !== null && v.toString ? v.toString() : ''
}

export function numeric(value) {
  return numeral(value).format('0,0')
}

export function clp(value) {
  return numeral(value).format('$0,0')
}

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

export function isEmail(email) {
  return EMAIL_REG.test(email);
}

export function now() {
  return fecha()
}

export function formatTime(date) {
  return moment(date).format('HH:mm:ss')
}

export function formatDate(date) {
  return moment(date).format('DD/MM/YYYY')
}

export function fecha(v) {
  return moment(v).format('DD/MM/YYYY HH:mm:ss')
}

export const nowWithMonthBefore = (m, mask) => moment().subtract(m, 'months').format(mask)
export const nowDate = (mask) => moment().format(mask)

export function current_timestamp() {
  return new Date().getTime()
}

function anios() {
  const anios = []
  const actual = moment().year()

  for (let i = 2020; i <= actual; i++) {
    anios.push(
      {key: i.toString(), value: i, text: i.toString() }
    )
  }

  return anios
}

export const ANIOS = anios()

export const MESES_AGNIO = [
  { key: '1', value: 1, text: 'Enero' },
  {key: '2', value: 2, text: 'Febrero'},
  {key: '3', value: 3, text: 'Marzo'},
  {key: '4', value: 4, text: 'Abril'},
  {key: '5', value: 5, text: 'Mayo'},
  {key: '6', value: 6, text: 'Junio'},
  {key: '7', value: 7, text: 'Julio'},
  {key: '8', value: 8, text: 'Agosto'},
  {key: '9', value: 9, text: 'Septiembre'},
  {key: '10', value: 10, text: 'Octubre'},
  {key: '11', value: 11, text: 'Noviembre'},
  {key: '12', value: 12, text: 'Diciembre'}
]

export function react_app_inverted() {
  return process.env.REACT_APP_INVERTED === 'true'
}

export function primary_color() {
  return process.env.REACT_APP_PRIMARY_COLOR || 'black'
}