import numeral from 'numeral'

numeral.register('locale', 'es', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'uno' : 'zero';
    },
    currency: {
        symbol: '$'
    }
})

numeral.locale('es')

export function numeric(value) {
  return numeral(value).format('0,0')
}

export default numeral
