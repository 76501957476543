import React, { Component } from "react";
import { connect } from "react-redux";
import { seleccionarSucursal } from "../actions/SessionActions";
import { int } from "../utils";
import { DASHBOARD } from "../utils/Routes";
import { toggleFijarSucursal } from "../lib/custom";


class FijarSucursal extends Component {
  state = {
    sucursal: '-1',
    busy: false
  }

  componentDidMount() {
    this.setState({ sucursal: this.props.sucursal.toString()})
  }

  onChange = (e) => {
    this.setState({ sucursal: e.target.value })
  }

  onClick = () => {
    this.setState({ busy: true })
    this.props.seleccionarSucursal(int(this.state.sucursal)).finally(() => {
      toggleFijarSucursal()
      //this.props.history.push(DASHBOARD)
      window.location.reload()
    })
  }

  render() {
    let { sucursales } = this.props

    return (
      <div id="modalFijarSucursal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="categorias" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cambiar Sucursal</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <select className="form-control" value={this.state.sucursal} onChange={this.onChange}>
                  {sucursales.map(sucursal => (
                    <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>
                  ))}
                </select>
              </div>
              <button className="btn btn-primary btn-block" type="button" onClick={this.onClick}>Seleccionar</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function sortSucursal(a, b) {
  return a.nombre < b.nombre ? -1 : 1
}

const mapStateToProps = ({ session }) => ({
  sucursales: session.sucursales.sort(sortSucursal).map(sucursal => ({
    ...sucursal,
    id: sucursal.id.toString()
  })),
  sucursal: session.sucursal
})

const mapDispatchToProps = (dispatch) => ({
  seleccionarSucursal: (id) => dispatch(seleccionarSucursal(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(FijarSucursal)