export const LOGIN = '/'
export const PRIVATE = '/private'
export const JORNADAS = PRIVATE + '/compraventa/jornadas'
export const JORNADA_DETALLE = PRIVATE + '/compraventa/jornadas/:id'
export const PEDIDOS = PRIVATE + '/compraventa/pedidos'
export const COMPRA_VENTA = PRIVATE + '/compraventa/periodo'
export const RESUMEN_MENSUAL = PRIVATE + '/compraventa/resumen'
export const RESUMEN_DIARIO = PRIVATE + '/compraventa/diario'
export const RESUMEN_CONCEPTOS = PRIVATE + '/compraventa/conceptos/resumen'
export const RESUMEN_ANUAL = PRIVATE + '/compraventa/anual/resumen'
export const PRODUCTS = PRIVATE + '/productos'
export const CUSTOMERS = PRIVATE + '/clientes'
export const SUCURSALES = PRIVATE + '/sucursales'
export const SUCURSALES_ADMIN = PRIVATE + '/admin/sucursales'
export const SUCURSAL_NUEVA = PRIVATE + '/admin/sucursales/modificar'
export const SUCURSAL_MODIFICAR = PRIVATE + '/admin/sucursales/modificar/:id'
export const USUARIOS = PRIVATE + '/usuarios'
export const USUARIO_NUEVO = PRIVATE + '/usuarios/nuevo'
export const USUARIO_MODIFICAR = PRIVATE + '/usuarios/modificar/:email'
export const INVENTARIO = PRIVATE + '/inventario'
export const GESTION_INVENTARIO = PRIVATE + '/inventario/gestion'
export const INVENTARIO_DETALLE = PRIVATE + '/inventario/:uuid/:anterior'
export const INVENTARIO_DETALLE_ITEM = PRIVATE + '/inventario/gestion/item/:id'
export const TRABAJADORES = PRIVATE + '/trabajadores'
export const AGREGAR_TRABAJADOR = PRIVATE + '/trabajadores/agregar'
export const DETALLE_TRABAJADOR = PRIVATE + '/trabajadores/detalle/:email'
export const AJUSTES = PRIVATE + '/ajustes'
export const COMPRAS = PRIVATE + '/compras'
export const NUEVA_COMPRA = PRIVATE + '/compras/nueva'
export const MODIFICAR_COMPRA = PRIVATE + '/compras/modificar/:id'
export const RESUMEN_COMPRA = PRIVATE + '/compras/resumen'
export const ARCHIVOS = PRIVATE + '/archivos'
export const VENTAS_SUCURSALES = PRIVATE + '/ventas/sucursales'

export const DASHBOARD = PRIVATE
