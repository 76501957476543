import React, {Component} from 'react'
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import moment from 'moment'


import { resumenDiario, isSuccess } from '../api'
import { clp } from '../utils';

class ResumenDiario extends Component {
  state = {
    sort: 'nombre',
    invert: 1,
    data: [],
    busy: false
  }

  componentDidMount() {
    this.fetch()
    this._interval = setInterval(this.fetch, 10000)
  }

  componentWillUnmount() {
    clearInterval(this._interval)
  }

  fetch = async () => {
    try {
      this.setState({ busy: true })
      const res = await resumenDiario()
      if (isSuccess(res)) {
        console.log(res.data)
        const { compras, ventas, jornadas, despachos } = res.data
        const data = this.props.sucursales.map(({ id, nombre }) => {
          const c = compras.find(c => c._id === id)
          const v = ventas[id]
          const j = jornadas.find(c => c.id_sucursal === id)
          const d = despachos[id]
          return {
            id,
            nombre,
            compras: c ? c.total : 0,
            ventas: v ? v : 0,
            jornada: j ? j.id_jornada : 0,
            apertura: j && j.apertura,
            despachos: d ? d : 0
          }
        })
        this.setState({ data })
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ busy: false })
    }
  }

  ordenar = (e) => this.setState({ sort: e.target.id, invert: this.state.sort === e.target.id ? this.state.invert * -1 : 1 })

  render() {
    const { data, sort, invert, busy } = this.state

    return (
      <div className="container mt-3">
        <div className="card card-default">
          <div className="card-header">
            <h2>Jornadas Activas {busy && <Loader active inline size="tiny" />}</h2>
          </div>
          <div className="card-body table-responsive">
            <p>El despacho esta incluido en las compras</p>
            <table className="table table-striped">
              <thead>
                <tr>
                  {/*<th id="id" onClick={this.ordenar}>id <i className="fas fa-sort"></i></th>*/}
                  <th id="nombre" onClick={this.ordenar}>sucursal <i className="fas fa-sort"></i></th>
                  <th>Hora Apertura</th>
                  <th id="ventas" onClick={this.ordenar}>ventas <i className="fas fa-sort"></i></th>
                  <th id="compras" onClick={this.ordenar}>compras <i className="fas fa-sort"></i></th>
                  <th>margen</th>
                  <th id="despachos" onClick={this.ordenar}>despachos <i className="fas fa-sort"></i></th>
                  <th id="jornada" onClick={this.ordenar}>jornada <i className="fas fa-sort"></i></th>
                </tr>
              </thead>
              <tbody>
                {data.sort((a, b) => sortData(a, b, sort, invert)).map(({ id, nombre, compras, ventas, jornada, despachos, apertura }) => (
                  <tr key={id}>
                    {/*<td>{id}</td>*/}
                    <td>{nombre}</td>
                    <td>{apertura ? moment(apertura).format('HH:mm') : ''}</td>
                    <td>{clp(ventas + despachos)}</td>
                    <td>{clp(compras + despachos)}</td>
                    <td>{clp(ventas - compras)}</td>
                    <td>{clp(despachos)}</td>
                    <td>{jornada}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

function sortData(a, b, v, invert) {
  return (a[v] > b[v] ? 1 : -1) * invert
}

const mapStateToProps = ({ session }) => ({
  sucursales: session.sucursales.filter(s => s.id > 1),
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ResumenDiario)