import React, {Component} from 'react'

import {  fetchArchivos, isSuccess, urlDescarga } from '../api'
import { Card, Container, Header, Table, Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { permisos_fs } from '../reducers/SessionReducer';
import { int } from '../utils';


class Archivos extends Component {
  state = {
    archivos: [],
    downloading: false
  }

  async componentDidMount() {
    if (!this.props.autorizado) {
      return
    }
    try {
      const res = await fetchArchivos()
      if (isSuccess(res)) {
        this.setState({
          archivos: res.data.map(parseArchivos)
        })
      }
    } catch (e) {
      console.log('fetchArchivos', e)
    }
  }

  descargar = async (archivo) => {
    try {
      this.setState({ downloading: true })
      await urlDescarga(archivo)
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ downloading: false })
    }
  }


  render() {
    const downloading = this.state.downloading
    return (
      <Container>
        <Header>Archivos</Header>
        <p>Estos archivos se actualizan diariamente en la madrugada. Contienen todos los registros del mes de todas
          las sucursales.
        </p>
        <p>El numero del archivo corresponde al numero del mes. Los archivos estan en formato CSV deben ser importados en excel.</p>
        <Table celled >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Mes</Table.HeaderCell>
              <Table.HeaderCell>Año</Table.HeaderCell>
              <Table.HeaderCell>Descargar</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.archivos.sort(ordenarTipo).sort(ordenarMes).sort(ordenarAnio).map(v => (
              <Table.Row>
                <Table.Cell>{v.nombre}</Table.Cell>
                <Table.Cell>{v.tipo}</Table.Cell>
                <Table.Cell>{v.mes}</Table.Cell>
                <Table.Cell>{v.anio}</Table.Cell>
                <Table.Cell>
                  <Button icon="download" color="green" onClick={() => this.descargar(v.archivo)} loading={downloading} disabled={downloading}/>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {/*<Card.Group>
          {this.state.archivos.sort(ordenar).map(v => (
            <Card key={v.archivo} fluid color='red' header={v.archivo} onClick={() => urlDescarga(v.archivo)} />
          ))}
          </Card.Group>*/}
      </Container>
    )
  }
}

function parseArchivos(v) {
  const parts = v.split('.')
  const first = parts[0].split('-')

  return {
    archivo: v,
    nombre: first[0],
    tipo: first[1],
    mes: int(first[2]),
    anio: int(first[3])
  }
}

function ordenarMes(a, b) {
  return b.mes - a.mes
}

function ordenarAnio(a, b) {
  return b.anio - a.anio
}

function ordenarTipo(a, b) {
  return b.tipo < a.tipo ? 1 : -1
}

const mapStateToProps = (state) => ({
  autorizado: permisos_fs(state) > 1024
})

export default connect(mapStateToProps)(Archivos)