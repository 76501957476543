import React, { Component, useState } from 'react'
import { connect } from 'react-redux';
import Calendar from 'react-calendar';

import { Button, Container, Form, Icon, Menu, Segment } from 'semantic-ui-react';
import { fetch_conceptos } from '../../actions/ComprasActions';
import { ingresarCompra, isSuccess } from '../../api';
import { categorias_from_state, compras_from_state, conceptos_from_state } from '../../reducers/ComprasReducer';
import { int, isEmpty } from '../../utils';
import moment from 'moment';


class NuevaCompra extends Component {
  state = {
    id_categoria: 1,
    id_concepto: '',
    numero_factura: '',
    fecha_pago: null,
    monto: '',
    proveedor: '',
    comentario: '',
    loading: true,
    busy: false
  }

  async componentDidMount() {
    await this.props.fetch_conceptos()
    const id = this.props.match.params.id
    if (id) {
      const compra = this.props.compras.find(v => v.id === int(id))
      const concepto = this.props.conceptos.find(v => v.value === compra.id_concepto)
      console.log(id, compra)
      this.setState({
        id_categoria: concepto.id_categoria,
        id_concepto: compra.id_concepto,
        numero_factura: compra.numero_factura !== null ? compra.numero_factura : '',
        fecha_pago: compra.fecha_pago !== null ? new Date(compra.fecha_pago) : null,
        monto: compra.monto,
        proveedor: compra.proveedor,
        comentario: compra.descripcion
      })
    }
    this.setState({ loading: false })
  }

  submit = async () => {
    const id = this.props.match.params.id
    const fecha_pago = this.state.fecha_pago !== null ? this.state.fecha_pago.toISOString().split('.')[0] : null
    const numero_factura = !isEmpty(this.state.numero_factura) ? int(this.state.numero_factura) : null

    const data = {
      id: id ? int(id) : undefined,
      id_categoria: this.state.id_categoria,
      id_concepto: this.state.id_concepto,
      numero_factura,
      fecha_pago,
      monto: int(this.state.monto),
      proveedor: this.state.proveedor,
      descripcion: this.state.comentario
    }

    try {
      console.log(data)
      this.setState({ busy: true })
      const res = await ingresarCompra(data)
      if (isSuccess(res)) {
        this.volver()
      } else {
        console.log(res)
      }
    } catch (e) {
      console.error('nuevacompra.submit', e)
    } finally {
      this.setState({ busy: false })
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }
  onCategoriaChange = (e, { value }) => this.setState({ id_categoria: value, id_concepto: '' })
  onConceptoChange = (e, { value }) => this.setState({ id_concepto: value })


  volver = () => this.props.history.goBack()

  render() {
    const {id_categoria, id_concepto, numero_factura, fecha_pago, monto, proveedor, comentario, loading, busy  } = this.state
    const { categorias, conceptos } = this.props
    const options = conceptos.filter(v => v.id_categoria === id_categoria)

    const disabled = isEmpty(id_concepto) || isEmpty(monto) || busy || loading

    return (
      <Container>
        <Menu>
          <Menu.Item>
            <Button onClick={this.volver} icon labelPosition="left">
              <Icon name="angle left" />
              Volver
            </Button>
          </Menu.Item>
          <Menu.Item position="right">
            <Button color="green" icon labelPosition="left" onClick={this.submit} disabled={disabled} loading={busy}>
              <Icon name="save" />
              Guardar
            </Button>
          </Menu.Item>
        </Menu>
        <Segment loading={loading}>
          <Form>
            <Form.Select fluid label='Categoria' required options={categorias} value={id_categoria} onChange={this.onCategoriaChange}/>
            <Form.Select fluid label='Concepto' required options={options} value={id_concepto} onChange={this.onConceptoChange} placeholder='Selecciona el concepto' />
          </Form>
        </Segment>
        <Segment>
          <Form>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>Numero de Factura</label>
                <input type="number" id="numero_factura" value={numero_factura} onChange={this.onChange} />
              </Form.Field>
              <Form.Field>
                <label>Fecha de Pago</label>
                <InputFecha value={fecha_pago} onChange={v => this.setState({ fecha_pago: v })} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field required>
                <label>Monto</label>
                <input type="number" id="monto" value={monto} onChange={this.onChange} />
              </Form.Field>
              <Form.Field>
                <label>Proveedor</label>
                <input id="proveedor" value={proveedor} onChange={this.onChange}/>
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Comentario</label>
              <textarea id="comentario" value={comentario} onChange={this.onChange} />
            </Form.Field>
          </Form>
        </Segment>
      </Container>
    )
  }
}

const InputFecha = ({ value, onChange }) => {
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const onDateChange = (v) => {
    console.log('valu', v)
    onChange(v)
    toggle()
  }

  return (
    <div style={{ position: 'relative' }}>
      <Button fluid basic onClick={toggle} icon labelPosition="left">
        {value === null ? 'Presiona para seleccionar la fecha' : moment(value).format('DD/MM/YYYY')}
        <Icon name="calendar" />
      </Button>
      <div style={{ display: visible ? 'block' : 'none', position: 'absolute', margin: 0, top: 40 }}>
        <Calendar value={value} onChange={onDateChange} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  compras: compras_from_state(state),
  conceptos: conceptos_from_state(state),
  categorias: categorias_from_state(state)
})

export default connect(mapStateToProps, { fetch_conceptos })(NuevaCompra)