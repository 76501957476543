import React from 'react'
import { Link } from 'react-router-dom';
import { Button, Confirm, Container, Header, Icon, Image, Item, Label, List, Menu, Segment } from 'semantic-ui-react';

import Screen from '../Screen'
import Loading, { Spinning } from '../../components/Loading'
import { fetchEmpleados, isSuccess, eliminarEmpleado } from '../../api'
import { AlertMessage } from '../../components';
import ButtonClose from '../../components/ButtonClose';
import { AGREGAR_TRABAJADOR, DETALLE_TRABAJADOR } from '../../utils/Routes';
import AlertModal from '../../components/AlertModal';
import { toggleModal } from '../../utils';

const idModal = "eliminarModal"

function toggleAlert() {
  toggleModal(idModal)
}

export default class Trabajadores extends Screen {
  state = {
    empleados: [],
    loading: true,
    confirm: false
  }

  componentDidMount() {
    this.refresh().finally(this.toggleLoading)
  }

  refresh = () => fetchEmpleados().then(res => {
    if (isSuccess(res)) {
      this.setState({ empleados: res.data })
    }
  })

  eliminar = () => {
    //this.toggleConfirm()
    toggleAlert()
    eliminarEmpleado(this._email).finally(this.refresh)
  }

  toggleEliminar = (email) => {    
    this._email = email
    //this.toggleConfirm()
    toggleAlert()
  }

  //toggleConfirm = () => this.setState({ confirm: !this.state.confirm })
  modificar = (email) => this.props.history.push(DETALLE_TRABAJADOR.replace(':email', email))

  render() {
    const { empleados, loading, confirm } = this.state
  
    return (
      <Container>
        <Menu>
          <Menu.Item>Trabajadores</Menu.Item>
          <Menu.Item position="right">
            <Button icon color="green" labelPosition="left" onClick={() => this.props.history.push(AGREGAR_TRABAJADOR)}>
              <Icon name="plus" />
              Agregar
            </Button>
          </Menu.Item>
        </Menu>
        <Segment loading={loading} padded>
          <List divided>
            {empleados.map(empleado => <Empleado key={empleado.email} {...empleado} eliminar={this.toggleEliminar} modificar={this.modificar} />)}
          </List>
        </Segment>
        {/*<Confirm open={confirm} onCancel={this.toggleConfirm} onConfirm={this.eliminar} header="Eliminar trabajador"
          content="Vas a eliminar al empleado, confinuar?" />*/}
        <AlertModal id={idModal} title="Quitar Trabajador" message="Seguro deseas quitar al empleado?" label="Continuar" onClick={this.eliminar} />
      </Container>
    )
  }
}

const Empleado = ({ email, nombre, eliminar, modificar }) => (
  <List.Item>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <div>
        <Header>{nombre}</Header>
        <span>{email}</span>
        <p>Repartidor</p>
      </div>
      <Button.Group>
        <Button onClick={() => modificar(email)} icon="edit"></Button>
        <Button icon="close" onClick={() => eliminar(email)} />
      </Button.Group>
    </div>
  </List.Item>
)

/*  < li className = "list-group-item" >
    <div className="d-flex justify-content-between">
      <Link to={DETALLE_TRABAJADOR.replace(':email', email)}><h4>{nombre}</h4></Link>
      <ButtonClose onClick={() => eliminar(email)} />
    </div>
    <small>{email}</small>
  </li >*/