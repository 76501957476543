import { FIJAR_COMPRAS, FIJAR_CONCEPTOS } from '../actions/Types'
import { current_timestamp } from '../utils'
import createReducer from './createReducer'

const initialState = {
  data: []
}

export const compras = createReducer(initialState, {
  [FIJAR_COMPRAS]: (state, { compras }) => ({ ...state, data: compras })
})

const initialConceptosState = {
  data: [],
  categorias: [],
  timestamp: 0
}

export const conceptos = createReducer(initialConceptosState, {
  [FIJAR_CONCEPTOS]: (state, action) => ({
    timestamp: current_timestamp(),
    categorias: action.categorias,
    data: action.conceptos
  })
})

export function compras_from_state(state) {
  return state.compras.data
}

export function conceptos_from_state(state) {
  return state.conceptos.data.map(v => ({
    key: v.id,
    text: v.nombre,
    value: v.id,
    id_categoria: v.id_categoria
  }))
}

export function categorias_from_state(state) {
  return state.conceptos.categorias.map(v => ({
    key: v.id,
    text: v.nombre,
    value: v.id
  }))
}

const REFRESH_TIME = 1000 * 60 * 60 * 12

export function refrescar_conceptos_fs(state) {
  return (current_timestamp() - state.conceptos.timestamp) > REFRESH_TIME
}