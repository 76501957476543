import React from 'react'
import Screen from '../Screen';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty, int } from '../../utils';
import { nuevaSucursal, modificarSucursal } from '../../api';
import { ArrowLeft } from '../../components/Icons';
import { Checkbox } from '../../components';
import { obtenerSucursales } from "../../actions/SucursalesActions";


class NuevaSucursal extends Screen {
  state = {
    id: '',
    nombre: '',
    telefono: '',
    direccion: '',
    comunas: [],
    vertigs_id: '62',
    loading: false,
    message: '',
    latitude: '',
    longitude: '',
    tipo: 'KAMI'
  }

  componentDidMount() {
    const sucursal = this.props.sucursales.find(v => v.id === int(this.props.match.params.id))
    if (sucursal) {
      const coordenadas = sucursal.coordenadas
      this.setState({
        id: sucursal.id.toString(),
        nombre: sucursal.nombre,
        telefono: sucursal.telefonos.join(','),
        direccion: sucursal.direccion,
        comunas: this.props.comunas.map(v => ({ ...v, checked: sucursal.comunas.some(k => v.id === k)})),
        //vertigs_id: sucursal.vertigs_id,
        latitude: coordenadas ? coordenadas.latitude : '',
        longitude: coordenadas ? coordenadas.longitude : '',
        tipo: sucursal.tipo
      })
    } else {
      this.setState({ comunas: this.props.comunas })
    }
  }

  submit = (e) => {
    e.preventDefault()
    const { id, nombre, telefono, direccion, comunas, vertigs_id, latitude, longitude, tipo } = this.state

    const sucursal = {
      id: int(id),
      nombre: nombre.trim(),
      telefonos: telefono.trim().split(','),
      direccion: direccion.trim(),
      comunas: comunas.filter(c => c.checked).map(c => c.id),
      vertigs_id: isNaN(vertigs_id) ? null : int(vertigs_id),
      coordenadas: {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude)
      },
      tipo
    }

    console.log(sucursal)
    this.toggleLoading()
    const _fnc = isEmpty(this.props.match.params.id) ? nuevaSucursal : modificarSucursal
    _fnc(sucursal).then(console.log).then(this.props.obtenerSucursales).then(this.goBack).catch(this.onError)
  }

  check = (id) => {
    this.setState({ comunas: this.state.comunas.map(v => ({
      ...v,
      checked: v.id === id ? !v.checked : v.checked
    }))})
  }

  render() {
    const { id, nombre, telefono, direccion, vertigs_id, comunas, loading,
      latitude, longitude, tipo } = this.state
    const modificar = !isEmpty(this.props.match.params.id)
    const disabled = isEmpty(id) || isEmpty(nombre) || loading

    return (
      <div className="container col-8">
        <div className="card">
          <div className="card-body">
            <form onSubmit={this.submit}>
              <div className="d-flex justify-content-between mb-2">
                <button className="btn btn-light" type="button" onClick={this.goBack}><ArrowLeft dark /></button>
                <button className="btn btn-primary" disabled={disabled}>{modificar ? 'Modificar' : 'Nueva'}</button>
              </div>
              <div className="form-group">
                <label htmlFor="id">Id</label>
                <input className="form-control" type="number" id="id" value={id} onChange={this.onChange} disabled={modificar}/>
                <small id="idHelp" className="form-text text-muted">El id debe ser un numero.</small>
              </div>
              <div className="form-group">
                <label htmlFor="nombre">Nombre</label>
                <input className="form-control" id="nombre" value={nombre} onChange={this.onChange} />
              </div>
              <div className="form-group">
                <label htmlFor="telefono">Telefonos</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="telefono"><i className="fas fa-phone"></i></span>
                  </div>
                  <input className="form-control" id="telefono" value={telefono} onChange={this.onChange} />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="direccion">Direccion</label>
                <input className="form-control" id="direccion" value={direccion} onChange={this.onChange} />
              </div>
              <div className="form-group">
                <label htmlFor="direccion">Coordenadas</label>
                <div className="input-group">
                  <input className="form-control col-12 col-md-6" placeholder="Latitude" id="latitude" value={latitude} onChange={this.onChange} />
                  <input className="form-control col-12 col-md-6" id="longitude" placeholder="Longitude" value={longitude} onChange={this.onChange} />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="vertigs_id">Tipo</label>
                <select className="form-control" id="tipo" value={tipo} onChange={this.onChange}>
                  <option value="KAMI">Kami</option>
                  <option value="OTRO">Otro</option>
                </select>
              </div>
              <div className="form-group">
                <label>Comunas</label>
                <table className="table">
                  <thead>
                    <tr>
                      <th><Checkbox /></th>
                      <th>id</th>
                      <th>nombre</th>
                    </tr>
                  </thead>
                  <tbody>
                    {comunas.map(c => <ComunaRow key={c.id} { ...c} onClick={this.check} />)}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const ComunaRow = ({ id, nombre, checked, onClick }) => (
  <tr onClick={() => onClick(id)}>
    <td><Checkbox checked={checked} /></td>
    <td>{id}</td>
    <td>{nombre}</td>
  </tr>
)

const mapStateToProps = ({ sucursales, comunas }) => ({
  sucursales: sucursales,
  comunas: comunas.sort((a, b) => a.id - b.id)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ obtenerSucursales }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NuevaSucursal)