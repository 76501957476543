import { fetchCategoriasConceptos, fetchCompras, fetchConceptos, isSuccess } from "../api"
import { refrescar_conceptos_fs } from "../reducers/ComprasReducer"
import { fijarError } from "./StatusActions"
import { FIJAR_COMPRAS, FIJAR_CONCEPTOS, FIJAR_INVENTARIO_ITEMS } from "./Types"

export function fetch_compras(fecha_desde, fecha_hasta) {
  return async (dispatch) => {
    try {
      const res = await fetchCompras(fecha_desde, fecha_hasta)

      if (_has_error(res, dispatch)) {
        return
      }
      dispatch(fijar_compras(res.data))
    } catch (e) {
      console.error('fetch_inventario_items', e)
      dispatch(fijarError(e.message))
      dispatch(fijar_compras([]))
    }
  }
}

export function fetch_conceptos() {
  return async (dispatch, getState) => {
    try {
      if (!refrescar_conceptos_fs(getState())) {
        return
      }
      const res = await fetchConceptos()
      const res2 = await fetchCategoriasConceptos()

      if (_has_error(res, dispatch) || _has_error(res2, dispatch)) {
        return
      }
      dispatch({
        type: FIJAR_CONCEPTOS,
        conceptos: res.data,
        categorias: res2.data
      })
    } catch (e) {
      console.log('fetch_conceptos', e)
    }
  }
}

function _has_error(res, dispatch) {
  if (!isSuccess(res)) {
    dispatch(fijarError(res.data))
    fijar_compras([])
    return true
  }
  return false
}

function fijar_compras(compras) {
  return {
    type: FIJAR_COMPRAS,
    compras
  }
}