import React, {Component} from 'react'
import { connect } from 'react-redux';
import moment from 'moment';

import { Container, Form, Icon, Menu, Segment, Select, Statistic, Table } from 'semantic-ui-react';
import { ANIOS, clp, MESES_AGNIO } from '../../utils';
import { isSuccess, resumenConceptos, ventasPorMes } from '../../api';
import { fetch_conceptos } from '../../actions/ComprasActions';
import { categorias_from_state, conceptos_from_state } from '../../reducers/ComprasReducer';


class ResultadoMensual extends Component {
  state = {
    mes: moment().month() + 1,
    anio: moment().year(),
    totales_conceptos: [],
    totales_ventas: [],
    rango: [],
    loading: false,
  }

  async componentDidMount() {
    await this.props.fetch_conceptos()
    await this.fetch()
  }

  onMesChange = (value) => {
    this.setState({ mes: value })
    this.fetch(value, this.state.anio)
  }

  onAnioChange = (value) => {
    this.setState({ anio: value })
    this.fetch(this.state.mes, value)
  }

  onRatiosChange = (value) => {
    this.setState({ id_grupo: value })
  }

  fetch = async (mes, anio) => {
    mes = mes ? mes : this.state.mes
    anio = anio ? anio : this.state.anio

    const month = mes < 10 ? '0' + mes : mes
    const fecha = `${anio}-${month}`
    const pivote = moment(fecha, "YYYY-MM")

    const dias = pivote.daysInMonth()
    const desde = pivote.clone().subtract(1, 'month')
    const fecha_desde = `${desde.format('YYYY-MM')}-01`
    const fecha_hasta = `${fecha}-${dias}`

    this.setState({
      rango: [
        { key: 1, label: desde.format('MMMM YYYY'), mes: desde.format('MM') },
        { key: 2, label: pivote.format('MMMM YYYY'), mes: pivote.format('MM') },
      ],
      loading: true
    })

    try {
      const res = await resumenConceptos(fecha_desde, fecha_hasta)
      if (isSuccess(res)) {
        this.setState({
          totales_conceptos: res.data
        })
      }

      const res2 = await ventasPorMes(fecha_desde, fecha_hasta)
      if (isSuccess(res2)) {
        this.setState({ totales_ventas: res2.data })
      }
    } catch (e) {
      console.error('ResumenCompraVenta', e)
    } finally {
      this.setState({ loading: false })
    }
  }
  
  render() {
    const { loading, anio, mes, totales_conceptos, rango, totales_ventas } = this.state
    const { categorias, conceptos } = this.props
    return (
      <Container>
        <Menu>
          <Menu.Item>
            <Form.Field control={Select} options={MESES_AGNIO} value={mes} onChange={(e, { value }) => this.onMesChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
          <Menu.Item>
            <Form.Field control={Select} options={ANIOS} value={anio} onChange={(e, { value }) => this.onAnioChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
        </Menu>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="4" />
              {rango.map(v => (
                <Table.HeaderCell key={v.key} width="6">{v.label}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row >
              <Table.Cell>Total Ventas</Table.Cell>
              {rango.map(v => <TotalVentas {...v} totales_ventas={totales_ventas} />)}
            </Table.Row>
            {categorias.map(v => <Categoria {...v} totales_conceptos={totales_conceptos} conceptos={conceptos} rango={rango} ventas={totales_ventas} />)}
          </Table.Body>
        </Table>
      </Container>
    )
  }
}

const TotalVentas = ({ mes, totales_ventas }) => {
  const ventas = totales_ventas.find(v => v.mes === mes)
  return (
    <Table.HeaderCell width="6">{clp(ventas ? ventas.total_ventas : 0)}</Table.HeaderCell>
  )
}

/**
 * TODO procesar la lista antes de mostrarla
 */
const Categoria = ({ text, value, totales_conceptos, conceptos, rango, ventas }) => {
  const conceptos_categoria = conceptos.filter(v => v.id_categoria === value)

  const totales_categoria = rango.map(v => {
    const total = totales_conceptos.filter(k => k.mes === v.mes && k.id_categoria === value).reduce((t, v) => t + v.total, 0)
    return { mes: v.mes, total }
  })
  
  const data = conceptos_categoria.map((v, i) => (
    <Table.Row key={i}>
      <Table.Cell>{v.text}</Table.Cell>
      <CeldasMensuales concepto={v.value} rango={rango} totales={totales_conceptos} />
    </Table.Row>
  ))

  return [
    <Table.Row active key="a">
      <Table.Cell>% {text}</Table.Cell>
      {totales_categoria.map(v => {
        const _ventas = ventas.find(k => k.mes === v.mes)
        const _total_ventas = _ventas ? _ventas.total_ventas : 0
        let _value = ((Math.floor(v.total / _total_ventas) * 1000) / 1000)
        
        if (isNaN(_value) || !isFinite(_value)) {
          _value = 0
        }

        return (
          <Table.Cell key={v.mes}>{_value}%</Table.Cell>
        )
      })}
    </Table.Row>,
    <Table.Row active key="b">
      <Table.Cell>{text}</Table.Cell>
      {totales_categoria.map(v => (
        <Table.Cell key={v.mes}>{clp(v.total)}</Table.Cell>
      ))}
    </Table.Row>,
    <Table.Row active key="c">
      <Table.Cell>% Margen {text}</Table.Cell>
      {totales_categoria.map(v => {
        const _ventas = ventas.find(k => k.mes === v.mes)
        const _total_ventas = _ventas ? _ventas.total_ventas : 0
        let _value = (Math.floor(((_total_ventas - v.total) / _total_ventas) * 1000) / 1000)

        if (isNaN(_value) || !isFinite(_value)) {
          _value = 0
        }

        return (
          <Table.Cell key={v.mes}>{_value === 1 ? 100 : _value}%</Table.Cell>
        )
      })}
    </Table.Row>,
    <Table.Row active key="d">
      <Table.Cell>Margen {text}</Table.Cell>
      {totales_categoria.map(v => {
        const _ventas = ventas.find(k => k.mes === v.mes)
        const _total_ventas = _ventas ? _ventas.total_ventas : 0
        const _value = _total_ventas - v.total
        return (
          <Table.Cell key={v.mes}>{clp(_value)}</Table.Cell>
        )
      })}
    </Table.Row>,
    ...data]
}

const CeldasMensuales = ({ concepto, rango, totales }) => {
  return rango.map(v => {
    const value = totales.find(k => k.mes === v.mes && k.id_concepto === concepto)
    return <Table.Cell key={v.mes}>{clp(value ? value.total : 0)}</Table.Cell>
  })
}


const mapStateToProps = (state) => ({
  categorias: categorias_from_state(state),
  conceptos: conceptos_from_state(state)
})

export default connect(mapStateToProps, { fetch_conceptos })(ResultadoMensual)