import React, { Component } from 'react'

export default class Screen extends Component {
  toggleLoading = () => this.setState({ loading: !this.state.loading })
  onChange = ({ target }) => this.setState({ [target.id]: target.value })
  goBack = () => this.props.history.goBack()
  navigate = (to) => this.props.history.push(to)

  onError = (e) => {
    console.error(e)
    this.setState({ loading: false })
  }
}