import React, {Component} from 'react'

import Loading from '../../components/Loading'
import { fetchEventosInventario, fetchItemsInventario } from '../../api'
import { clp } from "../../utils";
import { inventario_xlsx } from '../../lib/InventarioExport';

const FACTOR_CANTIDAD = 10000

export function calculaCantidad(cantidad) {
  return cantidad / FACTOR_CANTIDAD
}

export default class InventarioDetalle extends Component {
  state = {
    loading: true,
    eventos: [],
    items: []
  }

  componentDidMount() {
    const { uuid, anterior } = this.props.match.params
    let ids = [uuid]

    if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(anterior)) {
      ids.push(anterior)
    }

    fetchItemsInventario().then(res => {
      console.log('items', res)
      if (res.status === 'success') {
        this.setState({ items: res.data })
      }
    }).catch(console.error)
    fetchEventosInventario(ids).then(res => {
      console.log(res)
      if (res.status === 'success') {
        this.setState({ eventos: res.data })
      }
    }).finally(() => this.setState({ loading: false }))
  }

  exportar = () => {
    const { uuid, anterior } = this.props.match.params
    inventario_xlsx(this.state.eventos, this.state.items, uuid, anterior)
  }

  render() {
    const { eventos, items, loading } = this.state
    const { uuid, anterior } = this.props.match.params

    if (loading) return <Loading />

    const actuales = eventos.filter(e => e.uuid_inventario === uuid)
    const anteriores = eventos.filter(e => e.uuid_inventario === anterior)
    const itemsEventos = eventos.map(e => ({id: e.id_item, precio: e.precio })).reduce((t, v) => [ ...t.filter(e => e.id !== v.id), v], [])

    const base = anteriores.filter(e => e.tipo === 'CERRAR').reduce(reduceTotal, 0)
    const movimiento = actuales.filter(e => e.tipo === 'AGREGAR').reduce(reduceTotal, 0)
    const final = actuales.filter(e => e.tipo === 'CERRAR').reduce(reduceTotal, 0)
    const consumo = base + movimiento - final
    const comprado = Math.abs(base - movimiento)

    return (
      <div className="container-fluid">
        <div className="card mb-2 mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-6 col-md-2">
                <h5>{clp(consumo)}</h5>
                <small>Total Consumo</small>
              </div>
              <div className="col-6 col-md-2">
                <h5>{clp(consumo * 1.19)}</h5>
                <small>Total Consumo IVA</small>
              </div>
              <div className="col-6 col-md-2">
                <h5>{clp(comprado)}</h5>
                <small>Total Comprado</small>
              </div>
              <div className="col-6 col-md-2">
                <h5>{clp(comprado * 1.19)}</h5>
                <small>Total Comprado IVA</small>
              </div>
              <div className="col-6 col-md-2">
                <h5>{clp(final)}</h5>
                <small>Total Final</small>
              </div>
              <div className="col-6 col-md-2">
                <h5>{clp(final * 1.19)}</h5>
                <small>Total Final IVA</small>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-2">
          <button className="btn btn-success" type="button" onClick={this.exportar}>
            <i className="fas fa-download"></i>
          </button>
        </div>
        <div className="card">
          <div className="card-body table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Producto</th>
                  <th className="text-center">Inicial</th>
                  <th className="text-center">Movimiento</th>
                  <th className="text-center">Final</th>
                  <th className="text-center">Consumo</th>
                  <th className="text-center">Comprado</th>
                  <th className="text-center">Precio Neto</th>
                  <th className="text-center">Total Consumo</th>
                  <th className="text-center">Total Comprado</th>
                  <th className="text-center">Total Final</th>
                </tr>
              </thead>
              <tbody>
                {itemsEventos.map(item => (
                  <Evento key={item.id} {...item} actuales={actuales} anteriores={anteriores} items={items} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

function Evento({ id, precio, actuales, anteriores, items }) {
  const eventosIniciales = anteriores.filter(e => e.id_item === id)
  const eventosActuales = actuales.filter(e => e.id_item === id)

  const base = eventosIniciales.filter(e => e.tipo === 'CERRAR').reduce(reduceCantidad, 0)
  const movimiento = eventosActuales.filter(e => e.tipo === 'AGREGAR').reduce(reduceCantidad, 0)
  const final = eventosActuales.filter(e => e.tipo === 'CERRAR').reduce(reduceCantidad, 0)
  const consumo = base + movimiento - final
  const comprado = Math.abs(base - movimiento)

  const item = items.find(i => i.id === id)

  return (
    <tr>
      <td>
        <div>{item && item.nombre}</div>
        <small>{item && item.unidad}</small>
      </td>
      <td className="text-center">{calculaCantidad(base)}</td>
      <td className="text-center">{calculaCantidad(movimiento)}</td>
      <td className="text-center">{calculaCantidad(final)}</td>
      <td className="text-center">{calculaCantidad(consumo)}</td>
      <td className="text-center">{calculaCantidad(comprado)}</td>
      <td className="text-center">{clp(precio)}</td>
      <td className="text-center">{clp(calculaCantidad(consumo) * precio)}</td>
      <td className="text-center">{clp(calculaCantidad(comprado) * precio)}</td>
      <td className="text-center">{clp(calculaCantidad(final) * precio)}</td>
    </tr>
  )
}

function reduceCantidad(t, v) {
  return t + v.cantidad
}

function reduceTotal(t, v) {
  return t + (calculaCantidad(v.cantidad) * v.precio)
}