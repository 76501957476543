export const ADMIN = 2048
export const SUPERVISOR = 1500
export const OPERADOR = 1024
export const EMPLEADO = 64
export const ESPECTADOR = 32
export const CLIENTE = 1

export const ROL_ADMIN = 'ADMIN'
export const ROL_SUPERVISOR = 'SUPERVISOR'
export const ROL_OPERADOR = 'OPERADOR'
export const ROL_EMPLEADO = 'EMPLEADO'
export const ROL_ESPECTADOR = 'ESPECTADOR'
export const ROL_CLIENTE = 'CLIENTE'

const permisos = [{
  label: 'Administrador',
  value: ADMIN,
  rol: ROL_ADMIN
}, {
  label: 'Supervisor',
  value: SUPERVISOR,
  rol: ROL_SUPERVISOR
}, {
  label: 'Operador',
  value: OPERADOR,
  rol: ROL_OPERADOR
}, {
  label: 'Empleado',
  value: EMPLEADO,
  rol: ROL_EMPLEADO
}, {
  label: 'Espectador',
  value: ESPECTADOR,
  rol: ROL_ESPECTADOR
}, {
  label: 'Cliente',
  value: CLIENTE,
  rol: ROL_CLIENTE
}]

export default permisos

export function permisosLabel(id) {
  const v = permisos.find(v => v.value === id)
  return v ? v.label : id
}

export function rol(id) {
  const v = permisos.find(v => v.value === id)
  return v ? v.rol : id
}