export const EFECTIVO = 0
export const TRANSBANK = 1
export const TRANSFERENCIA = 2
export const SODEXO = 3
export const APLICACIONES = 4
export const PAGO_ONLINE = 5
export const AMIPASS = 6

const mediosDePago = [
  {
    id: EFECTIVO,
    label: "Efectivo"
  },
  {
    id: TRANSBANK,
    label: "Tarjeta"
  },
  {
    id: TRANSFERENCIA,
    label: "Transferencia"
  },
  {
    id: SODEXO,
    label: "Sodexo"
  },
  {
    id: APLICACIONES,
    label: "Aplicaciones"
  },
  {
    id: PAGO_ONLINE,
    label: "Pago Online"
  },
  {
    id: AMIPASS,
    label: "Amipass"
  }
]

export function tipoPagoLabel(id) {
  const medio = mediosDePago.find(v => v.id === id)
  return medio ? medio.label : ''
}

