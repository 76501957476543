import createReducer from './createReducer'
import { FIJAR_ERROR, LIMPIAR_ERROR } from '../actions/Types'

const initialState = {
  error: ''
}

export const status = createReducer(initialState, {
  [FIJAR_ERROR]: (state, action) => ({
    ...state,
    error: action.error
  }),
  [LIMPIAR_ERROR]: () => ({ ...initialState })
})