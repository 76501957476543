import React, {Component} from 'react'
import {ordersByDate} from '../api'
import { nowDate, nowWithMonthBefore } from "../utils";
import CustomerList from "../components/CustomerList";

export default class Customers extends Component {
  state = {
    start_date: nowWithMonthBefore(1, 'YYYY-MM-DD'),
    end_date: nowDate('YYYY-MM-DD'),
    customers: [],
    loading: false
  }

  submit = (e) => {
    e.preventDefault()
    if (this.state.start_date.trim().length === 0 || this.state.end_date.trim().length === 0) {
      return
    }
    let start_date = this.state.start_date + 'T00:00:00'
    let end_date = this.state.end_date + 'T00:00:00'

    this.setState({loading: true})
    ordersByDate(start_date, end_date).then(rows => {
      this.setState({loading: false, customers: rows.sort((a, b) => b.orders.length - a.orders.length)})
    }).catch(this.onError)
  }

  onChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }

  onError = (e) => {
    this.setState({loading: false})
    console.error(e)
  }

  render() {
    return (
      <div className="container">
        <div className="row mb-3 border-bottom">
          <div className="col-xs-12">
            <h2>Clientes</h2>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <form className="form-row" onSubmit={this.submit}>
              <div className="form-group col-md-6">
                <label>Fecha de inicio</label>
                <input id="start_date" type="date" className="form-control" value={this.state.start_date} onChange={this.onChange} disabled={this.state.loading}/>
              </div>
              <div className="form-group col-md-6">
                <label>Fecha de termino</label>
                <input id="end_date" type="date" className="form-control" value={this.state.end_date} onChange={this.onChange} disabled={this.state.loading}/>
              </div>
              <button className="btn btn-primary" disabled={this.state.loading}>Buscar</button>
            </form>
          </div>
        </div>
        <CustomerList customers={this.state.customers} />
      </div>
    )
  }
}
