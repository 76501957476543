import moment from 'moment';
import React, {Component} from 'react'
import { connect } from 'react-redux';

import { Button, Confirm, Container, Dimmer, Form, Icon, Item, Label, Loader, Menu, Segment, Select, Table } from 'semantic-ui-react';
import { fetch_compras } from '../../actions/ComprasActions';
import { compras_from_state, conceptos_from_state } from '../../reducers/ComprasReducer';
import { MODIFICAR_COMPRA, NUEVA_COMPRA } from '../../utils/Routes';
import { MESES_AGNIO, ANIOS, toggleModal, clp } from "../../utils";
import { eliminarCompra, isSuccess } from '../../api';
import AlertModal from "../../components/AlertModal";


const ID_MODAL = 'confirm_eliminar'

function toggleConfirm() {
  toggleModal(ID_MODAL)
}

class Compras extends Component {
  state = {
    mes: moment().month() + 1,
    anio: moment().year(),
    loading: true,
  }

  async componentDidMount() {
    await this.fetch()
  }

  fetch = async (mes, anio) => {
    mes = mes ? mes : this.state.mes
    anio = anio ? anio : this.state.anio
    const fecha = `${anio}-${mes < 10 ? '0' + mes : mes}`
    const dias = moment(fecha, "YYYY-MM").daysInMonth()
    const fecha_desde = `${fecha}-01`
    const fecha_hasta = `${fecha}-${dias}`

    this.setState({ loading: true })
    await this.props.fetch_compras(fecha_desde, fecha_hasta)
    this.setState({ loading: false })
  }

  onMesChange = (value) => {
    this.setState({ mes: value })
    this.fetch(value, this.state.anio)
  }

  onAnioChange = (value) => {
    this.setState({ anio: value })
    this.fetch(this.state.mes, value)
  }

  nuevaCompra = () => this.props.history.push(NUEVA_COMPRA)

  modificar = (id) => {
    this.props.history.push(MODIFICAR_COMPRA.replace(':id', id))
  }

  eliminar = async () => {
    try {
      this.setState({ loading: true })
      toggleConfirm()
      await eliminarCompra(this._id)
      await this.fetch()
    } catch (e) {
      console.log('[eliminar]', e)
    } finally {
      this.setState({ loading: false })
    }
  }

  toggleEliminar = (id) => {
    this._id = id
    toggleConfirm()
  }

  render() {
    const { loading, anio, mes } = this.state
    const { compras, conceptos } = this.props

    return (
      <Container>
        <Menu>
          <Menu.Item>
            <Form.Field control={Select} options={MESES_AGNIO} value={mes} onChange={(e, { value }) => this.onMesChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
          <Menu.Item>
            <Form.Field control={Select} options={ANIOS} value={anio} onChange={(e, { value }) => this.onAnioChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
          <Menu.Item position="right">
            <Button color="green" icon labelPosition="left" onClick={this.nuevaCompra}>
              <Icon name="plus" />
              Nueva Compra
            </Button>
          </Menu.Item>
        </Menu>
        <Segment loading={loading} basic>
          <Table singleLine columns="7">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Fecha Creación</Table.HeaderCell>
                <Table.HeaderCell>Concepto</Table.HeaderCell>
                <Table.HeaderCell>Numero Factura</Table.HeaderCell>
                <Table.HeaderCell>Fecha Pago</Table.HeaderCell>
                <Table.HeaderCell>Monto</Table.HeaderCell>
                <Table.HeaderCell>Proveedor</Table.HeaderCell>
                <Table.HeaderCell>Acciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {compras.sort(sortFecha).map(v => (
                <CompraRow key={v.id} {...v} conceptos={conceptos} modificar={this.modificar} eliminar={this.toggleEliminar} />
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <AlertModal id={ID_MODAL} title="Eliminar compra" message="Vas a eliminar la compra, continuar?" label="Continuar" onClick={this.eliminar} />
      </Container>
    )
  }
}

const CompraRow = ({ id, momento, id_concepto, numero_factura, fecha_pago, monto, proveedor, conceptos, modificar, eliminar }) => {
  const concepto = conceptos.find(v => v.key === id_concepto)
  return (
    <Table.Row>
      <Table.Cell verticalAlign="middle">{moment(momento).format("DD/MM/YYYY")}</Table.Cell>
      <Table.Cell verticalAlign="middle">{concepto && concepto.text}</Table.Cell>
      <Table.Cell verticalAlign="middle">{numero_factura}</Table.Cell>
      <Table.Cell verticalAlign="middle">{fecha_pago !== null && moment(fecha_pago).format("DD/MM/YYYY")}</Table.Cell>
      <Table.Cell verticalAlign="middle">{clp(monto)}</Table.Cell>
      <Table.Cell verticalAlign="middle">{proveedor}</Table.Cell>
      <Table.Cell verticalAlign="middle">
        <Button.Group>
          <Button basic onClick={() => modificar(id)}>
            <Icon name="edit" />
          </Button>
          <Button basic onClick={() => eliminar(id)}>
            <Icon name="close" />
          </Button>
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  )
}
/*

*/

/*
        <Segment>
          <Item.Group divided>
            {this.props.compras.map(v => (
              <Item>
                <Item.Content>
                  <Item.Header as='a'>{v.id_concepto}</Item.Header>
                  <Item.Meta>
                    <span className='cinema'>Union Square 14</span>
                  </Item.Meta>
                  <Item.Description></Item.Description>
                  <Item.Extra>
                    <Button primary floated='right'>
                      Buy tickets
                      <Icon name='right chevron' />
                    </Button>
                    <Label>IMAX</Label>
                    <Label icon='globe' content='Additional Languages' />
                  </Item.Extra>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
        </Segment>
        */

function sortFecha(a, b) {
  return moment(a.momento).isBefore(b.momento) ? 1 : -1
}

const mapStateToProps = (state) => ({
  compras: compras_from_state(state),
  conceptos: conceptos_from_state(state)
})

export default connect(mapStateToProps, { fetch_compras })(Compras)