import React, {Component, useState} from 'react'

import { agregarInventario, fetchInventarioEventos, isSuccess } from '../../api'
import { connect } from 'react-redux';
import { Button, Card, CardContent, Container, Grid, Header, Icon,  Input, List, Menu, MenuItem, Segment } from 'semantic-ui-react';
import { clp, fecha, int } from '../../utils';
import { inventario_calcula_cantidad, inventario_cantidad_float } from "../../lib/Logica";
import { inventario_items_from_state } from '../../reducers';
import moment from 'moment';



class InventarioItem extends Component {
  state = {
    loading: true,
    eventos: []
  }

  async componentDidMount() {
    try {
      const id = this.props.match.params.id
      const res = await fetchInventarioEventos(id)
      if (isSuccess(res)) {
        this.setState({ eventos: res.data })
      }
    } catch (e){
      console.log(e)
    } finally {
      this.setState({ loading: false })
    }
  }

  volver = () => this.props.history.goBack()

  render() {
    const { loading } = this.state
    const id = this.props.match.params.id
    const item = this.props.items.find(v => v.id === int(id))

    return (
      <div style={{ marginTop: 40 }}>
        <Container>
          <Menu>
            <Menu.Item>
              <Button icon labelPosition="left" onClick={this.volver}>
                <Icon name="angle left" />
                Volver
              </Button>
            </Menu.Item>
            <MenuItem>
              <Header>
                {item.nombre}
                <Header.Subheader>{clp(item.precio)}</Header.Subheader>
              </Header>
            </MenuItem>
            <MenuItem>
              {item.cantidad} {item.unidad}
            </MenuItem>
          </Menu>
          <Segment loading={loading}>
            <List divided>
              {this.state.eventos.sort(sortEventos).map(v => (
                <List.Item key={v.id || v._id}>
                  <Grid verticalAlign="middle" style={{padding:8}}>
                    <Grid.Column width="2">
                      <Header>{v.cantidad > 0 && '+'} {inventario_calcula_cantidad(v.cantidad)}</Header>
                    </Grid.Column>
                    <Grid.Column width="3">
                      <Header as="h4">{moment(v.momento).fromNow()}</Header>
                      {fecha(v.momento)}
                    </Grid.Column>
                  </Grid>
                </List.Item>
              ))}
            </List>
          </Segment>
        </Container>
      </div>
    )
  }
}

function sortEventos(a, b) {
  return moment(a.momento).isBefore(b.momento) ? 1 : -1
}

const mapStateToProps = (state) => ({
  items: inventario_items_from_state(state)
})

export default connect(mapStateToProps)(InventarioItem)