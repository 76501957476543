import React from 'react'
import Calendar from 'react-calendar'

import moment from "../utils/Moment";

export default ({ onChange, value, visible, toggle }) => (
  <div className="ml-2 mr-2">
    <div className="d-flex cs-pointer" onClick={toggle}>
      <div className="bg-primary p-2 pr-3 pl-3 text-white rounded-left">
        <i className="far fa-calendar-alt fa-lg"></i>
      </div>
      <div className="border border-primary rounded-right p-2">
        {moment(value).format('dddd DD [de] MMMM YYYY')}
      </div>
    </div>
    <div className="cs-calendar" style={{ display: (visible ? 'block' : 'none') }}>
      <Calendar onChange={onChange} value={value} />
    </div>
  </div>
)