import { fetchInventario, fetchInventarioConsumo, fetchInventarioItems, isSuccess } from "../api"
import { inventario_calcula_cantidad } from "../lib/Logica"
import { fijarError } from "./StatusActions"
import { FIJAR_INVENTARIO_ITEMS } from "./Types"

export function fetch_inventario_items() {
  return async (dispatch) => {
    try {
      const res = await fetchInventarioItems()
      const consumo_res = await fetchInventarioConsumo()
      const cantidad_res = await fetchInventario()

      if (_has_error(res, dispatch)) {
        return
      }

      if (_has_error(cantidad_res, dispatch)) {
        return
      }

      const consumo_data = isSuccess(consumo_res) ? consumo_res.data : []

      const items = res.data.map(v => {
        const actual = cantidad_res.data.find(k => k._id === v.id)
        const item = consumo_data.find(k => k.id_item === v.id)

        return {
          ...v,
          cantidad: actual ? inventario_calcula_cantidad(actual.cantidad) : 0,
          consumo: item ? inventario_calcula_cantidad(item.cantidad) : 0
        }
      })
      
      dispatch(fijar_items(items))
    } catch (e) {
      console.error('fetch_inventario_items', e)
      dispatch(fijarError(e.message))
      dispatch(fijar_items([]))
    }
  }
}

function _has_error(res, dispatch) {
  if (!isSuccess(res)) {
    dispatch(fijarError(res.data))
    dispatch(fijar_items([]))
    return true
  }
  return false
}

function fijar_items(items) {
  return {
    type: FIJAR_INVENTARIO_ITEMS,
    items
  }
}