import { combineReducers } from 'redux'

import createReducer from './createReducer'
import { FIJAR_WINDOWS_SIZE } from '../actions/Types'

import { session } from './SessionReducer'
import { status } from './StatusReducer'
import { comunas, sucursales } from './SucursalesReducer'
import { usuarios } from './UsuariosReduces'
import { inventario } from "./InventarioReducer";
import { compras, conceptos } from "./ComprasReducer";
import { reportes } from "./ReportesReducer";


const estadoInitialState = {
  window_width: window.innerWidth
}

const estado = createReducer(estadoInitialState, {
  [FIJAR_WINDOWS_SIZE]: (state) => ({
    ...state,
    window_width: window.innerWidth
  })
})


export default combineReducers({
  session, status, comunas, usuarios, sucursales,
  inventario, estado, compras, conceptos, reportes
})


export function inventario_items_from_state({ inventario }) {
  return inventario.items
}