import moment from 'moment';
import React, { useState } from 'react'
import { Grid, Menu, Label, Tab, Item, Pagination } from 'semantic-ui-react';

import { PedidoItem, GastoItem, RepartidorItem } from '../../components'
import { CANCELED, COMPLETED } from '../../utils/Estados';
import { DELIVERY } from '../../utils/TipoPedidos';


const PAGE_SIZE = 20


function TablaDetalle({ pedidos, gastos, busy }) {
  const [terminadosPagina, setTerminadosPagina] = useState(0)
  const [anuladosPagina, setAnuladosPagina] = useState(0)
  const [repartidoresPagina, setRepartidoresPagina] = useState(0)
  const [gastosPagina, setGastosPagina] = useState(0)

  const pedidos_terminados = pedidos.filter(pedidoCompletado).sort(sortPedidos)
  const pedidos_anulados = pedidos.filter(pedidoCancelado).sort(sortPedidos)

  const pedidos_delivery = pedidos_terminados.filter(pedidosDelivery)
  let repartidores = []

  for (let v of pedidos_delivery) {
    const man = v.deliveryMan
    let despacho = v.deliveryCost
    let cantidad = 1

    const repartidor = repartidores.find(v => v.email === man.email)
    if (repartidor) {
      despacho += repartidor.total
      cantidad += repartidor.cantidad
      repartidores = repartidores.filter(v => v.email !== man.email)
    }
    repartidores.push({
      email: man.email,
      nombre: man.nombre,
      total: despacho,
      cantidad
    })
  }

  const pagina_terminados = filtrarPagination(pedidos_terminados, terminadosPagina)
  const pagina_anulados = filtrarPagination(pedidos_anulados, anuladosPagina)
  const pagina_repartidores = filtrarPagination(repartidores, repartidoresPagina)
  const pagina_gastos = filtrarPagination(gastos, gastosPagina)

  const panes = [
    {
      menuItem: (
        <Menu.Item key='facturados'>
          Facturados<Label color="blue">{pedidos_terminados.length}</Label>
        </Menu.Item>
      ),
      render: () => <Tab.Pane loading={busy}>
        <Grid divided="vertically">
          {pagina_terminados.map(v => <PedidoItem key={v.id} {...v} />)}
        </Grid>
        <Paginacion defaultActivePage={terminadosPagina} totalPages={pedidos_terminados.length} set={setTerminadosPagina} />
      </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='anulados'>
          Anulados<Label color="blue">{pedidos_anulados.length}</Label>
        </Menu.Item>
      ),
      render: () => <Tab.Pane loading={busy}>
        <Grid divided="vertically">
          {pagina_anulados.map(v => <PedidoItem key={v.id} {...v} />)}
        </Grid>
        <Paginacion defaultActivePage={anuladosPagina} totalPages={pedidos_anulados.length} set={setAnuladosPagina} />
      </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='gastos'>
          Gastos<Label color="blue">{gastos.length}</Label>
        </Menu.Item>
      ),
      render: () => <Tab.Pane loading={busy}>
        <Item.Group divided>
          {pagina_gastos.map(v => <GastoItem key={v.id} {...v} />)}
        </Item.Group>
        <Paginacion defaultActivePage={gastosPagina} totalPages={gastos.length} set={setGastosPagina} />
      </Tab.Pane>
    },
    {
      menuItem: (
        <Menu.Item key='repartidores'>
          Repartos<Label color="blue">{repartidores.length}</Label>
        </Menu.Item>
      ),
      render: () => <Tab.Pane loading={busy}>
        <Item.Group divided>
          {pagina_repartidores.map(v => <RepartidorItem key={v.email} {...v} />)}
        </Item.Group>
        <Paginacion defaultActivePage={repartidoresPagina} totalPages={repartidores.length} set={setRepartidoresPagina} />
      </Tab.Pane>
    },
  ]

  return (
    <div>
      <Tab panes={panes} className="cs-mt-3" menu={{ attached: true, tabular: true, stackable: true, widths: 4 }} />
    </div>
  )
}

const Paginacion = ({ defaultActivePage, totalPages, set }) => (
  <div style={{ textAlign: 'center' }}>
    <Pagination defaultActivePage={defaultActivePage} totalPages={Math.ceil(totalPages / PAGE_SIZE)}
      onPageChange={(e, data) => set(data.activePage - 1)} />
  </div>
)

export function filtrarPagination(data, frame) {
  return data.slice(frame * PAGE_SIZE, frame * PAGE_SIZE + PAGE_SIZE)
}

function pedidoCompletado({ status }) {
  return status === COMPLETED
}

function pedidoCancelado({ status }) {
  return status === CANCELED
}

function pedidosDelivery({ type, deliveryMan }) {
  return type === DELIVERY && deliveryMan && deliveryMan !== null
}

function sortPedidos(a, b) {
  return moment(a.datetime).isAfter(b.datetime) ? -1 : 1
}

export default TablaDetalle