import React, {Component} from 'react'
import { connect } from 'react-redux'

import { toggleFijarSucursal } from "../lib/custom";
import { Dropdown, Icon, Menu, MenuItem } from 'semantic-ui-react';
import { AJUSTES, ARCHIVOS, COMPRAS, COMPRA_VENTA, CUSTOMERS, DASHBOARD, GESTION_INVENTARIO, INVENTARIO, JORNADAS, PEDIDOS, PRODUCTS, RESUMEN_ANUAL, RESUMEN_COMPRA, RESUMEN_CONCEPTOS, RESUMEN_DIARIO, RESUMEN_MENSUAL, SUCURSALES, SUCURSALES_ADMIN, TRABAJADORES, USUARIOS, VENTAS_SUCURSALES } from '../utils/Routes';
import { ADMIN, SUPERVISOR } from '../utils/Permisos';
import { primary_color, react_app_inverted } from '../utils';


const trigger = (
  <span><img src="img/logo512.png" width={40} height={40} /> Menu</span>
)

class Header extends Component {
  navigate = (to, e) => {
    if (e) {
     // e.preventDefault()
      e.stopPropagation()
    }
    this.props.history.push(to)
  }

  render() {
    const { sucursal, mobile, permisos } = this.props
    const nombre = sucursal ? sucursal.nombre : ''
    const admin = permisos >= ADMIN
    const supervisor = permisos >= SUPERVISOR

    if (mobile) {
      return (
        <Menu color={primary_color()} inverted={react_app_inverted()} fixed="top">
          <Dropdown item simple trigger={trigger}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.navigate(DASHBOARD)}>Escritorio</Dropdown.Item>
              {supervisor ? <Dropdown.Item onClick={() => this.navigate(RESUMEN_DIARIO)}>Diario</Dropdown.Item> : null}
              <Dropdown.Divider />
              <Dropdown.Item>
                <Icon name='dropdown' />
                <span className='text'>Compra y Venta</span>
                <MenuCompraVenta navigate={this.navigate} supervisor={supervisor}/>
              </Dropdown.Item>
              {supervisor ? <Dropdown.Item>
                <Icon name='dropdown' />
                <span className='text'>Inventario</span>
                <MenuInventario navigate={this.navigate}/>
              </Dropdown.Item> : null}
              <Dropdown.Item>
                <Icon name='dropdown' />
                <span className='text'>Mantención</span>
                <MenuMantencion navigate={this.navigate}/>
              </Dropdown.Item>
              {admin && <Dropdown.Item>
                <Icon name='dropdown' />
                <span className='text'>Admin</span>
                <MenuAdmin navigate={this.navigate}/>
              </Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
          <MenuDerecho nombre={nombre} navigate={this.navigate}/>
        </Menu>
      )
    }

    return (
      <Menu color={primary_color()} inverted={react_app_inverted()} fixed="top">
        <Menu.Item>
          <img src={process.env.REACT_APP_LOGO} />
        </Menu.Item>
        <Menu.Item onClick={() => this.navigate(DASHBOARD)}>Escritorio</Menu.Item>
        {supervisor && <MenuItem onClick={() => this.navigate(RESUMEN_DIARIO)}>Diario</MenuItem>}
        <Dropdown item simple text="Compra y Venta">
          <MenuCompraVenta navigate={this.navigate} supervisor={supervisor} />
        </Dropdown>
        {supervisor && <Dropdown item simple text="Inventario">
          <MenuInventario navigate={this.navigate}/>
        </Dropdown>}
        <Dropdown item simple text="Mantención">
          <MenuMantencion navigate={this.navigate}/>
        </Dropdown>
        {admin && <Dropdown item simple text="Admin">
          <MenuAdmin navigate={this.navigate}/>
        </Dropdown>}
        <MenuDerecho nombre={nombre} navigate={this.navigate}/>
      </Menu>
    )
  }
}

const MenuCompraVenta = ({ navigate, supervisor }) => (
  <Dropdown.Menu>
    <Dropdown.Item onClick={e => navigate(RESUMEN_MENSUAL, e)}>Resumen Mensual</Dropdown.Item>
    <Dropdown.Item onClick={e => navigate(RESUMEN_ANUAL, e)}>Resumen Anual</Dropdown.Item>
    <Dropdown.Item onClick={e => navigate(COMPRA_VENTA, e)}>Buscar Periodo</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item onClick={e => navigate(JORNADAS, e)}>Jornadas</Dropdown.Item>
    {supervisor ? <Dropdown.Item onClick={e => navigate(COMPRAS, e)}>Compras</Dropdown.Item> :  null}
    <Dropdown.Item onClick={e => navigate(VENTAS_SUCURSALES, e)}>Ventas Sucursales</Dropdown.Item>
    {supervisor ? <Dropdown.Divider /> : null}
    {supervisor ? <Dropdown.Item onClick={e => navigate(RESUMEN_COMPRA, e)}>Proyeccion</Dropdown.Item> : null}
    {supervisor ? <Dropdown.Item onClick={e => navigate(RESUMEN_CONCEPTOS, e)}>Resumen Conceptos</Dropdown.Item> : null}
    {supervisor ? <Dropdown.Divider /> : null}
    {supervisor ? <Dropdown.Item onClick={e => navigate(ARCHIVOS, e)}>Archivos</Dropdown.Item> : null}
  </Dropdown.Menu>
)

const MenuInventario = ({ navigate }) => (
  <Dropdown.Menu>
    <Dropdown.Item onClick={e => navigate(GESTION_INVENTARIO, e)}>Gestionar</Dropdown.Item>
    <Dropdown.Item onClick={e => navigate(INVENTARIO, e)}>Ver</Dropdown.Item>
  </Dropdown.Menu>
)

const MenuMantencion = ({ navigate }) => (
  <Dropdown.Menu>
    <Dropdown.Item onClick={e => navigate(SUCURSALES, e)}>Sucursal</Dropdown.Item>
    <Dropdown.Item onClick={e => navigate(PRODUCTS, e)}>Productos</Dropdown.Item>
    <Dropdown.Item onClick={e => navigate(TRABAJADORES, e)}>Trabajadores</Dropdown.Item>
  </Dropdown.Menu>
)

const MenuDerecho = ({ nombre, navigate }) => (
  <Menu.Menu position="right">
    <MenuItem onClick={toggleFijarSucursal}>
      <div className="cs-header-icon-item">
        <Icon name="exchange" size="large" />
        {nombre}
      </div>
    </MenuItem>
    <MenuItem onClick={() => navigate(AJUSTES)}>
      <div className="cs-header-icon-item">
        <Icon name="cog" size="large" />
          Ajustes
        </div>
    </MenuItem>
  </Menu.Menu>
)

const MenuAdmin = ({ navigate })  => (
  <Dropdown.Menu>
    <Dropdown.Item onClick={() => navigate(SUCURSALES_ADMIN)}>Sucursales</Dropdown.Item>
    <Dropdown.Item onClick={() => navigate(USUARIOS)}>Usuarios</Dropdown.Item>
    <Dropdown.Item onClick={() => navigate(CUSTOMERS)}>Clientes</Dropdown.Item>
  </Dropdown.Menu>
)

const mapStateToProps = ({ session, estado }) => ({
  email: session.email,
  permisos: session.permisos,
  sucursal: session.sucursales.find(s => s.id === session.sucursal),
  mobile: estado.window_width < 800
})

export default connect(mapStateToProps)(Header)