import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'

import moment from '../utils/Moment';


export function resumenMensualXlsx(data, gastos, conceptos, sucursal, fecha) {
  /*var workbook = new Workbook()
  fetch('templates/template_resumen_mensual.xlsx').then(r => r.arrayBuffer()).then(buffer => {
    workbook.xlsx.load(buffer).then(wb => {
      console.log(wb)
      return writeWorkbook(wb, 'ventas_$1_$2.xlsx'.replace('$1', sucursal).replace('$2', fecha))
    }).catch(console.error)
  })*/
  const workbook = new Workbook()
  const total_ventas = data.reduce((t, v) => t + v.total_ventas, 0)
  const total_gastos = data.reduce((t, v) => t + v.total_gastos, 0)
  
  resumenSheet(workbook.addWorksheet('VENTAS'), data)
  gastosSheet(workbook.addWorksheet('PRESUPUESTO GASTOS'), gastos, conceptos, total_ventas, total_gastos)
  return writeWorkbook(workbook, 'ventas_$1_$2.xlsx'.replace('$1', sucursal).replace('$2', fecha))
}

function writeWorkbook(workbook, name) {
  return workbook.xlsx.writeBuffer({ base64: true }).then(xlsx64 => {
    saveAs(new Blob([xlsx64]), name, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  }).catch(console.error)
}

function resumenSheet(sheet, data) {
  sheet.columns = [
    { header: 'Dia', key: 'fecha', width: 15 },
    { header: 'Pedidos', key: 'cantidad', width: 15 },
    { header: 'Total', key: 'total_ventas', width: 15 },
    { header: 'Efectivo', key: 'total_efectivo', width: 15 },
    { header: 'Transbank', key: 'total_transbank', width: 15 },
    { header: 'Transferencias', key: 'total_transferencia', width: 15 },
    { header: 'Sodexo', key: 'total_sodexo', width: 15 },
    { header: 'Aplicaciones', key: 'total_aplicaciones', width: 15 },
    { header: 'Despacho', key: 'total_despacho', width: 15 },
    { header: 'Gastos', key: 'total_gastos', width: 15 },
    { header: 'Margen', key: 'ganancia', width: 15 },
  ]

  //data.forEach(sheet.addRow)
  data.forEach(v => sheet.addRow(v))
  
  const total_ventas = data.reduce((t, v) => t + v.total_ventas, 0)
  const total_gastos = data.reduce((t, v) => t + v.total_gastos, 0)
  const total_ganancia = data.reduce((t, v) => t + v.ganancia, 0)
  console.log(total_ganancia, total_gastos, total_ventas)
  sheet.addRow(['', '', total_ventas, '', '', '', '', '', '', total_gastos, total_ganancia])
}

function gastosSheet(sheet, data, conceptos, total_ventas, total_gastos) {
  sheet.columns = [
    { width: 30 },
    { width: 30 },
    { width: 15 },
    { width: 15 },
    { width: 15 },
    { width: 30 },
    { width: 30 }
  ]

  sheet.addRow([])
  sheet.addRow(['Total Ventas', total_ventas, '', '', '', ';MARGEN', total_ventas - total_gastos])
  sheet.addRow(['Total Gastos', total_gastos])
  sheet.addRow([])
  sheet.addRow([])

  conceptos.forEach(v => {
    const values = data.filter(d => d.tipo === v._id)
    const total = values.reduce((t, v) => t + (v.monto * v.cantidad), 0)
    const presupuesto = Math.ceil(v.ratio * total_ventas)
    const deltaPresupuesto = presupuesto - total
    const utilizado = Math.floor((total / total_ventas) * 10000) / 100
    
    sheet.addRow([])
    sheet.addRow(['', v.label])
    sheet.addRow([])
    sheet.addRow(['PRESUPUESTO GASTOS ADMINISTRACION Y VENTAS', total_ventas, '', '', '', 'PRESUPUESTO UTILIZADO', presupuesto])
    sheet.addRow(['DELTA DEL PRESUPUESTO', deltaPresupuesto, '', '', '', '% UTILIZADO DE GASTO', '%' + utilizado])
    sheet.addRow([])
    sheet.addRow([])
    sheet.addRow(['Fecha', 'Tipo', 'Descripcion', 'Monto', 'Cantidad',  'Total', 'caja'])
    values.forEach(v => sheet.addRow([
      moment(v.fecha).format('DD/MM/YYYY'), v.concepto, v.descripcion, v.monto, v.cantidad,
      v.monto * v.cantidad, v.caja ? 'SI' : 'NO'
    ]))
    sheet.addRow([])
    sheet.addRow([])
    sheet.addRow([])
    sheet.addRow([])
  })
}