import React, { Component } from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import { connect } from 'react-redux'

import Header from './components/Header'
import { Dashboard, Login, Products, Sucursales, Usuarios,
  Jornadas, Customers, Inventario,  JornadaDetalle, InventarioDetalle,
  Pedidos, CompraVenta, ResumenMensual, ResumenDiario, Trabajadores, AgregarTrabajador,
  NuevoUsuario, NuevaSucursal, AdminSucursales, GestionInventario, InventarioItem, Ajustes, Compras, ResumenCompraVenta, ResultadoMensual, Archivos, VentasSucursales, ResumenAnual } from './screens'
import { PRODUCTS, SUCURSALES, USUARIOS, GESTION_INVENTARIO,
  JORNADAS, CUSTOMERS, INVENTARIO, JORNADA_DETALLE, INVENTARIO_DETALLE,
  PEDIDOS, COMPRA_VENTA, RESUMEN_MENSUAL, RESUMEN_DIARIO, TRABAJADORES, AGREGAR_TRABAJADOR,
  DETALLE_TRABAJADOR, USUARIO_NUEVO, USUARIO_MODIFICAR, SUCURSAL_NUEVA, SUCURSALES_ADMIN, SUCURSAL_MODIFICAR, INVENTARIO_DETALLE_ITEM, AJUSTES, COMPRAS, NUEVA_COMPRA, MODIFICAR_COMPRA, RESUMEN_COMPRA, RESUMEN_CONCEPTOS, ARCHIVOS, VENTAS_SUCURSALES, RESUMEN_ANUAL } from './utils/Routes'
import {isEmpty} from './utils'
import './App.css';
import { AlertMessage, FijarSucursal } from './components';
import { limpiarError } from './actions/StatusActions';
import { init_setup } from './actions/SessionActions';
import { obtenerSucursales } from './actions/SucursalesActions';
import { fijar_window_size } from './actions';

import { ADMIN } from './utils/Permisos';
import NuevaCompra from './screens/compras/NuevaCompra';
import Footer from './components/Footer';


class App extends Component {
  state = {
    overlay: false,
  }

  componentDidMount() {
    window.onresize = this.props.fijar_window_size
    //sideBar()
    if (this.props.autorizado) {
      this.props.init_setup()
      if (this.props.admin) {
        this.props.obtenerSucursales()
      }
    }
  }

  render() {
    if (!this.props.autorizado) {
      return <Login />
    }

    return (
      <div className={this.state.overlay ? 'mobile-sticky-body-overlay': ''}>
        <Router>
          <div className="">
            {/*<Route component={AsideMenu} />*/}
            <Route component={Header} />
            <div className="cs-app">
              <AlertMessage message={this.props.error} close={this.props.limpiarError} />
              <Switch>
                <Route exact path={JORNADAS} component={Jornadas} />
                <Route exact path={JORNADA_DETALLE} component={JornadaDetalle} />
                <Route exact path={PEDIDOS} component={Pedidos} />
                <Route exact path={PRODUCTS} component={Products} />
                <Route exact path={CUSTOMERS} component={Customers} />
                <Route exact path={SUCURSALES} component={Sucursales} />
                <Route exact path={SUCURSALES_ADMIN} component={AdminSucursales} />
                <Route exact path={SUCURSAL_NUEVA} component={NuevaSucursal} />
                <Route exact path={SUCURSAL_MODIFICAR} component={NuevaSucursal} />
                <Route exact path={USUARIOS} component={Usuarios} />
                <Route exact path={GESTION_INVENTARIO} component={GestionInventario} />
                <Route exact path={INVENTARIO_DETALLE_ITEM} component={InventarioItem} />
                <Route exact path={INVENTARIO} component={Inventario} />
                <Route exact path={INVENTARIO_DETALLE} component={InventarioDetalle} />
                <Route exact path={COMPRA_VENTA} component={CompraVenta} />
                <Route exact path={RESUMEN_MENSUAL} component={ResumenMensual} />
                <Route exact path={RESUMEN_DIARIO} component={ResumenDiario} />
                <Route exact path={TRABAJADORES} component={Trabajadores} />
                <Route exact path={AGREGAR_TRABAJADOR} component={AgregarTrabajador} />
                <Route exact path={DETALLE_TRABAJADOR} component={AgregarTrabajador} />
                <Route exact path={USUARIO_NUEVO} component={NuevoUsuario} />
                <Route exact path={USUARIO_MODIFICAR} component={NuevoUsuario} />
                <Route exact path={AJUSTES} component={Ajustes} />
                <Route exact path={COMPRAS} component={Compras} />
                <Route exact path={NUEVA_COMPRA} component={NuevaCompra} />
                <Route exact path={MODIFICAR_COMPRA} component={NuevaCompra} />
                <Route exact path={RESUMEN_COMPRA} component={ResumenCompraVenta} />
                <Route exact path={RESUMEN_CONCEPTOS} component={ResultadoMensual} />
                <Route exact path={ARCHIVOS} component={Archivos} />
                <Route exact path={VENTAS_SUCURSALES} component={VentasSucursales} />
                <Route exact path={RESUMEN_ANUAL} component={ResumenAnual} />
                <Route component={Dashboard} />
              </Switch>
              <Route component={FijarSucursal} />
            </div>
          </div>
        </Router>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ session, status }) => ({
  autorizado: !isEmpty(session.token),
  admin: session.permisos >= ADMIN,
  error: status.error
})

export default connect(mapStateToProps, { limpiarError, init_setup, obtenerSucursales, fijar_window_size })(App);
