import moment from 'moment'
import "moment/locale/es";

moment.locale('es')

export const ISO_DATE = "YYYY-MM-DDTHH:mm:ss"
export const MESES = [{
  id: '01',
  label: 'Enero'
}, {
  id: '02',
  label: 'Febrero'
}, {
  id: '03',
  label: 'Marzo'
}, {
  id: '04',
  label: 'Abril'
}, {
  id: '05',
  label: 'Mayo'
}, {
  id: '06',
  label: 'Junio'
}, {
  id: '07',
  label: 'Julio'
}, {
  id: '08',
  label: 'Agosto'
}, {
  id: '09',
  label: 'Septiembre'
}, {
  id: '10',
  label: 'Octubre'
}, {
  id: '11',
  label: 'Noviembre'
}, {
  id: '12',
  label: 'Diciembre'
}]

const AGNIOS = ['2020', '2021', '2022']

export function diasDelMes(dias, mes, agnio) {
  return dias.map(d => moment(agnio + '-' + mes + '-' + d).format('ddd').replace('.', ''))
}

export function meses() {
  return MESES
}

export function agnios() {
  return AGNIOS
}

export function mesLabel(id) {
  return MESES.find(m => m.id === id).label
}

export default moment
