import React, { Component } from "react";


export default class SucursalesModal extends Component {
  state = {
    sucursal: '-1'
  }

  onChange = (e) => this.setState({ sucursal: e.target.value })
  onClick = () => this.props.onClick(this.state.sucursal)

  render() {
    let sucursal = this.state.sucursal

    return (
      <div id="modalSucursales" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="categorias" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Sucursal destino</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <select className="form-control" value={sucursal} onChange={this.onChange}>
                  <option value="-1">----------------</option>
                  {this.props.sucursales.sort(sortSucursal).map(sucursal => (
                    <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={this.onClick} disabled={sucursal === '-1'}>Copiar</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function sortSucursal(a, b) {
  return a.nombre < b.nombre ? -1 : 1
}