import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'


const FACTOR_CANTIDAD = 10000

export function inventario_xlsx(eventos, items, uuid, anterior) {
  const workbook = new Workbook()
  
  const actuales = eventos.filter(e => e.uuid_inventario === uuid)
  const anteriores = eventos.filter(e => e.uuid_inventario === anterior)
  const itemsEventos = eventos.map(e => ({ id: e.id_item, precio: e.precio })).reduce((t, v) => [...t.filter(e => e.id !== v.id), v], [])

  const base = anteriores.filter(e => e.tipo === 'CERRAR').reduce(reduceTotal, 0)
  const movimiento = actuales.filter(e => e.tipo === 'AGREGAR').reduce(reduceTotal, 0)
  const final = actuales.filter(e => e.tipo === 'CERRAR').reduce(reduceTotal, 0)
  const consumo = base + movimiento - final
  const comprado = Math.abs(base - movimiento)

  const sheet = workbook.addWorksheet('Inventario')
  
  sheet.columns = [
    { header: 'Producto', key: 'producto', width: 30 },
    { header: 'Unidad', key: 'unidad', width: 20 },
    { header: 'Inicial', key: 'inicial', width: 15 },
    { header: 'Movimiento', key: 'movimiento', width: 15 },
    { header: 'Final', key: 'final', width: 15 },
    { header: 'Consumo', key: 'consumo', width: 15 },
    { header: 'Comprado', key: 'comprado', width: 15 },
    { header: 'Precio Neto', key: 'precioneto', width: 15 },
    { header: 'Total Consumo', key: 'totalconsumo', width: 15 },
    { header: 'Total Comprado', key: 'totalcomprado', width: 15 },
    { header: 'Total Final', key: 'totalfinal', width: 15 }
  ]

  itemsEventos.forEach(evento => agregarFila(sheet, evento, anteriores, actuales, items))

  sheet.addRow([])
  sheet.addRow(['Total Consumo', consumo])
  sheet.addRow(['Total Consumo IVA', Math.floor(consumo * 1.19)])
  sheet.addRow(['Total Comprado', comprado])
  sheet.addRow(['Total Comprado IVA', Math.floor(comprado * 1.19)])
  sheet.addRow(['Total Final', final])
  sheet.addRow(['Total Final IVA', Math.floor(final * 1.19)])

  writeWorkbook(workbook, 'inventario.xlsx')
}

function agregarFila(sheet, evento, anteriores, actuales, items) {
  const eventosIniciales = anteriores.filter(e => e.id_item === evento.id)
  const eventosActuales = actuales.filter(e => e.id_item === evento.id)

  const base = eventosIniciales.filter(e => e.tipo === 'CERRAR').reduce(reduceCantidad, 0)
  const movimiento = eventosActuales.filter(e => e.tipo === 'AGREGAR').reduce(reduceCantidad, 0)
  const final = eventosActuales.filter(e => e.tipo === 'CERRAR').reduce(reduceCantidad, 0)
  const consumo = base + movimiento - final
  const comprado = Math.abs(base - movimiento)

  const item = items.find(i => i.id === evento.id)

  sheet.addRow({
    producto: item && item.nombre,
    unidad: item && item.unidad,
    inicial: calculaCantidad(base),
    movimiento: calculaCantidad(movimiento),
    final: calculaCantidad(final),
    consumo: calculaCantidad(consumo),
    comprado: calculaCantidad(comprado),
    precioneto: evento.precio,
    totalconsumo: evento.precio * calculaCantidad(consumo),
    totalcomprado: evento.precio * calculaCantidad(comprado),
    totalfinal: evento.precio * calculaCantidad(final)
  })
}

export function calculaCantidad(cantidad) {
  return cantidad / FACTOR_CANTIDAD
}

function reduceCantidad(t, v) {
  return t + v.cantidad
}

function reduceTotal(t, v) {
  return t + (calculaCantidad(v.cantidad) * v.precio)
}

function writeWorkbook(workbook, name) {
  return workbook.xlsx.writeBuffer({ base64: true }).then(xlsx64 => {
    saveAs(new Blob([xlsx64]), name, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  }).catch(console.error)
}
