import React from 'react'

import { clp, isEmpty } from "../utils";
import moment from "../utils/Moment";
import { reduceProductos } from '../Model';
import { Grid, Icon, Label, List } from 'semantic-ui-react';


export default ({ index, datetime, status, type, comment, payment, pay, deliveryCost, deliveryMan, user, products, recargo, motivo }) => {
  const total = (products.reduce(reduceProductos, 0) * recargo) + deliveryCost

  return (
    <Grid.Row>
      <Grid.Column width={2} textAlign="center">
        {tipo(type)}
      </Grid.Column>
      <Grid.Column width={14}>       
        <List.Item>
          <List.Content style={fixStyleContent}>
            N° {index}
          </List.Content>
        </List.Item>
        <List celled>
          <List.Item>
            <List.Content style={fixStyleContent}>
              <Icon name="user" />
              {user.name}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content style={fixStyleContent}>
              <Icon name="phone" />
              {user.phone}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content style={fixStyleContent}>
              <Icon name="map marker" />
              {user.address}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content style={fixStyleContent}>
              {moment(datetime).format('DD/MM/YYYY HH:mm:ss')}
            </List.Content>
          </List.Item>
          {!isEmpty(motivo) &&
          <List.Item>
            <List.Content style={fixStyleContent}>
              <Label color="red">Motivo: {motivo}</Label>
            </List.Content>
          </List.Item>}
          {!isEmpty(comment) &&
          <List.Item>
            <List.Content style={fixStyleContent}>
              Comentario: {comment}
            </List.Content>
          </List.Item>
          }
          {deliveryMan !== null &&
          <List.Item>
            <List.Content style={fixStyleContent}>
              <Repartidor {...deliveryMan} costo={deliveryCost} />
            </List.Content>
          </List.Item>}
          {products.map((p, i) => (
            <List.Item key={p._id + i}>
              <List.Content style={fixStyleContent}>
                <div className="d-flex justify-content-between">
                  <div>{p.title} <Label circular color="red">{p.quantity}</Label> </div>
                  <div>{clp(p.price)}</div>
                </div>
              </List.Content>
            </List.Item>
          ))}
        </List>
        <Label.Group>
          {recargo !== 1 && <Label>Recargo {Math.floor((recargo - 1) * 100)}%</Label>}
          {pay && <Label color="green">Pagado</Label>}
          <Label color="red">{estado(status)}</Label>
          <Label color="blue">{tipoPago(payment)}</Label>
          <Label>{tipoLabel(type)}</Label>
          <Label color="green">Total {clp(total)}</Label>
        </Label.Group>
      </Grid.Column>
    </Grid.Row>
  )
}

const fixStyleContent = { padding: 8 }

const Repartidor = ({ nombre, costo }) => (
  <div className="d-flex justify-content-between">
    <div>Repartidor: {nombre}</div>
    <div>{clp(costo)}</div>
  </div>
)

const Usuario = ({ name, phone, address }) => (
  <div className="p-2">
    <div><i className="fas fa-user"></i> {name}</div>
    <div><i className="fas fa-phone"></i> {phone}</div>
    <div><i className="fas fa-map-marker-alt"></i> {address}</div>
  </div>
)

function tipoPago(tipo) {
  switch (tipo) {
    case 1: return 'Transbank'
    case 2: return 'Transferencia'
    case 3: return 'Sodexo'
    case 4: return 'Aplicaciones'
    default: return 'Efectivo'
  }
}

function estado(status) {
  switch (status) {
    case 'WORKING': return 'Cocinando'
    case 'DONE': return 'Preparado'
    case 'DELIVER': return 'Despachando'
    case 'RETIRO': return 'Retiro'
    case 'COMPLETED': return 'Finalizado'
    case 'CANCELED': return 'Anulado'
    default: return status
  }
}

function tipo(t) {
  switch (t) {
    case 'LOCAL':
    case 'RETIRO': return <Icon name="home" size="big" />
    case 'RESTAURANT': return <Icon name="utensils" size="big"/>
    default: return <Icon name="motorcycle" size="big"/>
  }
}

function tipoLabel(t) {
  switch (t) {
    case 'LOCAL':
    case 'RETIRO': return "Retiro"
    case 'RESTAURANT': return "Restaurant"
    default: return "Despacho"
  }
}