import React from 'react'
import { Item } from 'semantic-ui-react'
import { clp } from '../utils'


export default ({ email, nombre, total }) => (
  <Item>
    <Item.Content>
      <Item.Header>{nombre}</Item.Header>
      <Item.Meta>{email}</Item.Meta>
      <Item.Description>{clp(total)}</Item.Description>
    </Item.Content>
  </Item>
)