import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Screen from '../Screen'
import Loading from '../../components/Loading'
import { isSuccess, eliminarCredencial } from '../../api'
import Permisos, { OPERADOR, permisosLabel } from "../../utils/Permisos";
import { isEmpty, int, toggleModal } from "../../utils";
import { USUARIO_NUEVO, USUARIO_MODIFICAR } from '../../utils/Routes';
import AlertModal from '../../components/AlertModal';
import { buscarUsuarios, clearUsuarios } from "../../actions/UsuariosActions";
import { PlusIcon } from '../../components/Icons';

const EliminarModal = 'modalEliminar'


function toggleAlert() {
  toggleModal(EliminarModal)
}


class Usuarios extends Screen {
  state = {
    email: '',
    permisos: OPERADOR.toString(),
    sucursal: '',
    selected: '',
    loading: false
  }

  componentDidMount() {
    this.props.clearUsuarios()
  }

  buscar = () => {
    const { email, permisos, sucursal } = this.state
    const data = {
      email: isEmpty(email) ? undefined : email,
      permisos: isEmpty(email) ? int(permisos) : undefined,
      sucursal: !isEmpty(email) && isEmpty(sucursal) ? undefined : int(sucursal)
    }

    this.toggleLoading()
    this.props.buscarUsuarios(data).then(res => {
      if (!isSuccess(res)) {
        this.setState({ error: res.data })
      }
    }).catch(this.onError).finally(this.toggleLoading)
  }

  mapSucursal = (v) => {
    const sucursal = this.props.sucursales.find(s => s.id === v)
    return sucursal ? sucursal.nombre : v
  }

  modificar = (email) => this.navigate(USUARIO_MODIFICAR.replace(':email', email))
  nuevoUsuario = () => this.props.history.push(USUARIO_NUEVO)

  toggleEliminar = (email) => {
    this._email = email
    toggleAlert()
  }

  eliminar = () => {
    eliminarCredencial(this._email).then(this.buscar).finally(toggleAlert)
  }

  //onChange = (e) => this.setState({ [e.target.id]: e.target.value })

  render() {
    const { email, sucursal, permisos, selected, loading} = this.state
    
    return (
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5>Usuarios</h5>
              <button className="btn btn-primary" type="button" onClick={this.nuevoUsuario}><PlusIcon /></button>
            </div>
            <form className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <label>Email</label>
                  <input className="form-control" id="email" value={email} onChange={this.onChange} />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Permisos</label>
                  <select className="form-control" id="permisos" value={permisos} onChange={this.onChange}>
                    {Permisos.map(p => <option key={p.value} value={p.value.toString()}>{p.label}</option>)}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Sucursales</label>
                  <select id="sucursal" className="form-control" value={sucursal} onChange={this.onChange}>
                    <option value="">---------------</option>
                    {this.props.sucursales.map(p => <option key={p.id} value={p.id.toString()}>{p.nombre}</option>)}
                  </select>
                </div>
              </div>
              <button className="btn btn-primary" type="button" onClick={this.buscar} disabled={loading}>
                Buscar
              </button>
            </form>

          </div>
        </div>
        <div className="card mt-2">
          <div className="card-body">
            {loading && <Loading />}
            <ul className="list-group list-group-flush">
              {this.props.usuarios.map(v => <Usuario key={v.email} { ...v } eliminar={this.toggleEliminar} onClick={this.modificar} />)}
            </ul>
          </div>
        </div>
        <AlertModal id={EliminarModal} title="Eliminar usuario" message="Seguro que deseas eliminar el usuario?" label="eliminar" onClick={this.eliminar} />
      </div>
    )
  }
}

const Usuario = ({ email, nombre, permisos, sucursales, onClick, eliminar }) => (
  <li className="list-group-item">
    <div className="d-flex justify-content-between align-items-center">
      <div onClick={() => onClick(email)} className="cs-pointer">
        <strong>{email}</strong>
        <div>{permisosLabel(permisos)}</div>
        <p>{nombre}</p>
        <small className="text-muted">{sucursales.join(', ')}</small>
      </div>
      <button type="button" className="btn btn-danger btn-sm" onClick={() => eliminar(email)}><i className="fas fa-times"></i></button>
    </div>
  </li>
)


function sucursalNombre(v, data) {
  const s = data.find(d => d.id === v)
  return s ? s.nombre : v
}

const mapStateToProps = ({ session, usuarios }) => ({
  sucursales: session.sucursales,
  usuarios: usuarios.data.map(v => ({
    ...v,
    sucursales: v.sucursales.map(k => sucursalNombre(k, session.sucursales))
  }))
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ buscarUsuarios, clearUsuarios }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios)