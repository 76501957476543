import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Button, Container, Icon, Menu } from 'semantic-ui-react'
import { sessionLogout } from '../actions/SessionActions'

class Ajustes extends Component {
  state = {

  }

  render() {
    return (
      <Container>
        <Menu>
          <Menu.Item>{this.props.email}</Menu.Item>
          <Menu.Item position="right">
            <Button color="red" icon labelPosition="right" onClick={this.props.sessionLogout}>
              Salir de la sesion
              <Icon name="sign-out" />
            </Button>
          </Menu.Item>
        </Menu>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  email: state.session.email
})

export default connect(mapStateToProps, { sessionLogout })(Ajustes)