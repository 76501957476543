import React from 'react'
import { Table } from 'semantic-ui-react'
import { fecha } from '../utils'


export default ({ id, apertura, cierre, onClick }) => (
  <Table.Row key={id} onClick={() => onClick && onClick(id)} className="cs-pointer">
    <Table.Cell>{id}</Table.Cell>
    <Table.Cell>{fecha(apertura)}</Table.Cell>
    <Table.Cell>{fecha(cierre)}</Table.Cell>
  </Table.Row>
)