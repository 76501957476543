export const ALL = 'ALL'
export const WORKING = 'WORKING'
export const DONE = 'DONE'
export const DELIVER = 'DELIVER'
export const RETIRO = 'RETIRO'
export const COMPLETED = 'COMPLETED'
export const CANCELED = 'CANCELED'

const estados = [{
  id: ALL,
  label: 'Todos'
}, {
  id: WORKING,
  label: 'Trabajando'
}, {
  id: DONE,
  label: 'Terminado'
}, {
  id: DELIVER,
  label: 'Reparto'
}, {
  id: RETIRO,
  label: 'Retiro'
}, {
  id: COMPLETED,
  label: 'Finalizado'
}, {
  id: CANCELED,
  label: 'Anulado'
}]

export function estadoLabel(id) {
  const estado = estados.find(v => v.id === id)
  return estado ? estado.label : ''
}