import React from 'react'
import { connect } from "react-redux";
import { PlusIcon } from '../../components/Icons';
import Screen from '../Screen';
import { SUCURSAL_NUEVA, SUCURSAL_MODIFICAR } from '../../utils/Routes';


class Sucursales extends Screen {
  nueva = () => this.navigate(SUCURSAL_NUEVA)
  modificar = (id) => this.navigate(SUCURSAL_MODIFICAR.replace(':id', id))

  render() {
    return (
      <div className="container">
        <div className="card">
          <div className="card-body">
          <div className="d-flex justify-content-end mb-2">
            <button type="button" className="btn btn-primary" onClick={this.nueva}><PlusIcon /></button>
          </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>id</th>
                  <th>nombre</th>
                  <th>direccion</th>
                  <th>telefonos</th>
                  <th>comunas</th>
                  <th>tipo</th>
                  <th>vertigs</th>
                  <th>nori</th>
                  <th>activa</th>
                </tr>
              </thead>
              <tbody>
                {this.props.sucursales.map(v => <SucursalRow key={v.id} { ...v } onClick={this.modificar} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const SucursalRow = ({ id, nombre, direccion, telefonos, comunas, activa, nori, tipo, vertigs_id, onClick }) => (
  <tr className="cs-pointer" onClick={() => onClick(id)}>
    <td>{id}</td>
    <td>{nombre}</td>
    <td>{direccion}</td>
    <td>{telefonos.join(', ')}</td>
    <td>{comunas.length > 2 ? '.........' : comunas.map(v => v.nombre).join(',')}</td>
    <td>{tipo}</td>
    <td>{vertigs_id}</td>
    <td>{nori ? 'SI' : 'NO'}</td>
    <td>{activa ? 'SI' : 'NO'}</td>
  </tr>
)

function resolveComunas(ids, data) {
  return ids.map(v => data.find(d => d.id === v))
}

const mapStateToProps = ({ session, sucursales, comunas }) => ({
  sucursales: sucursales.map(v => ({ ...v, comunas: resolveComunas(v.comunas, comunas) })).sort((a, b) => a.id - b.id),
  permisos: session.permisos,
})

export default connect(mapStateToProps)(Sucursales)