import React from 'react'
import { connect } from 'react-redux'

import Screen from './Screen'
import { fetchProductos, fetchCategorias, copiarProductos, galleryUri, eliminarProductos, fetchImagenesPath, isSuccess } from "../api";
import { ProductoModal, SeleccionarSucursalesModal, Loading, Checkbox } from "../components";
import { toggleModal, str, int, clp, isEmpty } from '../utils';
import { fijarError } from '../actions/StatusActions';
import AlertModal from '../components/AlertModal';
import { Spinning } from '../components/Loading';




const initialProduct = {
  index: '',
  title: '',
  detail: '',
  price: '',
  asset: '',
  tags: '',
  products: [],
  extras: [],
  subproducts_category: '',
  checkbox: false
}

const toggleProducto = () => toggleModal('modalProducto')
const toggleSucursales = () => toggleModal('modalSeleccionarSucursales')
const toggleEliminar = () => toggleModal('modalEliminar')

class Products extends Screen {
  state = {
    filtro: '',
    destino: '-1',
    products: [],
    categorias: [],
    imagenes: [],
    extras: [],
    producto: { ...initialProduct },
    sub_title: '',
    sub_detail: '',
    loading: true,
    networking: false,
    selected: [],
    error: ''
  }

  componentDidMount() {
    this.refrescar().finally(this.toggleLoading)
  }

  toggleNetworking = () => this.setState({ networking: !this.state.networking })

  error = (message) => this.props.fijarError(message ? message : '')

  refrescar = () => {
    const fetchPromise = fetchProductos(this.props.sucursal).then(res => {
      if (!isSuccess(res)) {
        return this.error(res.data)
      }
      this.setState({ products: res.data })
    }).catch(this.onError)

    return Promise.all([fetchPromise, this.obtenerCategorias(), this.obtenerImagenes()])
  }

  obtenerCategorias = () => fetchCategorias().then(res => {
    if (isSuccess(res)) {
      this.setState({ categorias: res.data })
    }
  }).catch(console.error)

  obtenerImagenes = () => fetchImagenesPath().then(res => {
    if (isSuccess(res)) {
      this.setState({ imagenes: res.data })
    }
  })

  copiarProductos = (sucursales) => {
    toggleSucursales()
    this.toggleNetworking()
    const productos = this.state.products.filter(p => p.checked).map(p => p._id)
    copiarProductos(sucursales, productos).then(console.log).catch(console.error).finally(this.toggleNetworking)
  }

  filtro = (e) => {
    this.setState({ filtro: e.target.value })
  }

  onError = (e) => {
    this.setState({loading: false})
    if (e.data) {
      this.error(e.data)
    } else {
      this.error(e.message)
    }
    console.error(e)
  }

  nuevoProducto = () => {
    this.setState({ producto: {...initialProduct} })
    toggleProducto()
  }

  eliminarProductos = () => {
    const _ids = this.state.products.filter(p => p.checked).map(p => p._id)
    console.log('eliminar', _ids)
    eliminarProductos(_ids).then(console.log).then(this.refrescar).then(toggleEliminar)
  }

  detail = (producto) => {
    this.setState({ producto: { ...initialProduct, ...producto } })
    toggleProducto()
  }

  onProductoChange = (e) => {
    this.setState({ producto: {...this.state.producto, [e.target.id]: e.target.value }})
  }

  agregarExtra = (value) => {
    this.setState({
      producto: {
        ...this.state.producto,
        extras: [...this.state.producto.extras, { value, selected: false }]
      }
    })
  }

  eliminaExtra = (index) => {
    let extras = [...this.state.producto.extras]
    extras.splice(index, 1)
    this.setState({
      producto: {
        ...this.state.producto,
        extras
      }
    })
  }

  selectExtra = (index) => {
    this.setState({
      producto: {
        ...this.state.producto,
        extras: this.state.producto.extras.map((e, i) => ({
          value: e.value,
          selected: index === i
        }))
      }
    })
  }

  addProduct = (product) => {
    this.setState({
      producto: {
        ...this.state.producto,
        products: [...this.state.producto.products, product]
      }
    })
  }

  removeProduct = (index) => {
    let products = [...this.state.producto.products]
    products.splice(index, 1)
    this.setState({
      producto: {
        ...this.state.producto,
        products
      }
    })
  }

  check = (_id) => {
    this.setState({ products: this.state.products.map(p => 
      ({ ...p, checked: p._id === _id ? !p.checked : p.checked }))
    })
  }

  checkAll = () => {
    const checkbox = !this.state.checkbox
    this.setState({
      checkbox,
      products: this.state.products.map(p =>
        ({ ...p, checked: checkbox }))
    })
  }

  filtrar = () => {
    const { products, filtro } = this.state
    return products.filter(p => p.title.toLowerCase().indexOf(filtro.toLowerCase()) !== -1 ||
      p.index === parseInt(filtro, 10))
  }

  render() {
    const { filtro, loading, networking, checkbox } = this.state
    if (loading) return <Loading />

    const productos = this.filtrar()
    const checkeds = productos.filter(p => p.checked).length
    const hasSucursales = this.props.sucursales.length > 0

    return (
      <div className="container">
        <div className="card mt-2">
          <div className="card-body p-3">
            <h3 className="mb-2">Productos ({productos.length})</h3>
            <div className="row mb-2">
              <div className="col-9">
                <input type="text" className="form-control" placeholder="Buscar" value={filtro} onChange={this.filtro} />
              </div>
              <div className="col-md-3 btn-group" role="group">
                <button className="btn btn-success" type="button" onClick={this.nuevoProducto} data-toggle="tooltip" data-placement="top" title="Agregar Producto">
                  <i className="far fa-plus fa-lg"></i></button>
                <button className="btn btn-danger" type="button" data-toggle="modal" data-target="#modalEliminar" data-placement="top" title="Eliminar Producto" disabled={checkeds === 0}>
                  <i className="far fa-minus fa-lg"></i></button>
                {hasSucursales && <button className="btn btn-info" type="button" onClick={toggleSucursales} data-toggle="tooltip" data-placement="top" title="Copiar a.." disabled={checkeds === 0}>
                  <i className="far fa-copy fa-lg"></i></button>}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-product table-striped">
                <thead>
                  <tr>
                    <th><div onClick={this.checkAll}><Checkbox checked={checkbox} /></div></th>
                    <th>Indice</th>
                    <th>Imagen</th>
                    <th>Nombre</th>
                    <th>Descripcion</th>
                    <th>Categoria</th>
                    <th>Extras</th>
                    <th>Precio</th>
                    <th>Modificar</th>
                  </tr>
                </thead>
                <tbody>
                  {productos.sort(sortProducts).map(p =>
                    <tr key={p._id}>
                      <td onClick={() => this.check(p._id)}><Checkbox checked={p.checked} /></td>
                      <td>{p.index}</td>
                      <td><Thumbnail img={p.asset} /></td>
                      <td>{p.title}</td>
                      <td>{p.detail}</td>
                      <td>{p.tags}</td>
                      <td>{p.extras.length > 0 ?  'Si' : 'No'}</td>
                      <td>{clp(p.price)}</td>
                      <td><button className="btn btn-link" type="button" onClick={() => this.detail(p)}>
                        <i className="fal fa-edit fa-lg"></i></button></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ProductoModal producto={this.state.producto}
          onChange={this.onProductoChange}
          sucursal={this.props.sucursal}
          refrescar={this.refrescar}
          categorias={this.state.categorias}
          imagenes={this.state.imagenes}
          agregarExtra={this.agregarExtra}
          eliminaExtra={this.eliminaExtra}
          selectExtra={this.selectExtra}
          agregarProducto={this.addProduct}
          eliminaProducto={this.removeProduct} />
        <SeleccionarSucursalesModal sucursales={this.props.sucursales} onClick={this.copiarProductos} disabled={networking} />
        <AlertModal id="modalEliminar" title="Eliminar Productos" message={`Vas a eliminar ${checkeds} productos.`}
          onClick={this.eliminarProductos} label="Eliminar" />
      </div>
    )
  }
}

const Thumbnail = ({ img }) => !isEmpty(img) && <img src={galleryUri(img)} className="img-fluid" alt="asset" />

function sortProducts(a, b) {
  return a.index - b.index
}

const mapStateToProps = ({ session }) => ({
  sucursales: session.sucursales.sort((a, b) => a.id - b.id),
  sucursal: session.sucursal
})

const mapDispatchToProps = (dispatch) => ({
  fijarError: (error) => dispatch(fijarError(error))
})

export default connect(mapStateToProps, mapDispatchToProps)(Products)