import { buscarCredenciales, isSuccess } from "../api";
import { FIJAR_USUARIOS } from "./Types";


export function buscarUsuarios(data) {
  return (dispatch) => buscarCredenciales(data).then(res => {
    if (isSuccess(res)) {
      dispatch({ type: FIJAR_USUARIOS, usuarios: res.data })
    }
    return res
  })
}

export function clearUsuarios() {
  return {
    type: FIJAR_USUARIOS,
    usuarios: []
  }
}