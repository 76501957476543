import moment from 'moment';
import React, {Component} from 'react'
import { connect } from 'react-redux';

import { Button, Container, Form, Menu, Select, Table, Label } from 'semantic-ui-react';
import { fijar_reporte_anual } from '../actions';
import { ANIOS, clp } from "../utils";
import { sucursales_fs } from '../reducers/SessionReducer';
import { ventas_sucursales_xlsx } from '../lib/VentasExport';
import { reporte_anual_fs } from '../reducers/ReportesReducer';


const meses = [
  { key: '01', label: 'Enero' },
  { key: '02', label: 'Febrero' },
  { key: '03', label: 'Marzo' },
  { key: '04', label: 'Abril' },
  { key: '05', label: 'Mayo' },
  { key: '06', label: 'Junio' },
  { key: '07', label: 'Julio' },
  { key: '08', label: 'Agosto' },
  { key: '09', label: 'Septiembre' },
  { key: '10', label: 'Octubre' },
  { key: '11', label: 'Noviembre' },
  { key: '12', label: 'Diciembre' }
]


class ResumenAnual extends Component {
  state = {
    anio: moment().year(),
    mensaje: '',
    loading: false
  }

  componentDidMount() {
    this.obtenerReporte()
  }

  obtenerReporte = async (anio) => {
    try {
      this.setState({ loading: true })
      await this.props.fijar_reporte_anual(anio || this.state.anio)
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ loading: false })
    }
  }

  onAnioChange = (value) => {
    this.setState({ anio: value })
    this.obtenerReporte(value)
  }

  render() {
    const { loading, anio } = this.state
    const { sucursales, anual } = this.props
    const reporte = anual.find(v => v.key === anio)

    return (
      <Container fluid>
        <h2>Resumen Anual</h2>
        <p>Selecciona el año para ver el resumen</p>
        <Menu>
          <Menu.Item>
            <Form.Field control={Select} options={ANIOS} value={anio} onChange={(e, { value }) => this.onAnioChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
          {/*<Menu.Item position="right">
            <Button color="green" loading={loading}>
              Refrescar
            </Button>
    </Menu.Item>*/}
        </Menu>
        {reporte &&
          <div style={{width: '100%', overflowX: 'scroll'}}>
            <Table definition>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  {sucursales.map(sucursal => <Table.HeaderCell key={sucursal.id}>{sucursal.nombre}</Table.HeaderCell>)}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {meses.map(mes => fila_tabla(reporte.data, mes, sucursales))}
              </Table.Body>
            </Table>
          </div>
        }
      </Container>
    )
  }
}

function fila_tabla(anual, mes, sucursales) {
  const actuales = anual.filter(v => v.mes === mes.key)
  return (
    <Table.Row>
      <Table.Cell>{mes.label}</Table.Cell>
      {sucursales.map(sucursal => cell_total(actuales, sucursal))}
    </Table.Row>
  )
}

function cell_total(actuales, sucursal) {
  const value = actuales.find(v => v.id_sucursal === sucursal.id)
  return  (
    <Table.Cell>{value ? clp(value.total) : ''}</Table.Cell>
  )
}

function sort_sucursales(a, b) {
  return a.nombre < b.nombre ? -1 : 1
}


const mapStateToProps = (state) => ({
  sucursales: sucursales_fs(state).sort(sort_sucursales),
  anual: reporte_anual_fs(state)
})

export default connect(mapStateToProps, { fijar_reporte_anual })(ResumenAnual)