import React from 'react'
import { Icon, Message } from 'semantic-ui-react'
import { isEmpty } from '../utils'

const MensajeError = ({ content }) => {
  if (isEmpty(content))  {
    return null
  }

  return (
    <Message attached='bottom' color="red">
      <Icon name='exclamation triangle' />
      {content}
    </Message>
  )
}

export {
  MensajeError
}