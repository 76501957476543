import React from 'react'
import './Loading.css'

export default () => (
  <div className="Loading">
    <i className="fas fa-circle-notch fa-spin fa-5x"></i>
  </div>
)

function Spinning({ visible }) {
  if (!visible) {
    return null
  }
  return (
    <div className="Loading">
      <i className="fas fa-circle-notch fa-spin fa-5x"></i>
    </div>
  )
}

export {
  Spinning
}