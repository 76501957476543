import { CREATE_SESSION, DESTROY_SESSION, FIJAR_SESSION, FIJAR_ERROR, FIJAR_SUCURSAL } from './Types'
import { login, session, fijarSucursal, logout } from '../api'
import { fetch_conceptos } from './ComprasActions'
import { obtenerComunas } from './SucursalesActions'


export function init_setup() {
  return async (dispatch, getState) => {
    try {
      await fijarSession()(dispatch)
      await obtenerComunas()(dispatch)
      await fetch_conceptos()(dispatch, getState)
    } catch (e) {
      console.error('init_setup', e)
    }
  }
}

export function sessionLogin(body) {
  return (dispatch) => login(body).then(res => {
    if (res.status !== 'success') {
      return Promise.reject(res)
    }
    dispatch({
      type: CREATE_SESSION,
      token: res.data,
      email: body.email
    })
  }).then(() => fijarSession()(dispatch))
}

export function sessionLogout() {
  return (dispatch) => logout().then(() => ({
    type: DESTROY_SESSION
  })).then(dispatch)
}

export function fijarSession() {
  return (dispatch) => session().then(res => {
    if (res.status === 'fail' && res.data === 'No autorizado') {
      return sessionLogout()(dispatch)
    }
    if (res.status !== 'success') {
      return dispatch(e(res.data))
    }
    if (res.data.permisos < 1024) {
      sessionLogout()(dispatch)
      return dispatch(e('El usuario no tiene permisos'))
    }
    
    return dispatch({
      type: FIJAR_SESSION,
      ...res.data
    })
  })
}

export function seleccionarSucursal(id) {
  return dispatch => fijarSucursal(id).then(res => {
    if (res.status !== 'success') {
      return dispatch(e(res.data))
    }

    return dispatch({
      type: FIJAR_SUCURSAL,
      id
    })
  })
}

function e(error) {
  return {
    type: FIJAR_ERROR,
    error
  }
}