import React from 'react'
import { version } from "../../package.json";

export default () => {
  const year = new Date().getFullYear()
  return (
    <footer>
      <div>
        <img src={process.env.REACT_APP_LOGO} width={32} height={32} />
            {process.env.REACT_APP_TITULO} {version} - {year}
      </div>
    </footer>
  )
}