const $ = window.$

export function toggleFijarSucursal() {
  $('#modalFijarSucursal').modal('toggle')
}

export function mobileOverlay() {
  if ($(window).width() < 768) {
    console.log("overlay")
    $(".sidebar-toggle").on("click", function () {
      $("body").css("overflow", "hidden");
      $('body').prepend('<div className="mobile-sticky-body-overlay"></div>')
    });

    $(document).on("click", '.mobile-sticky-body-overlay', function (e) {
      $(this).remove();
      $("#body").removeClass("sidebar-mobile-in").addClass("sidebar-mobile-out");
      $("body").css("overflow", "auto");
    });
  }
}

export function sideBar() {
  $(".sidebar-scrollbar")
    .slimScroll({
      opacity: 0,
      height: "100%",
      color: "#808080",
      size: "5px",
      touchScrollStep: 50
    })
    .mouseover(function () {
      $(this)
        .next(".slimScrollBar")
        .css("opacity", 0.5);
    });


  /*======== 3. SIDEBAR MENU ========*/
  $(".sidebar .nav > .has-sub > a").click(function () {
    $(this).parent().siblings().removeClass('expand')
    $(this).parent().toggleClass('expand')
  })

  $(".sidebar .nav > .has-sub .has-sub > a").click(function () {
    $(this).parent().toggleClass('expand')
  })


  /*======== 4. SIDEBAR TOGGLE FOR MOBILE ========*/
  if ($(window).width() < 768) {
    $(document).on("click", ".sidebar-toggle", toggleSideBar);
  }

  /*======== 5. SIDEBAR TOGGLE FOR VARIOUS SIDEBAR LAYOUT ========*/
  var body = $("#body");
  if ($(window).width() >= 768) {

    if (body.hasClass('sidebar-mobile-in sidebar-mobile-out')) {
      body.removeClass('sidebar-mobile-in sidebar-mobile-out');
    }

    window.isMinified = false;
    window.isCollapsed = false;

    $("#sidebar-toggler").on("click", sideBarToggler);
  }

  if ($(window).width() >= 768 && $(window).width() < 992) {
    if (
      body.hasClass("sidebar-fixed") ||
      body.hasClass("sidebar-static")
    ) {
      body
        .removeClass("sidebar-collapse sidebar-minified-out")
        .addClass("sidebar-minified");
      window.isMinified = true;
    }
  }
}

export function toggleSideBar(e) {
  if ($(window).width() < 768) {
      //e.preventDefault();
      var min = "sidebar-mobile-in",
        min_out = "sidebar-mobile-out",
        body = "#body";
      $(body).hasClass(min)
        ? $(body)
          .removeClass(min)
          .addClass(min_out)
        : $(body)
          .addClass(min)
          .removeClass(min_out)
    }
}

function sideBarToggler() {
  var body = $("#body");

  if (
    body.hasClass("sidebar-fixed-offcanvas") ||
    body.hasClass("sidebar-static-offcanvas")
  ) {
    $(this)
      .addClass("sidebar-offcanvas-toggle")
      .removeClass("sidebar-toggle");
    if (window.isCollapsed === false) {
      body.addClass("sidebar-collapse");
      window.isCollapsed = true;
      window.isMinified = false;
    } else {
      body.removeClass("sidebar-collapse");
      body.addClass("sidebar-collapse-out");
      setTimeout(function () {
        body.removeClass("sidebar-collapse-out");
      }, 300);
      window.isCollapsed = false;
    }
  }

  if (
    body.hasClass("sidebar-fixed") ||
    body.hasClass("sidebar-static")
  ) {
    $(this)
      .addClass("sidebar-toggle")
      .removeClass("sidebar-offcanvas-toggle");
    if (window.isMinified === false) {
      body
        .removeClass("sidebar-collapse sidebar-minified-out")
        .addClass("sidebar-minified");
      window.isMinified = true;
      window.isCollapsed = false;
    } else {
      body.removeClass("sidebar-minified");
      body.addClass("sidebar-minified-out");
      window.isMinified = false;
    }
  }
}