import moment from 'moment';
import React, {Component} from 'react'
import { connect } from 'react-redux';
import { saveAs } from 'file-saver'

import { Button, Container, Form, Icon, Menu, Select } from 'semantic-ui-react';
import { fetch_compras } from '../actions/ComprasActions';
import { MESES_AGNIO, ANIOS, int } from "../utils";
import { isSuccess, ventasSucursales } from '../api';
import { sucursales_fs } from '../reducers/SessionReducer';
import { ventas_sucursales_xlsx } from '../lib/VentasExport';



class VentasSucursales extends Component {
  state = {
    mes: moment().month() + 1,
    anio: moment().year(),
    mensaje: '',
    loading: false
  }

  descargar = async () => {
    try {
      this.setState({ loading: true, mensaje: '' })
      const { mes, anio } = this.state

      const fecha = `${anio}-${mes < 10 ? '0' + mes : mes}`
      const dias = moment(fecha, "YYYY-MM").daysInMonth()
      const fecha_desde = `${fecha}-01T00:00:00`
      const fecha_hasta = `${fecha}-${dias}T23:59:59`

      const res = await ventasSucursales(fecha_desde, fecha_hasta)
      
      if (!isSuccess(res)) {
        this.setState({ mensaje: res.data })
        return
      }

      const data = res.data
      if (data.length === 0) {
        this.setState({ mensaje: 'No hay datos' })
        return
      }

      const sucursales = this.props.sucursales.sort(sort_sucursales)
      ventas_sucursales_xlsx(sucursales, dias, data)
    } catch (e) {
      console.log('descargar', e)
    } finally {
      this.setState({ loading: false })
    }
  }

  onMesChange = (value) => {
    this.setState({ mes: value })
  }

  onAnioChange = (value) => {
    this.setState({ anio: value })
  }

  render() {
    const { loading, anio, mes } = this.state

    return (
      <Container>
        <h2>Ventas Sucursales</h2>
        <p>Selecciona el año y el mes para descargar el archivo</p>
        <Menu>
          <Menu.Item>
            <Form.Field control={Select} options={MESES_AGNIO} value={mes} onChange={(e, { value }) => this.onMesChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
          <Menu.Item>
            <Form.Field control={Select} options={ANIOS} value={anio} onChange={(e, { value }) => this.onAnioChange(value)} loading={loading} disabled={loading} />
          </Menu.Item>
          <Menu.Item position="right">
            <Button color="green" icon labelPosition="left" onClick={this.descargar} loading={loading}>
              <Icon name="download" />
              Descargar
            </Button>
          </Menu.Item>
        </Menu>
      </Container>
    )
  }
}

function sort_sucursales(a, b) {
  return a.id < b.id ? -1 : 1
}

function descargar(data) {
  var blob = new Blob([data], { type: "text/csv;charset=utf-8" });
  saveAs(blob, "ventas.csv")
}

const mapStateToProps = (state) => ({
  sucursales: sucursales_fs(state)
})

export default connect(mapStateToProps, { fetch_compras })(VentasSucursales)