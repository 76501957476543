import createReducer from './createReducer'
import { FIJAR_INVENTARIO_ITEMS } from '../actions/Types'

const initialState = {
  items: []
}

export const inventario = createReducer(initialState, {
  [FIJAR_INVENTARIO_ITEMS]: (state, { items }) => ({
    ...state, items
  }),
})