import React, {Component} from 'react'
import { connect } from 'react-redux';


import { resumenMensual } from '../api'
import moment, { agnios, meses, mesLabel } from "../utils/Moment";
import { clp } from '../utils';
import { resumenMensualXlsx } from '../lib/ResumenMensualExport';


const initMes = moment().format('MM')
const initAgnio = moment().format('YYYY')

const conceptos = [{
  _id: 'costo_adm_vts',
  label: 'Gastos de Administración y Ventas',
  ratio: 0.12
}, {
    _id: 'gst_produccion',
    label: 'Gastos de Producción Indirectos',
    ratio: 0.02
}, {
    _id: 'remuneraciones',
    label: 'Remuneraciones',
    ratio: 0.2
}]


class ResumenMensual extends Component {
  state = {
    mes: initMes,
    agnio: initAgnio,
    data: [],
    gastosMensuales: []
  }

  componentDidMount() {
    this.buscar(this.state.mes, this.state.agnio)
  }

  buscar = (mes, agnio) => {
    resumenMensual(agnio + '-' + mes + '-01T00:00:00', agnio + '-' + mes + '-31T23:59:59').then(res => {
      //console.log(res)
      if (res.status === 'success') {
        const { pedidos, gastos, gastosMensuales } = res.data
        const data = pedidos.map(p => {
          const gasto = gastos.find(g => g.dia === p.dia)
          const total_gastos = gasto ? gasto.total : 0
          return {
            dia: p.dia,
            fecha: moment(agnio + '-' + mes + '-' + p.dia).format('DD dddd'),
            cantidad: p.cantidad,
            total_ventas: p.total,
            total_efectivo: p.efectivo,
            total_transbank: p.transbank,
            total_transferencia: p.transferencia,
            total_sodexo: p.sodexo,
            total_aplicaciones: p.aplicaciones,
            total_despacho: p.total_despachos,
            despachos: p.despachos,
            retiros: p.retiros,
            restaurant: p.restaurant,
            total_gastos,
            ganancia: p.total - total_gastos
          }
        })
        this.setState({ data, gastosMensuales })
      }
    })
  }

  exportar = () => {
    const nombre = this.props.sucursal.nombre
    const fecha = mesLabel(this.state.mes) + '_' + this.state.agnio
    resumenMensualXlsx(this.state.data, this.state.gastosMensuales, conceptos, nombre, fecha)
  }

  onMesChange = (e) => {
    const mes = e.target.value
    this.setState({ mes })
    this.buscar(mes, this.state.agnio)
  }

  onAgnioChange = (e) => {
    const agnio = e.target.value
    this.setState({ agnio })
    this.buscar(this.state.mes, agnio)
  }

  render() {
    const { mes, agnio, data, gastosMensuales } = this.state
    const total_ventas = data.reduce((t, v) => t + v.total_ventas, 0)
    const total_gastos = data.reduce((t, v) => t + v.total_gastos, 0)

    return (
      <div className="container-fluid">
        <div className="card mt-2">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3 col-6">
                <MiniStatus title="Ventas" status={clp(total_ventas)} />
              </div>
              <div className="col-md-3 col-6">
                <MiniStatus title="Gastos" status={clp(total_gastos)} className="text-info" />
              </div>
              <div className="col-md-2 col-6">
                <MiniStatus title="Margen" status={clp(total_ventas - total_gastos)} className="text-success" />
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-between">
                <div className="form-group mr-1">
                  <select className="form-control" id="mes" value={mes} onChange={this.onMesChange}>
                    {meses().map(m => <option key={m.id} value={m.id}>{m.label}</option>)}
                  </select>
                </div>
                <div className="form-group">
                  <select className="form-control" id="agnio" value={agnio} onChange={this.onAgnioChange}>
                    {agnios().map(a => <option key={a} value={a}>{a}</option>)}
                  </select>
                </div>
                <div className="ml-1">
                  <button type="button" className="btn btn-success" onClick={this.exportar}><i className="fas fa-download"></i></button>
                </div>
              </div>
            </div>
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#compraventa">Compra / Venta</a>
              </li>
              {conceptos.map(c => (
                <li className="nav-item" key={c._id}>
                  <a className="nav-link" data-toggle="tab" href={"#" + c._id}>{c.label}</a>
                </li>
              ))}
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="compraventa" role="tabpanel" aria-labelledby="CompraVenta-tab">
                <CompraVenta data={data} />
              </div>
              {conceptos.map(c => (
                <div key={c._id} className="tab-pane fade" id={c._id} role="tabpanel" aria-labelledby="profile-tab">
                  <GastosMensuales
                    ratio={c.ratio}
                    total_ventas={total_ventas}
                    data={gastosMensuales.filter(g => g.tipo === c._id)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const CompraVenta = ({ data }) => (
  <div className="table-responsive mt-3">
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Dia</th>
          <th>Pedidos</th>
          <th>Total</th>
          <th>Efectivo</th>
          <th>Transbank</th>
          <th>Transferencia</th>
          <th>Sodexo</th>
          <th>Aplicaciones</th>
          <th>Despacho</th>
          <th>Gastos</th>
          <th>Ganancia</th>
        </tr>
      </thead>
      <tbody>
        {data.map(value => (
          <tr key={value.dia}>
            <td>{value.fecha}</td>
            <td>{value.cantidad}</td>
            <td>{clp(value.total_ventas)}</td>
            <td>{clp(value.total_efectivo)}</td>
            <td>{clp(value.total_transbank)}</td>
            <td>{clp(value.total_transferencia)}</td>
            <td>{clp(value.total_sodexo)}</td>
            <td>{clp(value.total_aplicaciones)}</td>
            <td>{clp(value.total_despacho)}</td>
            <td>{clp(value.total_gastos)}</td>
            <td>{clp(value.ganancia)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

const GastosMensuales = ({ data, ratio, total_ventas }) => {
  const total = data.reduce((t, v) => t + (v.monto * v.cantidad), 0)
  const presupuesto = Math.ceil(ratio * total_ventas)
  const utilizado = Math.floor((total / total_ventas) * 10000) / 100

  return (
    <div className="mt-3">
      <div className="row">
        <div className="col-6 col-md-3">
          <MiniStatus title="Utilizado" status={clp(total)} />
        </div>
        <div className="col-6 col-md-3">
          <MiniStatus title="Presupuesto" status={clp(presupuesto)} className="text-warning"/>
        </div>
        <div className="col-6 col-md-3">
          <MiniStatus title="Delta de Presupuesto" status={clp(presupuesto - total)} className="text-info"/>
        </div>
        <div className="col-6 col-md-3">
          <MiniStatus title="Porcentaje Utilizado" status={'%' + utilizado} className="text-success"/>
        </div>
      </div>
      <div className="table-responsive mt-2">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Tipo</th>
              <th>Descripción</th>
              <th>Monto</th>
              <th>Cantidad</th>
              <th>Total</th>
              <th>Caja</th>
            </tr>
          </thead>
          <tbody>
            {data.map((v, i) => (
              <tr key={i}>
                <td>{moment(v.fecha).format('DD/MM/YYYY')}</td>
                <td>{v.concepto}</td>
                <td>{v.descripcion}</td>
                <td>{clp(v.monto)}</td>
                <td>{v.cantidad}</td>
                <td>{clp(v.monto * v.cantidad)}</td>
                <td>{v.caja}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const MiniStatus = ({ title, status, className }) => (
  <div className="mini-status">
    <div className="text-content text-center">
      <span className="title">{title}</span>
      <span className={"status " + (className ? className : 'text-primary')}>
        {status}
      </span>
    </div>
  </div>
)

const mapStateToProps = ({ session }) => ({
  sucursal: session.sucursales.find(s => s.id === session.sucursal),
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ResumenMensual)