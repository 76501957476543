import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { int } from '../utils'

export function ventas_sucursales_xlsx(sucursales, dias, data) {
  const workbook = new Workbook()

  ventasSucursales(workbook.addWorksheet('Ventas'), sucursales, dias, data)
  writeWorkbook(workbook, 'ventas.xlsx')
}

function writeWorkbook(workbook, name) {
  return workbook.xlsx.writeBuffer({ base64: true }).then(xlsx64 => {
    saveAs(new Blob([xlsx64]), name, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  }).catch(console.error)
}

function ventasSucursales(sheet, sucursales, dias, data) {
  const columns  = 
  [
    { header: 'Dia', key: 'dia', width: 10 }
  ]

  for (let i = 0; i < sucursales.length; i++) {
    columns.push({
      header: sucursales[i].nombre,
      key: sucursales[i].id.toString(),
      width: sucursales[i].nombre.length + 10
    })
  }
  sheet.columns = columns
  for (let dia = 1; dia <= dias; dia++) {
    const data_dia = data.filter(v => int(v.dia) === dia)

    let row = {}
    for (let i = 0; i < sucursales.length; i++) {
      const id = sucursales[i].id
      const total_sucursal = data_dia.find(v => v.id_sucursal === id)
      row[id] = total_sucursal ? total_sucursal.total : 0
    }
    sheet.addRow({ dia, ...row })
  }
}