import createReducer from './createReducer'
import { FIJAR_COMUNAS, FIJAR_SUCURSALES } from '../actions/Types'


export const comunas = createReducer([], {
  [FIJAR_COMUNAS]: (state, { comunas }) => comunas
})

export const sucursales = createReducer([], {
  [FIJAR_SUCURSALES]: (state, { sucursales }) => sucursales
})