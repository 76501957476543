import { version } from "../package.json";

let _host = process.env.REACT_APP_HOST

if (process.env.NODE_ENV === 'development') {
  _host = 'http://192.168.0.25:8000'
}

export const host = _host
export const info = {
  version
}